import ApiClient from "clinic/services/ApiClient";
import moment from "moment";

export default class WorkflowsApi {
  static getAll() {
    return ApiClient.get(`workflows`);
  }

  static get(id) {
    return ApiClient.get(`workflows/${id}`);
  }

  static create = (formData) =>
    ApiClient.post(`workflows`, {
      ...formData,
      patientNotificationStartDate:
        formData.patientNotificationStartDate === null ||
        formData.patientNotificationStartDate === undefined
          ? null
          : moment(formData.patientNotificationStartDate).format("YYYY-MM-DD"),
    });

  static update = (id, formData) =>
    ApiClient.patch(`workflows/${id}`, {
      ...formData,
      patientNotificationStartDate:
        formData.patientNotificationStartDate === null
          ? null
          : moment(formData.patientNotificationStartDate).format("YYYY-MM-DD"),
    });

  static getHospitalizationSteps = (workflowId) =>
    ApiClient.get(`workflows/${workflowId}/hospitalizationSteps`);

  static getHospitalizationStep(workflowId, order) {
    return ApiClient.get(
      `workflows/${workflowId}/hospitalizationSteps/${order}`
    );
  }

  static createHospitalizationSteps(workflowId, data) {
    return ApiClient.post(`workflows/${workflowId}/hospitalizationSteps`, data);
  }

  static editHospitalizationSteps = (workflowId, order, data) =>
    ApiClient.patch(
      `workflows/${workflowId}/hospitalizationSteps/${order}`,
      data
    );

  static reorderHospitalizationSteps(workflowId, order, newOrder) {
    return ApiClient.patch(
      `workflows/${workflowId}/hospitalizationSteps/${order}/reorder`,
      {
        newOrder: newOrder,
      }
    );
  }

  static deleteHospitalizationStep(workflowId, order) {
    return ApiClient.delete(
      `workflows/${workflowId}/hospitalizationSteps/${order}`
    );
  }

  static addArticle(workflowId, data) {
    return ApiClient.post(`workflows/${workflowId}/articles`, data);
  }

  static editArticle(workflowId, articleId, data) {
    return ApiClient.patch(
      `workflows/${workflowId}/articles/${articleId}`,
      data
    );
  }

  static removeArticle(workflowId, articleId) {
    return ApiClient.delete(`workflows/${workflowId}/articles/${articleId}`);
  }

  static getArticlesIds(workflowId) {
    return ApiClient.get(`workflows/${workflowId}/articles`);
  }

  static getServices = (workflowId) =>
    ApiClient.get(`workflows/${workflowId}/services`);

  static getService = (workflowId, serviceId) =>
    ApiClient.get(`workflows/${workflowId}/services/${serviceId}`);

  static addService = (workflowId, data) =>
    ApiClient.post(`workflows/${workflowId}/services`, data, true);

  static editService = (workflowId, serviceId, data) =>
    ApiClient.post(`workflows/${workflowId}/services/${serviceId}`, data, true);

  static deleteService = (workflowId, serviceId) =>
    ApiClient.delete(`workflows/${workflowId}/services/${serviceId}`);

  static getRoomTypes(workflowId) {
    return ApiClient.get(`workflows/${workflowId}/roomTypes`);
  }

  static linkWorkflowToRoomType(workflowId, data) {
    return ApiClient.post(`workflows/${workflowId}/roomTypes`, data);
  }

  static removeRoomType(workflowId, roomTypeId) {
    return ApiClient.delete(`workflows/${workflowId}/roomTypes/${roomTypeId}`);
  }

  static changeRoomTypeOrder = (workflowId, roomTypeId, newOrder) =>
    ApiClient.patch(`workflows/${workflowId}/roomTypes/${roomTypeId}/order`, {
      newOrder: newOrder,
    });

  static changeArticleOrder = (workflowId, articleId, newOrder) =>
    ApiClient.patch(`workflows/${workflowId}/articles/${articleId}/order`, {
      position: newOrder,
    });
}
