import ApiClient from 'clinic/services/ApiClient';

export default class PostOpInstructionsApi {
  static getAll = () => ApiClient.get('postOpInstructions');

  static get = (id) => ApiClient.get(`postOpInstructions/${id}`);

  static add = (data) => ApiClient.post('postOpInstructions', data);

  static updateGeneral = (id, data) =>
    ApiClient.patch(`postOpInstructions/${id}`, data);

  static delete = (id) => ApiClient.delete(`postOpInstructions/${id}`);

  static addBlock = (id, blockType, data) =>
    ApiClient.post(`postOpInstructions/${id}/blocks/${blockType}`, data, true);

  static editBlock = (id, blockId, data) =>
    ApiClient.post(
      `postOpInstructions/${id}/blocks/${blockId}/content`,
      data,
      true,
    );

  static moveBlock = (id, blockId, data) =>
    ApiClient.patch(`postOpInstructions/${id}/blocks/${blockId}/order`, data);

  static deleteBlock = (id, blockId) =>
    ApiClient.delete(`postOpInstructions/${id}/blocks/${blockId}`);

  static addArticle(id, data) {
    return ApiClient.post(`postOpInstructions/${id}/articles`, data);
  }

  static removeArticle(id, articleId) {
    return ApiClient.delete(`postOpInstructions/${id}/articles/${articleId}`);
  }

  static getArticles(id) {
    return ApiClient.get(`postOpInstructions/${id}/articles`);
  }

  static changeArticleOrder = (id, articleId, newOrder) =>
    ApiClient.patch(`postOpInstructions/${id}/articles/${articleId}/order`, {
      position: newOrder,
    });

  static duplicate = (id) =>
    ApiClient.post(`postOpInstructions/${id}/duplicate`);
}
