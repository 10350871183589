import AddHospitalizationStepPage from 'clinic/pages/editorial/workflows/addHospitalizationStep/AddHospitalizationStepPage';
// import AddRoomTypePage from 'clinic/pages/hotellerie/roomTypes/addRoomType/AddRoomTypePage';
import AddService from 'clinic/pages/editorial/workflows/addService/AddService';
import ArticlesListPage from 'clinic/pages/editorial/articles/list/ArticlesListPage';
import ConvocationPlacesLinksPage from 'clinic/pages/editorial/convocationPlacesLinksPage/ConvocationPlacesLinksPage';
import EditArticlePage from 'clinic/pages/editorial/articles/edit/EditArticlePage';
import EditHospitalizationStep from 'clinic/pages/editorial/workflows/editHospitalizationStep/EditHospitalizationStep';
import EditPostOpInstructionsPage from 'clinic/pages/editorial/postOpInstructions/editPostOpInstructionsPage/EditPostOpInstructionsPage';
import EditPreOpInstructionsPage from 'clinic/pages/editorial/preOpInstructions/editPreOpInstructionsPage/EditPreOpInstructionsPage';
// import EditRoomOptionPage from 'clinic/pages/hotellerie/roomOptions/editRoomOption/EditRoomOptionPage';
// import EditRoomTypePage from 'clinic/pages/hotellerie/roomTypes/editRoomType/EditRoomTypePage';
import EditService from 'clinic/pages/editorial/workflows/editService/EditService';
import EditWorkflowPage from 'clinic/pages/editorial/workflows/edit/EditWorkflowPage';
import HomePage from 'clinic/pages/HomePage';
import Login from 'clinic/pages/login/Login';
import MyClinicPage from 'clinic/pages/editorial/myClinic/MyClinicPage';
import OperatingMethodsLinksPage from 'clinic/pages/editorial/operatingMethodsLinksPage/OperatingMethodsLinksPage';
import OperatingMethodsPage from 'clinic/pages/editorial/operatingMethods/OperatingMethodsPage';
import PostOpInstructionsListPage from 'clinic/pages/editorial/postOpInstructions/PostOpInstructionsListPage';
import PreOpInstructionsListPage from 'clinic/pages/editorial/preOpInstructions/PreOpInstructionsListPage';
import QoDDashboard from 'clinic/pages/qod/dashboard/QoDDashboard';
import QoDSearchPage from 'clinic/pages/qod/search/QoDSearchPage';
import ReleaseNotesPage from 'clinic/pages/releaseNotes/ReleaseNotesPage';
// import RoomOptionsPage from 'clinic/pages/hotellerie/roomOptions/listRoomOptions/RoomOptionsPage';
// import RoomReservationsPage from 'clinic/pages/hotellerie/roomReservations/RoomReservationsPage';
// import RoomTypesPage from 'clinic/pages/hotellerie/roomTypes/listRoomTypes/RoomTypesPage';
import UsersPage from 'clinic/pages/editorial/users/UsersPage';
import WorkflowsListPage from 'clinic/pages/editorial/workflows/list/WorkflowsListPage';

export default class ClinicAdminRoutes {
  static public = [
    {
      key: 'clinicLogin',
      path: '/login',
      exact: true,
      component: Login,
    },
  ];

  static private = [
    {
      key: 'home',
      path: '/',
      exact: true,
      component: HomePage,
    },

    // Release notes
    {
      key: 'releaseNotes',
      path: '/releaseNotes',
      exact: true,
      component: ReleaseNotesPage,
    },
    {
      key: 'adminUsers',
      path: '/admin/users',
      exact: true,
      component: UsersPage,
      authorizedIf: (user) => user.isUsersAdmin,
    },
    // Editorial
    {
      key: 'clinicMyClinic',
      path: '/healthFacility',
      exact: true,
      component: MyClinicPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },

    {
      key: 'operatingMethodsLinks',
      path: '/operatingMethodsLinks',
      exact: true,
      component: OperatingMethodsLinksPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicWorkflows',
      path: '/workflows',
      exact: true,
      component: WorkflowsListPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicEditWorkflow',
      path: '/workflows/:id',
      exact: true,
      component: EditWorkflowPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicPreOpInstructions',
      path: '/preOpInstructions',
      exact: true,
      component: PreOpInstructionsListPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicPreOpInstructions',
      path: '/preOpInstructions/:id',
      exact: true,
      component: EditPreOpInstructionsPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicPostOpInstructions',
      path: '/postOpInstructions',
      exact: true,
      component: PostOpInstructionsListPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicPostOpInstructions',
      path: '/postOpInstructions/:id',
      exact: true,
      component: EditPostOpInstructionsPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicAddHospitalizationStep',
      path: '/workflows/:workflowId/hospitalizationSteps/add',
      exact: true,
      component: AddHospitalizationStepPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicEditHospitalizationStep',
      path: '/workflows/:workflowId/hospitalizationSteps/:order',
      exact: true,
      component: EditHospitalizationStep,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicArticles',
      path: '/articles',
      exact: true,
      component: ArticlesListPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicEditArticle',
      path: '/articles/:id',
      exact: true,
      component: EditArticlePage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicAddService',
      path: '/workflows/:workflowId/services/add',
      exact: true,
      component: AddService,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicEditService',
      path: '/workflows/:workflowId/services/:serviceId',
      exact: true,
      component: EditService,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    {
      key: 'clinicOperatingMethods',
      path: '/operatingMethods',
      exact: true,
      component: OperatingMethodsPage,
      authorizedIf: (user) => user.isEditorialAdmin,
    },
    // Hôtellerie
    // {
    //   key: 'clinicRoomReservations',
    //   path: '/roomReservations',
    //   exact: true,
    //   component: RoomReservationsPage,
    //   authorizedIf: (user) => user.isRoomReservationsAdmin,
    // },
    // {
    //   key: 'clinicRoomTypes',
    //   path: '/roomTypes',
    //   exact: true,
    //   component: RoomTypesPage,
    //   authorizedIf: (user) => user.isRoomReservationsAdmin,
    // },
    // {
    //   key: 'clinicAddClinic',
    //   path: '/roomTypes/add',
    //   exact: true,
    //   component: AddRoomTypePage,
    // },
    // {
    //   key: 'clinicEditRoomType',
    //   path: '/roomTypes/:id',
    //   exact: true,
    //   component: EditRoomTypePage,
    // },
    // {
    //   key: 'clinicRoomOptions',
    //   path: '/roomOptions',
    //   exact: true,
    //   component: RoomOptionsPage,
    // },
    // {
    //   key: 'clinicRoomOptions',
    //   path: '/roomOptions/:id',
    //   exact: true,
    //   component: EditRoomOptionPage,
    // },
    // QoD
    {
      key: 'clinicQodSearch',
      path: '/qod/search',
      exact: true,
      component: QoDSearchPage,
      authorizedIf: (user) => user.isQoDAdmin,
    },
    {
      key: 'clinicQodDashboard',
      path: '/qod/',
      exact: true,
      component: QoDDashboard,
      authorizedIf: (user) => user.isQoDAdmin,
    },
    // Convocation Places
    {
      key: 'convocationPlacesLinks',
      path: '/convocationPlacesLinks',
      exact: true,
      component: ConvocationPlacesLinksPage,
    },
  ];
}
