import React from 'react';
import _ from 'lodash';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, Badge} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import YoutubeButton from 'sharedComponents/youtubeButton/YoutubeButton';

import CreateArticleButton from './createArticleButton/CreateArticleButton';
import PreviewTextArticleButton from './previewTextArticleButton/PreviewTextArticleButton';

import ArticlesApi from 'admin/apis/articles.api';

export default class ArticlesPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    articles: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des articles',
      activeMenuKey: 'articles',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, articles: null},
      async () => {
        let nextState = {isLoading: false};
        let articles = await ArticlesApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (articles) {
          nextState.articles = _.orderBy(articles, 'title');
        }

        this.setState(nextState);
      },
    );
  };

  onCreateArticle = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let createdArticle = await ArticlesApi.add(formData).catch((e) => {
      error = e;
      return null;
    });
    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    this.props.history.push(`articles/${createdArticle.id}`);
    return Promise.resolve();
  };

  deleteArticle = async (article) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await ArticlesApi.delete(article.id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    if (success) {
      NotificationManager.success('Article supprimé avec succès', null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>articles</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.articles) return null;

    return (
      <div>
        <div className="text-end mb-2">
          <CreateArticleButton onSubmit={this.onCreateArticle} />
        </div>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="width-auto"></th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.articles.map((article) => (
              <tr key={article.id}>
                <td>{article.title}</td>
                <td className="text-center">
                  <Badge color={article.published ? 'success' : 'secondary'}>
                    {article.published ? 'Publié' : 'Non publié'}
                  </Badge>
                </td>
                <td className="width-auto">
                  {article.type === 'video' && (
                    <YoutubeButton
                      title="Visualiser"
                      videoId={article.videoId}
                    />
                  )}
                  {article.type === 'text' && (
                    <PreviewTextArticleButton article={article} />
                  )}
                  <span className="mx-2">
                    <EditButtonLink to={`articles/${article.id}`} />
                  </span>
                  <DeleteButton
                    data={article}
                    title="Supprimer un article"
                    onConfirm={this.deleteArticle}>
                    Voulez-vous réellement supprimer l'article{' '}
                    <i>"{article.title}"</i> ?
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}
