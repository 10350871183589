import { withLayoutContextProvider } from "services/LayoutContext";
import InlineLoader from "sharedComponents/inlineLoader/InlineLoader";
import React from "react";

import "./Loader.scss";

export class Loader extends React.PureComponent {
  render() {
    return this.props.layoutContextProvider.loaderVisible ? (
      <div className="loader d-flex">
        <div className="content p-4 d-flex align-items-center align-self-center justify-content-center">
          <div>
            <InlineLoader block xl />
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withLayoutContextProvider(Loader);
