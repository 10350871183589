import ApiClient from "admin/services/ApiClient";
import moment from "moment";
import queryString from "query-string";

export default class QoDApi {
  static getLogsFromMedicalStay = (clinicCode, medicalStayGapId) =>
    ApiClient.get(
      `qod/search?medicalStayGapId=${medicalStayGapId}&clinicCode=${clinicCode}`
    );

  static dashboard = (clinicCode, startDate, endDate) => {
    let params = {};

    params.clinicCode = clinicCode;
    params.startDate = moment(startDate).format("YYYY-MM-DD");
    params.endDate = moment(endDate).format("YYYY-MM-DD");

    return ApiClient.get(`qod/dashboard?${queryString.stringify(params)}`);
  };

  static getErrorsDescriptions = () => ApiClient.get(`qod/errorsDescriptions`);

  static createErrorDescription = (code, description) =>
    ApiClient.post(`qod/errorsDescriptions`, {
      code: code,
      description: description
    });

  static patchErrorDescription = (code, description) =>
    ApiClient.patch(`qod/errorsDescriptions/${code}`, {
      description: description
    });

  static deleteErrorDescription = code =>
    ApiClient.delete(`qod/errorsDescriptions/${code}`);
}
