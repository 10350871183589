import {FormGroup, Label, Row, Col} from 'reactstrap';
import {PropTypes} from 'prop-types';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import Input from 'sharedComponents/input/Input';
import LinkButton from 'sharedComponents/buttons/linkButton/LinkButton';
import React from 'react';

export default class ClinicAdminForm extends React.PureComponent {
  static propTypes = {
    clinicAdmin: PropTypes.object,
    clinics: PropTypes.array,
  };

  state = {
    selectedIds: [],
  };

  componentDidMount() {
    this.setState({
      selectedIds: this.props.clinicAdmin
        ? this.props.clinicAdmin.healthFacilities.map((c) => c.id)
        : [],
    });
  }

  onSelectAll = (e) => {
    e.preventDefault();
    this.setState({
      selectedIds: this.props.clinics.map((c) => c.id),
    });
  };

  onUnSelectAll = (e) => {
    e.preventDefault();
    this.setState({
      selectedIds: [],
    });
  };

  render() {
    const {clinics, clinicAdmin} = this.props;

    return (
      <>
        <FormGroup className="row required">
          <Label className="col-sm-2">Nom</Label>
          <div className="col-sm-10">
            <Input name="name" value={clinicAdmin ? clinicAdmin.name : ''} />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-sm-2">Email</Label>
          <div className="col-sm-10">
            <Input
              type="email"
              name="email"
              required
              value={clinicAdmin ? clinicAdmin.email : ''}
            />
          </div>
        </FormGroup>

        <FormGroup
          className={this.props.passwordRequired ? 'row required' : 'row'}>
          <Label className="col-sm-2">Mot de passe</Label>
          <div className="col-sm-10">
            <Input
              type="password"
              name="password"
              required={this.props.passwordRequired}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-sm-2">Admin siège</Label>
          <div className="col-sm-10">
            <Checkbox
              id="isHead"
              name="isHead"
              label=""
              checked={clinicAdmin && clinicAdmin.isHead}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-sm-2">Droits</Label>
          <div className="col-sm-10">
            <Checkbox
              id="isEditorialAdmin"
              name="isEditorialAdmin"
              label="Contenu éditorial"
              checked={clinicAdmin && clinicAdmin.isEditorialAdmin}
            />
            <Checkbox
              id="isQoDAdmin"
              name="isQoDAdmin"
              label="QoD"
              checked={clinicAdmin && clinicAdmin.isQoDAdmin}
            />
            <Checkbox
              id="isRoomReservationsAdmin"
              name="isRoomReservationsAdmin"
              label="Gestion de l'hôtellerie"
              checked={clinicAdmin && clinicAdmin.isRoomReservationsAdmin}
            />
            <Checkbox
              id="isUsersAdmin"
              name="isUsersAdmin"
              label="Gestion des utilisateurs"
              checked={clinicAdmin && clinicAdmin.isUsersAdmin}
            />
          </div>
        </FormGroup>

        <FormGroup className="required">
          <Label>Cliniques</Label>
          <div className="ms-4">
            <Row>
              <Col className="p-3">
                <LinkButton onClick={this.onSelectAll}>Tout cocher</LinkButton>
                <span> / </span>
                <LinkButton onClick={this.onUnSelectAll}>
                  Tout décocher
                </LinkButton>
              </Col>
            </Row>
            <Row>
              {clinics &&
                clinics.map((c, index) => (
                  <Col sm={6} key={c.id} className="mb-2">
                    <Checkbox
                      id={`clinics_${c.id}`}
                      name={`clinics[${index}]`}
                      targetValue={`${c.id}`}
                      label={c.name}
                      checked={this.state.selectedIds.includes(c.id)}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </FormGroup>
      </>
    );
  }
}
