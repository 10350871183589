import { FormGroup, Label } from "reactstrap";
import DatePicker from "sharedComponents/datePicker/DatePicker";
import HtmlEditor from "sharedComponents/htmlEditor/HtmlEditor";
import Input from "sharedComponents/input/Input";
import React from "react";

export default class CreateForm extends React.PureComponent {
  render() {
    return (
      <div className="createWorkflowForm">
        <FormGroup className="required">
          <Label>Nom</Label>
          <Input name="name" required />
        </FormGroup>
        <FormGroup className="required">
          <Label>Documents à fournir</Label>
          <HtmlEditor
            type="textarea"
            className="form-control"
            name="hospitalizationRequirements"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Lien plan interactif</Label>
          <Input name="interactiveMapUrl" />
        </FormGroup>
        <FormGroup>
          <Label>Date début notifications patient</Label>
          <DatePicker
            name="patientNotificationStartDate"
            id="patientNotificationStartDate"
            className="form-control"
          />
        </FormGroup>
      </div>
    );
  }
}
