import _ from 'lodash';
import {FormGroup} from 'reactstrap';
import {Label} from 'reactstrap';
import {PropTypes} from 'prop-types';
import Input from 'sharedComponents/input/Input';
import React from 'react';
import Select from 'sharedComponents/select/Select';

export default class EditOperatingMethodForm extends React.PureComponent {
  static propTypes = {
    operatingMethodLink: PropTypes.shape({}),
    protocols: PropTypes.arrayOf(PropTypes.shape({})),
    treatmentModes: PropTypes.array.isRequired,
    workflows: PropTypes.array.isRequired,
  };

  render() {
    let treatmentMode = _.find(
      this.props.treatmentModes,
      (t) => t.mdtCode === this.props.operatingMethodLink.mdtCode,
    );

    return (
      <div className="editOperatingMethodForm">
        <FormGroup className="required">
          <Label>Mode de traitement</Label>
          <div className="ps-3">{treatmentMode?.name || <i>Aucun</i>}</div>
        </FormGroup>
        <FormGroup>
          <Label>Code praticien</Label>
          <div className="ps-3">
            {this.props.operatingMethodLink.practitionerCode}
          </div>
        </FormGroup>
        <FormGroup className="required">
          <Label>Praticien</Label>
          <Input
            className="form-control"
            name="practitionerName"
            value={this.props.operatingMethodLink.practitionerName}
            required
          />
        </FormGroup>
        <FormGroup className="required">
          <Label>Protocole Ambulis</Label>
          <Select
            name="ambulisCareId"
            clearable
            searchable
            required
            value={this.props.operatingMethodLink.operatingMethodId}
            options={this.props.protocols.map((p) => {
              return {value: p.id, label: p.name};
            })}
          />
        </FormGroup>
        <FormGroup className="required">
          <Label>Parcours</Label>
          <Select
            name="workflowId"
            clearable
            searchable
            required
            value={this.props.operatingMethodLink.workflowId}
            options={this.props.workflows.map((p) => {
              return {value: p.id, label: p.name};
            })}
          />
        </FormGroup>
      </div>
    );
  }
}
