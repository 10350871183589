import {NotificationManager} from 'react-notifications';

export const ErrorsMessages = {
  AUTH_USERNOTFOUND: "L'utilisateur est introuvable.",
  AUTH_NOREFRESHTOKEN: 'Aucun token de rafraîchissement renseigné.',
  AUTH_NOBEARERTOKEN: "Aucun token d'authentification renseigné.",
  AUTH_INVALIDTOKEN: "Le token d'authentification n'est pas reconnu.",
  AUTH_TOKENSDONOTMATCH: 'Les tokens renseignés ne correspondent pas.',
};

export default class ApiErrorsHelper {
  static getMessage = (code) =>
    typeof code === 'string' && code in ErrorsMessages
      ? ErrorsMessages[code]
      : code;

  static convertApiValidationError = (apiResponse) => {
    if (typeof apiResponse !== 'object') return apiResponse;

    let result = {};
    for (let field in apiResponse) {
      result[field] = [];

      for (let message of apiResponse[field]) {
        result[field].push(ApiErrorsHelper.getMessage(message));
      }
    }

    return result;
  };

  static manage = (error, formRef = null) => {
    if (!error) {
      NotificationManager.error(null, 'Une erreur est survenue');
      return;
    }

    let errorDetails = null;

    if (error.status === 400 && formRef) {
      if (typeof error.raw === 'object') {
        let result = {};
        for (let field in error.raw) {
          result[field] = [];

          for (let message of error.raw[field]) {
            result[field].push(message);
          }
        }
        formRef.current.updateInputsWithError(result);
      } else if (typeof error.raw === 'string') {
        errorDetails = error.raw;
      }

      NotificationManager.error(errorDetails, 'Une erreur est survenue');
    } else {
      let errorDetails = null;

      if (typeof error.raw === 'object') {
        errorDetails = error.raw.error;
      } else if (typeof error.raw === 'string') {
        errorDetails = error.raw;
      }

      NotificationManager.error(errorDetails, 'Une erreur est survenue');
    }
  };
}
