import React from 'react';
import {PropTypes} from 'prop-types';

import {Label, FormGroup} from 'reactstrap';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import Input from 'sharedComponents/input/Input';
import HtmlEditor from 'sharedComponents/htmlEditor/HtmlEditor';
import YoutubeInput from 'sharedComponents/youtubeInput/YoutubeInput';

export default class CreateReleaseNoteButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    modalVisible: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return {modalVisible: !prevState.modalVisible};
    });

  onSubmit = (formData) => {
    let data = {...formData};

    return this.props.onSubmit(data);
  };

  render() {
    return (
      <CreateButtonModal
        text="Nouvelle nouveauté"
        icon="plus-circle"
        onSubmit={this.onSubmit}>
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input type="text" name="title" required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Note</Label>
          <HtmlEditor
            type="textarea"
            className="form-control"
            name="note"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>ID YouTube de la vidéo</Label>
          <YoutubeInput name="videoId" />
        </FormGroup>
      </CreateButtonModal>
    );
  }
}
