import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import React from "react";

export default class DeleteButton extends React.PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
    ]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node,
    onConfirm: PropTypes.func,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    title: "Supprimer un élément",
    tooltip: "Supprimer",
  };

  state = {
    modalVisible: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return { modalVisible: !prevState.modalVisible };
    });

  delete = () => {
    this.toggleModal();

    if (this.props.onConfirm && typeof this.props.onConfirm === "function") {
      this.props.onConfirm(this.props.data);
    }
  };

  render() {
    const {
      title,
      children,
      onConfirm,
      data,
      tooltip,
      ...otherProps
    } = this.props;

    return (
      <span className="deleteButtonContainer">
        <Button
          color="danger"
          size="sm"
          onClick={this.toggleModal}
          title={tooltip}
          {...otherProps}
        >
          <FontAwesomeIcon icon="trash" />
        </Button>
        <Modal isOpen={this.state.modalVisible} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>{title}</ModalHeader>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleModal}>
              Annuler
            </Button>
            <Button color="danger" onClick={this.delete}>
              Supprimer
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}
