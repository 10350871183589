import ApiClient from "admin/services/ApiClient";
import queryString from "query-string";

export default class HealthFoldersCategoriesApi {
  static getByHealthFolderId = (healthFolderId, withArticles) => {
    let params = {};
    if (withArticles) {
      params.withArticles = null;
    }

    return ApiClient.get(
      `healthFolders/${healthFolderId}/categories?${queryString.stringify(
        params
      )}`
    );
  };

  static changeOrder = (categoryId, newOrder) =>
    ApiClient.put(`healthFoldersCategories/${categoryId}/order`, {
      newOrder: newOrder,
    });

  static create = (formData) =>
    ApiClient.post("healthFoldersCategories", formData);

  static update = (categoryId, formData) =>
    ApiClient.put(`healthFoldersCategories/${categoryId}`, formData);

  static delete = (categoryId) =>
    ApiClient.delete(`healthFoldersCategories/${categoryId}`);
}
