import { PropTypes } from 'prop-types'
import React from 'react'
import './SubtitleBlockRenderer.scss'

export default class SubtitleBlockRenderer extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    trunk: PropTypes.bool
  }

  static defaultProps = {
    trunk: false
  }

  render () {
    return (
      <div className={`subtitleBlockRenderer ${this.props.trunk ? 'trunk' : ''}`}>
        {this.props.data.text}
      </div>
    )
  }
}
