import React from 'react';

import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Button, FormGroup, Label} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import HtmlEditor from 'sharedComponents/htmlEditor/HtmlEditor';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import WorkflowsApi from 'clinic/apis/workflows.api';

export default class EditHospitalizationStepPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    step: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.workflowId = this.props.match.params.workflowId;
    this.order = this.props.match.params.order;

    this.props.layoutContextProvider.change({
      title: 'Gestion des parcours',
      activeMenuKey: 'workflows',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, clinics: null},
      async () => {
        let nextState = {isLoading: false};

        Promise.all([
          WorkflowsApi.getHospitalizationStep(this.workflowId, this.order),
          WorkflowsApi.get(this.workflowId),
        ])
          .then((data) => {
            nextState.step = data[0];

            this.props.layoutContextProvider.change({
              title: `Gestion des parcours / ${data[1].name} `,
              activeMenuKey: 'workflows',
            });

            this.setState(nextState);
          })
          .catch((error) => {
            nextState.loadingError = true;
            this.setState(nextState);
          });
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;

    await WorkflowsApi.editHospitalizationSteps(
      this.workflowId,
      this.order,
      formData,
    ).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();
    if (success) {
      NotificationManager.success('Étape modifiée', '');
      this.props.history.push(
        `/clinic/workflows/${this.workflowId}#hospitalizationSteps`,
      );
    }
  };

  render() {
    return (
      <Block
        title={
          <span>
            Modification d'une <strong>étape Jour J</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement de l'étape jour J"
          loadingError={this.state.loadingError}>
          {this.state.step && (
            <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
              <FormGroup className="row required">
                <Label className="col-sm-2">Titre</Label>
                <div className="col-sm-10">
                  <Input name="title" required value={this.state.step.title} />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-sm-2">Équipe</Label>
                <div className="col-sm-10">
                  <Input name="team" required value={this.state.step.team} />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-sm-2">Lieu</Label>
                <div className="col-sm-10">
                  <Input
                    name="location"
                    required
                    value={this.state.step.location}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-sm-2">Description</Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    name="description"
                    required
                    value={this.state.step.description}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-2">Durée</Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    name="duration"
                    value={this.state.step.duration}
                  />
                </div>
              </FormGroup>

              <FormGroup className="text-end">
                <Link
                  to={`/clinic/workflows/${this.workflowId}#hospitalizationSteps`}
                  className="btn btn-outline-primary me-1"
                  type="button">
                  Retour à la liste
                </Link>
                <Button type="submit" color="primary">
                  Valider
                </Button>
              </FormGroup>
            </ValidationForm>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
