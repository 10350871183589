import ApiClient from 'clinic/services/ApiClient';

export default class OperatingMethodsApi {
  static getAvailableAmbulisProtocols = () => ApiClient.get('operatingMethods/availableAmbulisProtocols');

  static getAll = () => ApiClient.get('operatingMethods');

  static get = (id) => ApiClient.get(`preOpInstructions/${id}`);

  static add = (data) => ApiClient.post('operatingMethods', data);

  static edit = (id, data) => ApiClient.patch(`operatingMethods/${id}`, data);

  static delete = (id) => ApiClient.delete(`operatingMethods/${id}`);
}
