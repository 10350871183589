import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {NotificationManager} from 'react-notifications';
import {withLayoutContextProvider} from 'services/LayoutContext';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import MapPlacesApi from 'admin/apis/mapPlaces.api';

class PlacesTab extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    places: null,
  };

  static propTypes = {
    mapId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, places: null},
      async () => {
        let nextState = {isLoading: false};
        let places = await MapPlacesApi.getByMapId(this.props.mapId).catch(
          (e) => {
            nextState.loadingError = true;
            return null;
          },
        );

        if (places) {
          nextState.places = _.orderBy(places, 'name');
        }

        this.setState(nextState);
      },
    );
  };

  deletePlace = async (place) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await MapPlacesApi.delete(place.id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    if (success) {
      NotificationManager.success('Le marqueur a été supprimé', null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>marqueurs</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.places) return null;

    return (
      <div>
        <div className="text-end mb-2">
          <CreateButtonLink
            to={`${this.props.mapId}/place`}
            text="Nouveau marqueur"
          />
        </div>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.places.map((place) => (
              <tr key={place.id}>
                <td>{place.name}</td>
                <td className="width-auto">
                  <span className="mx-2">
                    <EditButtonLink
                      to={`${this.props.mapId}/places/${place.id}`}
                    />
                  </span>
                  <DeleteButton
                    data={place}
                    title="Supprimer une carte"
                    onConfirm={this.deletePlace}>
                    Voulez-vous réellement supprimer le marqueur ?
                    <i>"{place.name}"</i> ?
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}

export default withLayoutContextProvider(PlacesTab);
