import ApiClient from "admin/services/ApiClient";
import queryString from "query-string";

export default class PreventionCategoriesApi {
  static getAll(withChecks = false) {
    let params = {};
    if (withChecks) {
      params.withChecks = null;
    }

    return ApiClient.get(
      `preventionCategories?${queryString.stringify(params)}`
    );
  }

  static getById = (
    id,
    withHealthFolders = false,
    withPreventionChecks = false,
    withSelfAssessmentTests = false
  ) => {
    let params = {};

    if (withHealthFolders) {
      params.withHealthFolders = null;
    }

    if (withPreventionChecks) {
      params.withPreventionChecks = null;
    }

    if (withSelfAssessmentTests) {
      params.withSelfAssessmentTests = null;
    }

    return ApiClient.get(
      `preventionCategories/${id}?${queryString.stringify(params)}`
    );
  };

  static create = (formData) =>
    ApiClient.post("preventionCategories", formData, true);

  static editGeneral = (id, formData) =>
    ApiClient.post(`preventionCategories/${id}/general`, formData, true);

  static delete = (id) => ApiClient.delete(`preventionCategories/${id}`);

  static addHealthFolder = (categoryId, healthFolderId) =>
    ApiClient.post(
      `preventionCategories/${categoryId}/healthFolders/${healthFolderId}`
    );

  static deleteHealthFolder = (categoryId, healthFolderId) =>
    ApiClient.delete(
      `preventionCategories/${categoryId}/healthFolders/${healthFolderId}`
    );

  static addPreventionCheck = (categoryId, preventionCheckId) =>
    ApiClient.post(
      `preventionCategories/${categoryId}/preventionChecks/${preventionCheckId}`
    );

  static deletePreventionCheck = (categoryId, preventionCheckId) =>
    ApiClient.delete(
      `preventionCategories/${categoryId}/preventionChecks/${preventionCheckId}`
    );

  static addSelfAssessmentTest = (categoryId, selfAssessmentTestId) =>
    ApiClient.post(
      `preventionCategories/${categoryId}/selfAssessmentTests/${selfAssessmentTestId}`
    );

  static deleteSelfAssessmentTest = (categoryId, selfAssessmentTestId) =>
    ApiClient.delete(
      `preventionCategories/${categoryId}/selfAssessmentTests/${selfAssessmentTestId}`
    );
}
