import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {FormGroup, Label, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import Block from 'sharedComponents/block/Block';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import HtmlEditor from 'sharedComponents/htmlEditor/HtmlEditor';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import Input from 'sharedComponents/input/Input';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Select from 'sharedComponents/select/Select';
import TooltipSlider from 'sharedComponents/slider/Slider';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import YoutubeInput from 'sharedComponents/youtubeInput/YoutubeInput';

import BookingApi from 'admin/apis/booking.api';
import HealthFoldersApi from 'admin/apis/healthFolders.api';
import PreventionChecksApi from 'admin/apis/preventionChecks.api';

export default class AddPreventionCheckPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    specificSex: null,
    minAge: 18,
    maxAge: 120,
    bookingType: 'none',
    internalSpecialities: null,
    healthFolders: null,
    addVideo: false,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des contrôles de prévention',
      activeMenuKey: 'prevention_checks',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        internalSpecialities: null,
        healthFolders: null,
      },
      async () => {
        let nextState = {isLoading: false};

        let data = await Promise.all([
          BookingApi.getInternalSpecialities(),
          HealthFoldersApi.getAll(false),
        ]).catch((error) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.internalSpecialities = _.sortBy(data[0], 'name');
          nextState.healthFolders = _.sortBy(data[1], 'title').map((hf) => ({
            label: `${hf.title}${hf.published ? '' : ' (non publié)'}`,
            value: hf.id,
          }));
        }

        this.setState(nextState);
      },
    );
  };

  selectSex = (sex) => {
    this.setState({
      specificSex: sex,
    });
  };

  selectBookingType = (type) => {
    this.setState({
      bookingType: type,
    });
  };

  onSliderChanged = (value) => {
    this.setState({
      minAge: value[0],
      maxAge: value[1],
    });
  };

  addVideoOnCheckboxChange = () => {
    this.setState((prevState) => ({
      addVideo: !prevState.addVideo,
    }));
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    formData.specificSex = this.state.specificSex;
    formData.minAge = this.state.minAge;
    formData.maxAge = this.state.maxAge;
    formData.bookingType = this.state.bookingType;

    let error = null;

    await PreventionChecksApi.create(formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Contrôle créé avec succès', null);
      this.props.history.push(`/admin/prevention/checks`);
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Ajout d'un <strong>contrôle de prévention</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.state.internalSpecialities && (
            <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Titre</Label>
                <div className="col-sm-10">
                  <Input className="form-control" name="title" required />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Description courte
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="shortDescription"
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Pourquoi se faire dépister ?
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="whyTrack"
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Qui est concerné ?
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="whoConcerned"
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Quelles modalités de dépistage
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="modalitiesTrack"
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  A qui s'adresser ?
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="whoContact"
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">En savoir +</Label>
                <div className="col-sm-10">
                  <HtmlEditor className="form-control" name="moreInfos" />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-2">Ajouter une vidéo</Label>
                <div className="col-sm-10">
                  <Checkbox
                    id="addVideoInfos"
                    name="addVideoInfos"
                    onChange={this.addVideoOnCheckboxChange}
                  />
                </div>
              </FormGroup>

              {this.state.addVideo && (
                <div>
                  <FormGroup className="row">
                    <Label className="col-form-label col-sm-2">
                      Vidéo "En savoir +" (ID Youtube)
                    </Label>
                    <YoutubeInput
                      className="form-control"
                      name="videoId"
                      required
                    />
                  </FormGroup>

                  <FormGroup className="row required">
                    <Label className="col-form-label col-sm-2">
                      Titre vidéo
                    </Label>
                    <div className="col-sm-10">
                      <Input
                        className="form-control"
                        name="videoTitle"
                        required
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row required">
                    <Label className="col-form-label col-sm-2">
                      Image vidéo
                    </Label>
                    <div className="col-sm-10">
                      <ImageUploader
                        name="videoPicture"
                        helpText="Taille attendue : 1920px par 1080px"
                        required
                      />
                    </div>
                  </FormGroup>
                </div>
              )}

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Sexe</Label>
                <div className="col-sm-10">
                  <div className="d-flex flex-row justify-content-around">
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={!this.state.specificSex ? 'primary' : 'light'}
                      onClick={() => this.selectSex(null)}>
                      Tous
                    </Button>
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={
                        this.state.specificSex === 'M' ? 'primary' : 'light'
                      }
                      onClick={() => this.selectSex('M')}>
                      Homme
                    </Button>
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={
                        this.state.specificSex === 'F' ? 'primary' : 'light'
                      }
                      onClick={() => this.selectSex('F')}>
                      Femme
                    </Button>
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Âge</Label>
                <div className="col-sm-10">
                  <TooltipSlider
                    min={0}
                    max={120}
                    defaultValue={[this.state.minAge, this.state.maxAge]}
                    allowCross={false}
                    marks={{0: '0', 30: '30', 60: '60', 90: '90', 120: '120'}}
                    onAfterChange={this.onSliderChanged}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-2" for="needGeneralPractitioner">
                  Popup parcours de soin
                </Label>
                <div className="col-sm-10">
                  <Checkbox
                    id="needGeneralPractitioner"
                    name="needGeneralPractitioner"
                    label="coc"
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Annuaire</Label>
                <div className="col-sm-10">
                  <div className="d-flex flex-row justify-content-around">
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={
                        this.state.bookingType === 'none' ? 'primary' : 'light'
                      }
                      onClick={() => this.selectBookingType('none')}>
                      Aucun
                    </Button>
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={
                        this.state.bookingType === 'internal'
                          ? 'primary'
                          : 'light'
                      }
                      onClick={() => this.selectBookingType('internal')}>
                      Interne
                    </Button>
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={
                        this.state.bookingType === 'external'
                          ? 'primary'
                          : 'light'
                      }
                      onClick={() => this.selectBookingType('external')}>
                      Externe
                    </Button>
                  </div>
                </div>
              </FormGroup>

              {this.state.bookingType === 'external' && (
                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">
                    Lien externe annuaire
                  </Label>
                  <div className="col-sm-10">
                    <Input
                      type="url"
                      className="form-control"
                      name="externalBookingUrl"
                      required
                    />
                  </div>
                </FormGroup>
              )}

              {this.state.bookingType === 'internal' && (
                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">
                    Spécialités associées
                  </Label>
                  <div className="col-sm-10">
                    <Select
                      name="bookingSpecialitiesIds"
                      clearable
                      searchable
                      required
                      multi
                      options={this.state.internalSpecialities.map((s) => {
                        return {value: s.id, label: s.name};
                      })}
                    />
                  </div>
                </FormGroup>
              )}

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Dossier santé</Label>
                <div className="col-sm-10">
                  <Select
                    id="healthFolderId"
                    name="healthFolderId"
                    placeholder="Choisir un dossier santé"
                    searchable
                    options={this.state.healthFolders}
                  />
                </div>
              </FormGroup>

              <FormGroup className="text-end">
                <Link
                  to="/admin/prevention/checks"
                  className="btn btn-outline-primary me-1"
                  type="button">
                  Retour à la liste
                </Link>
                <Button type="submit" color="primary">
                  Valider
                </Button>
              </FormGroup>
            </ValidationForm>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
