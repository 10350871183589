import {FormGroup} from 'reactstrap';
import {Label} from 'reactstrap';
import {PropTypes} from 'prop-types';
import Input from 'sharedComponents/input/Input';
import React from 'react';

export default class EditOperatingMethodForm extends React.PureComponent {
  static propTypes = {
    convocationPlaceLink: PropTypes.shape({}),
  };

  render() {
    return (
      <div className="editOperatingMethodForm">
        <FormGroup>
          <Label>Lit / Service</Label>
          <div className="ps-3">
            {this.props.convocationPlaceLink?.searchKey || <i>Aucun</i>}
          </div>
        </FormGroup>

        <FormGroup className="required">
          <Label>Lieu de convocation</Label>
          <Input
            className="form-control"
            name="convocationPlace"
            value={this.props.convocationPlaceLink.convocationPlace}
            helpText="Le lieu de convocation apparaîtra sur le SMS Patient, aussi penser à ajouter les pronoms correspondant : à l'accueil, au service, etc.."
            maxLength={255}
            required
          />
        </FormGroup>
      </div>
    );
  }
}
