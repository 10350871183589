import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';

import {FormGroup, Alert} from 'reactstrap';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import WorkflowsApi from 'clinic/apis/workflows.api';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class HospitalizationStepsTab extends React.PureComponent {
  static propTypes = {
    workflowId: PropTypes.number.isRequired,
  };

  state = {
    isLoading: false,
    loadingError: false,
    steps: [],
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({isLoading: true, loadingError: null}, async () => {
      let nextState = {isLoading: false};
      let data = await WorkflowsApi.getHospitalizationSteps(
        this.props.workflowId,
      ).catch((e) => {
        nextState.loadingError = true;
        return [];
      });

      nextState = {
        ...nextState,
        steps: _.orderBy(data, 'order'),
      };

      this.setState(nextState);
    });
  };

  deleteStep = async (order) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await WorkflowsApi.deleteHospitalizationStep(
      this.props.workflowId,
      order,
    ).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Étape supprimée avec succès', null);
      this.loadData();
    }
  };

  onDrop = (result) => {
    if (!result.destination || result.source.index === result.destination.index)
      return;

    this.props.layoutContextProvider.showLoader();

    let steps = this.state.steps;

    steps = reorder(steps, result.source.index, result.destination.index);

    for (var i = 0; i < steps.length; i++) {
      steps[i].order = i + 1;
    }

    this.setState({
      steps: _.orderBy(steps, 'order'),
    });

    WorkflowsApi.reorderHospitalizationSteps(
      this.props.workflowId,
      result.source.index + 1,
      result.destination.index + 1,
    ).then(() => {
      NotificationManager.success('Étape déplacée avec succès', null);
    });

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <div className="hospitalizationStepsTab">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement du parcours jour J"
          loadingError={this.state.loadingError}>
          {this.state.steps && (
            <div>
              <FormGroup className="text-end">
                <CreateButtonLink
                  to={`/clinic/workflows/${this.props.workflowId}/hospitalizationSteps/add`}
                  text="Nouvelle étape"
                />
              </FormGroup>

              {!this.props.displayHospitalizationSteps && (
                <Alert color="warning">
                  L'affichage du déroulé Jour-J a été désactivé dans l'onglet
                  Général
                </Alert>
              )}
              {this.state.steps.length === 0 && (
                <Alert color="info">
                  Aucune étape jour J ajoutée à ce parcours.
                </Alert>
              )}

              <DragDropContext onDragEnd={this.onDrop}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      className={`droppable ${
                        snapshot.isDraggingOver ? 'dragging' : 'notDragging'
                      }`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {this.state.steps.map((step, i) => (
                        <Draggable
                          key={`blockRenderer-${step.order}`}
                          draggableId={`${step.order}`}
                          index={i}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              <div className="item">
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <FontAwesomeIcon icon="arrows-alt" />
                                  </div>
                                  <div className="col">{step.title}</div>
                                  <div className="col-auto">
                                    <EditButtonLink
                                      to={`/clinic/workflows/${this.props.workflowId}/hospitalizationSteps/${step.order}`}>
                                      <FontAwesomeIcon icon="pen" />
                                    </EditButtonLink>

                                    <DeleteButton
                                      title={
                                        <span>
                                          Supprimer <i>{step.title}</i>
                                        </span>
                                      }
                                      data={step.order}
                                      onConfirm={this.deleteStep}
                                      className="ms-1">
                                      Voulez-vous réellement supprimer l'étape{' '}
                                      <i>{step.title}</i>
                                    </DeleteButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </PageDataLoader>
      </div>
    );
  }
}

export default withLayoutContextProvider(HospitalizationStepsTab);
