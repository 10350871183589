import React from 'react';
import PropTypes from 'prop-types';

import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import {withLayoutContextProvider} from 'services/LayoutContext';

import {FormGroup} from 'reactstrap';
import StructuredContent from 'sharedComponents/structuredContent/StructuredContent';

import PreOpInstructionsApi from 'clinic/apis/preOpInstructions.api';

class ContentTab extends React.PureComponent {
  static props = {
    preOpInstruction: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      structuredContent: PropTypes.shape({
        id: PropTypes.number.isRequired,
        blocks: PropTypes.array.isRequired,
      }),
    }),
  };

  state = {
    structuredContent: this.props.preOpInstruction.structuredContent,
  };

  onBlockAdded = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await PreOpInstructionsApi.addBlock(
      this.props.preOpInstruction.id,
      block.type,
      block.data,
    ).catch((e) => {
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      this.setState({structuredContent: modifiedContent});
      NotificationManager.success("L'élément a été ajouté", null);
      return Promise.resolve();
    }
  };

  onBlockMoved = (movement, reorderedBlocks) => {
    this.props.layoutContextProvider.showLoader();

    var previousContent = this.state.structuredContent;
    this.setState(
      {
        structuredContent: {...previousContent, blocks: reorderedBlocks},
      },
      async () => {
        let movedBlock = previousContent.blocks[movement.from];

        let error = null;
        let newContent = await PreOpInstructionsApi.moveBlock(
          this.props.preOpInstruction.id,
          movedBlock.id,
          {position: movement.to},
        ).catch((e) => {
          error = e;
          return null;
        });

        let nextState = {};
        if (error) {
          NotificationManager.error(error.message, 'Une erreur est survenue');
          nextState.structuredContent = previousContent;
        } else {
          nextState.structuredContent = newContent;
          NotificationManager.success("L'élément a été déplacé", null);
        }

        this.setState(nextState);
        this.props.layoutContextProvider.hideLoader();
        return Promise.resolve();
      },
    );
  };

  onBlockEdited = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await PreOpInstructionsApi.editBlock(
      this.props.preOpInstruction.id,
      block.id,
      block.data,
    ).catch((e) => {
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      this.setState({structuredContent: modifiedContent});
      NotificationManager.success("L'élément a été modifié", null);
      return Promise.resolve();
    }
  };

  onBlockDeleted = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await PreOpInstructionsApi.deleteBlock(
      this.props.preOpInstruction.id,
      block.id,
    ).catch((e) => {
      ApiErrorsHelper.manage(e);
      error = e;
      return null;
    });

    if (!error) {
      this.setState({structuredContent: modifiedContent});
      NotificationManager.success("L'élément a été supprimé", null);
    }

    this.props.layoutContextProvider.hideLoader();
    return Promise.resolve();
  };

  render() {
    return !this.props.preOpInstruction ? null : (
      <div>
        <StructuredContent
          editable
          blocks={this.state.structuredContent.blocks}
          onBlockAdded={this.onBlockAdded}
          onBlockMoved={this.onBlockMoved}
          onBlockEdited={this.onBlockEdited}
          onBlockDeleted={this.onBlockDeleted}
        />
        <FormGroup className="text-end">
          <Link
            to="/clinic/preOpInstructions"
            className="btn btn-outline-primary"
            type="button">
            Retour à la liste
          </Link>
        </FormGroup>
      </div>
    );
  }
}

export default withLayoutContextProvider(ContentTab);
