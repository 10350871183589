import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Link} from 'react-router-dom';
import {Button, FormGroup, Label} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import Input from 'sharedComponents/input/Input';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Select from 'sharedComponents/select/Select';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import SelfAssessmentTestsApi from 'admin/apis/selfAssessmentTestsApi';
import HealthFoldersApi from 'admin/apis/healthFolders.api';
import BookingApi from 'admin/apis/booking.api';
import MapsApi from 'admin/apis/maps.api';

export default class AddSelfAssessmentTestPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  state = {
    isLoading: true,
    loadingError: false,
    technicalCodes: null,
    healthFolders: null,
    specialities: null,
    maps: null,
    addMap: false,
    map: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des tests d'auto-évaluation",
      activeMenuKey: 'self_assessment_tests',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        technicalCodes: null,
        healthFolders: null,
        specialities: null,
        maps: null,
      },
      async () => {
        let nextState = {isLoading: false};
        let data = await Promise.all([
          SelfAssessmentTestsApi.getAvailableTechnicalCodes(),
          HealthFoldersApi.getAll(true),
          BookingApi.getInternalSpecialities(),
          MapsApi.getAll(),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.technicalCodes = _.map(_.sortBy(data[0], 'label'), (c) => ({
            value: c.code,
            label: `${c.label} (${c.code})`,
          }));

          nextState.healthFolders = _.map(_.sortBy(data[1], 'title'), (f) => ({
            value: f.id,
            label: `${f.title}`,
          }));

          nextState.specialities = _.map(_.sortBy(data[2], 'name'), (s) => ({
            value: s.id,
            label: `${s.name}`,
          }));

          nextState.maps = _.map(_.sortBy(data[3], 'title'), (m) => ({
            value: m.id,
            label: `${m.title}`,
          }));
        }

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await SelfAssessmentTestsApi.create(formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success(
        "Le test d'auto-évaluation a été créé.",
        null,
      );
      this.props.history.push(`/admin/selfAssessmentTests`);
    }
  };

  addMapOnChange = (map) => {
    this.setState({map: map});
  };

  render() {
    return (
      <Block
        title={
          <span>
            Création d'un <strong>test d'auto-évaluation</strong>
          </span>
        }
        irreducible>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (this.state.isLoading) return null;

    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Titre</Label>
          <div className="col-sm-10">
            <Input className="form-control" name="title" required />
          </div>
        </FormGroup>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Titre court</Label>
          <div className="col-sm-10">
            <Input className="form-control" name="shortTitle" required />
          </div>
        </FormGroup>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Description courte</Label>
          <div className="col-sm-10">
            <Input className="form-control" name="shortDescription" required />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">
            Code technique interne
          </Label>
          <div className="col-sm-10">
            <Select
              id="technicalCode"
              name="technicalCode"
              placeholder="Choisir un code"
              searchable
              options={this.state.technicalCodes}
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Image</Label>
          <div className="col-sm-10">
            <ImageUploader
              name="picture"
              helpText="Taille attendue : 200px par 350px"
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Icone</Label>
          <div className="col-sm-10">
            <ImageUploader
              name="icon"
              helpText="Taille attendue : 100px par 100px"
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Dossier santé</Label>
          <div className="col-sm-10">
            <Select
              id="healthFolderId"
              name="healthFolderId"
              placeholder="Choisir un dossier santé"
              searchable
              options={this.state.healthFolders}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Spécialités</Label>
          <div className="col-sm-10">
            <Select
              id="specialitiesIds"
              name="specialitiesIds"
              placeholder="Choisir une ou des spécialité(s)"
              searchable
              multi
              options={this.state.specialities}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Cartes interactives</Label>
          <div className="col-sm-10">
            <Select
              id="mapId"
              name="mapId"
              placeholder="Choisir une carte interactive"
              searchable
              onChange={this.addMapOnChange}
              options={this.state.maps}
            />
          </div>
        </FormGroup>

        {this.state.map !== null && (
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Intro carte</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="mapIntro" required />
            </div>
          </FormGroup>
        )}

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">
            Version minimale de l'app
          </Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="minimalAppVersion"
              helpText="Format attendu : X.Y.Z"
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="text-end">
          <Link
            to="/admin/selfAssessmentTests"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  };
}
