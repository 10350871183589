import React from 'react';
import {PropTypes} from 'prop-types';

import _ from 'lodash';

import {Table, FormGroup, Label} from 'reactstrap';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import Select from 'sharedComponents/select/Select';

export default class PreventionChecksTab extends React.PureComponent {
  static propTypes = {
    allPreventionChecks: PropTypes.arrayOf(PropTypes.object).isRequired,
    categoryPreventionChecks: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  onAdd = (data) => this.props.onAdd(data.preventionCheckId);

  onDelete = (id) => this.props.onDelete(id);

  render() {
    const {categoryPreventionChecks, allPreventionChecks} = this.props;

    const usedIds = categoryPreventionChecks.map((f) => f.id);
    const addablePreventionChecks = _.filter(
      allPreventionChecks,
      (f) => !usedIds.includes(f.id),
    ).map((f) => ({
      label: f.title,
      value: f.id,
    }));

    const orderedPreventionChecks = _.sortBy(categoryPreventionChecks, 'title');

    return (
      <div>
        <FormGroup className="text-end">
          <CreateButtonModal
            text="Ajouter un contrôle de prévention"
            onSubmit={this.onAdd}>
            <Label>Contrôle de prévention</Label>
            <Select
              id="preventionCheckId"
              name="preventionCheckId"
              placeholder="Choisir un contrôle de prévention"
              searchable
              options={addablePreventionChecks}
              required
            />
          </CreateButtonModal>
        </FormGroup>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orderedPreventionChecks.map((preventionCheck) => (
              <tr key={`${preventionCheck.id}`}>
                <td>{preventionCheck.title}</td>
                <td className="text-center width-auto">
                  <DeleteButton
                    data={preventionCheck.id}
                    onConfirm={this.onDelete}
                    tooltip={`Enlever ${preventionCheck.title}`}>
                    Voulez-vous réellement enlever le contrôle
                    <i>{preventionCheck.title}</i> de la catégorie ?
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
