import ApiClient from "admin/services/ApiClient";

export default class MapsApi {
  static getAll = () => ApiClient.get("maps");

  static get = (id) => ApiClient.get(`maps/${id}`);

  static add = (data) => ApiClient.post("maps", data, true);

  static editGeneral = (id, data) =>
    ApiClient.post(`maps/${id}/general`, data, true);

  static delete = (id) => ApiClient.delete(`maps/${id}`);
}
