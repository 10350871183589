import ApiClient from "admin/services/ApiClient";

export default class AdminsApi {
  static getAll() {
    return ApiClient.get("admins");
  }

  static create = (data) => ApiClient.post('admins', data);
  static delete = (id) => ApiClient.delete(`admins/${id}`);
  static update = (id, data) => ApiClient.patch(`admins/${id}`, data);
}
