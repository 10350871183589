import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, FormGroup} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';

import PreventionChecksApi from 'admin/apis/preventionChecks.api';

export default class PreventionChecksPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    checks: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des contrôles de prévention',
      activeMenuKey: 'prevention_checks',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, checks: null},
      async () => {
        let nextState = {isLoading: false};
        let checks = await PreventionChecksApi.getAll(true).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (checks) {
          nextState.checks = _.sortBy(checks, 'title');
        }

        this.setState(nextState);
      },
    );
  };

  onDelete = async (checkId) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await PreventionChecksApi.delete(checkId).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success(
        'Le contrôle de prévention a été supprimé',
        null,
      );
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>contrôles de prévention</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.checks) return null;

    return (
      <div>
        <FormGroup className="text-end">
          <CreateButtonLink
            to="/admin/prevention/checks/add"
            text="Nouveau contrôle"
            className="ms-2"
          />
        </FormGroup>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.checks.map((check) => (
              <tr key={`${check.id}`}>
                <td>{check.title}</td>
                <td className="text-center width-auto">
                  <EditButtonLink
                    to={`/admin/prevention/checks/${check.id}`}
                    tooltip={`Modifier ${check.title}`}
                    className="me-1"
                  />
                  <DeleteButton
                    data={check.id}
                    onConfirm={this.onDelete}
                    tooltip={`Supprimer ${check.title}`}>
                    Voulez-vous réellement supprimer le contrôle de prévention
                    <i>{check.title}</i> ?
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}
