import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';

import {Row, Col, Label, FormGroup, Alert, Button} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import Input from 'sharedComponents/input/Input';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import QoDMessagesList from 'sharedPagesComponents/qod/QoDMessagesList/QoDMessagesList';
import Select from 'sharedComponents/select/Select';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ClinicsApi from 'admin/apis/clinics.api';
import QoDApi from 'admin/apis/qod.api';

export default class SearchPage extends React.PureComponent {
  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'QoD - Recherche',
      activeMenuKey: 'qod_search',
    });

    this.loadData();
  }

  state = {
    isLoading: false,
    loadingError: false,
    clinics: null,

    loaded: false,
    messages: [],
    errorsDescriptions: [],
  };

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        clinics: null,
      },
      async () => {
        let nextState = {isLoading: false};
        let clinics = await ClinicsApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return [];
        });

        nextState.clinics = _.sortBy(clinics, 'longName');
        this.setState(nextState);
      },
    );
  };

  onSubmit = (formData) => {
    this.setState(
      {loaded: false, messages: [], errorsDescriptions: []},
      async () => {
        this.props.layoutContextProvider.showLoader();
        let nextState = {loaded: true};

        let error = null;
        let data = await Promise.all([
          QoDApi.getErrorsDescriptions(),
          QoDApi.getLogsFromMedicalStay(
            formData.clinicCode,
            formData.medicalStayGapId,
          ),
        ]).catch((e) => {
          error = e;
          if (e.status !== 404) {
            NotificationManager.error(e.message, 'Une erreur est survenue');
          }
        });

        this.props.layoutContextProvider.hideLoader();

        if (!error) {
          nextState.messages = data[1].sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateA < dateB ? 1 : -1;
          });

          nextState.errorsDescriptions = data[0];
        }

        this.setState(nextState);
      },
    );
  };

  render() {
    const clinicSelectOptions = this.state.clinics
      ? this.state.clinics.map((c) => ({
          label: `${c.shortName} - ${c.name}`,
          value: c.shortName,
        }))
      : [];

    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        loadData={this.loadData}
        loadingText="Chargement des cliniques"
        useBlock>
        {this.state.clinics && (
          <>
            <Block
              irreducible
              title={
                <span>
                  Filtres de <strong>recherche</strong>
                </span>
              }>
              <ValidationForm onSubmit={this.onSubmit}>
                <Row>
                  <Col sm={4}>
                    <Label>Clinique</Label>
                    <FormGroup>
                      <Select
                        id="clinicCode"
                        name="clinicCode"
                        required
                        searchable
                        options={clinicSelectOptions}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <Label>Numéro de dossier GAP</Label>
                    <FormGroup>
                      <Input
                        className="form-control"
                        name="medicalStayGapId"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Button type="submit" color="primary">
                  Valider
                </Button>
              </ValidationForm>
            </Block>

            {this.state.loaded && this.state.messages.length === 0 && (
              <Alert color="warning">
                Aucun message correspondant à cet identifiant
              </Alert>
            )}

            <QoDMessagesList
              messages={this.state.messages}
              errorsDescriptions={this.state.errorsDescriptions}
            />
          </>
        )}
      </PageDataLoader>
    );
  }
}
