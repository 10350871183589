import _ from 'lodash';
import {PropTypes} from 'prop-types';
import InlineLoader from 'sharedComponents/inlineLoader/InlineLoader';
import React from 'react';
import Select from 'sharedComponents/select/Select';
import SelfAssessmentTestsApi from 'admin/apis/selfAssessmentTestsApi';

import ExternalServicesApi from 'admin/apis/externalServices.api';
import HealthFoldersApi from 'admin/apis/healthFolders.api';
import MapsApi from 'admin/apis/maps.api';

const getInitialSelectedType = (contentDeeplink) => {
  if (contentDeeplink) {
    if (contentDeeplink.healthFolderId) {
      return 'healthFolder';
    } else if (contentDeeplink.selfAssessmentTestId) {
      return 'selfAssessmentTest';
    } else if (contentDeeplink.mapId) {
      return 'map';
    } else if (contentDeeplink.externalServiceKey) {
      return 'externalService';
    } else if (contentDeeplink.screenCode) {
      return 'vaccination';
    }
  }

  return null;
};

const getInitialSelectedId = (contentDeeplink) => {
  if (contentDeeplink) {
    if (contentDeeplink.healthFolderId) {
      return contentDeeplink.healthFolderId;
    } else if (contentDeeplink.selfAssessmentTestId) {
      return contentDeeplink.selfAssessmentTestId;
    } else if (contentDeeplink.mapId) {
      return contentDeeplink.mapId;
    } else if (contentDeeplink.externalServiceKey) {
      return contentDeeplink.externalServiceKey;
    } else if (contentDeeplink.screenCode) {
      return contentDeeplink.screenCode;
    }
  }

  return null;
};

export default class ContentDeeplinkEditor extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    contentDeeplink: PropTypes.shape({
      id: PropTypes.number.isRequired,
      healthFolderId: PropTypes.number,
      selfAssessmentTestId: PropTypes.number,
      mapId: PropTypes.number,
    }),
  };

  static defaultProps = {
    name: 'contentDeeplink',
  };

  state = {
    isLoading: false,
    error: null,

    selectedType: getInitialSelectedType(
      this.props.contentDeeplink,
    ),
    selectedId: getInitialSelectedId(
      this.props.contentDeeplink,
    ),

    healthFolders: [],
    selfAssessmentTests: [],
    maps: [],
    externalServices: {},
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({isLoading: true}, async () => {
      let nextState = {isLoading: false};
      let data = await Promise.all([
        HealthFoldersApi.getAll(),
        SelfAssessmentTestsApi.getAll(),
        MapsApi.getAll(),
        ExternalServicesApi.getAll(),
      ]).catch((e) => {
        nextState.error = e;
        return null;
      });

      if (data) {
        nextState.healthFolders = _.orderBy(data[0], 'title');
        nextState.selfAssessmentTests = data[1];
        nextState.maps = data[2];
        nextState.externalServices = data[3];
      }

      this.setState(nextState);
    });
  };



  getIdPropName = (type) => {
    switch (type) {
      case 'externalService':
        return `externalServicekey`;
      default:
        return `${type}Id`;
    }
  };

  onTypeChange = (type) => {
    if (type === this.state.selectedType) {
      return;
    }

    this.setState({selectedType: type, selectedId: null}, () => {
      if (this.props.onChange) {
        this.props.onChange(
          type
            ? {
                [this.getIdPropName(type)]: null,
              }
            : {},
        );
      }
    });
  };

  onIdChange = (id) => {
    if (id === this.state.selectedId) {
      return;
    }

    this.setState({selectedId: id}, () => {
      if (this.props.onChange) {
        this.props.onChange({
          [this.getIdPropName(this.state.selectedType)]: id,
        });
      }
    });
  };

  render() {
    const {id, name} = this.props;
    const {
      selectedType,
      selectedId,
      isLoading,
      error,
      healthFolders,
      selfAssessmentTests,
      maps,
      externalServices,
    } = this.state;

    let idOptions = [];
    switch (selectedType) {
      case 'healthFolder':
        idOptions = healthFolders
          ? healthFolders.map((hf) => ({
              value: hf.id,
              label: hf.title,
            }))
          : [];
        break;
      case 'selfAssessmentTest':
        idOptions = selfAssessmentTests
          ? selfAssessmentTests.map((t) => ({
              value: t.id,
              label: t.title,
            }))
          : [];
        break;
      case 'map':
        idOptions = maps
          ? maps.map((m) => ({
              value: m.id,
              label: m.title,
            }))
          : [];
        break;
      case 'externalService':
        if (externalServices) {
          idOptions = [];
          for (let prop in externalServices) {
            idOptions.push({
              value: prop,
              label: externalServices[prop],
            });
          }
        }
        break;
      default:
        break;
    }

    return (
      <div>
        <Select
          name={`${name}Type`}
          id={`${id || name}Type`}
          placeholder="Choisir un type"
          value={selectedType}
          onChange={this.onTypeChange}
          options={[
            {label: 'Dossier santé', value: 'healthFolder'},
            {label: "Test d'auto-évaluation", value: 'selfAssessmentTest'},
            {label: 'Carte intéractive', value: 'map'},
            {label: 'Service externe', value: 'externalService'},
            {label: 'Calendrier de vaccination', value: 'vaccination'},
          ]}
        />
        <div className="mt-1">
          {isLoading && <InlineLoader text="Chargement..." />}
          {!isLoading && selectedType && selectedType !== 'vaccination' && (
            <Select
              required
              name={`${name}Id`}
              id={`${id || name}Id`}
              onChange={this.onIdChange}
              placeholder="Choisir un élément"
              value={selectedId}
              clearable={false}
              options={idOptions}
            />
          )}
          {error && <div className="text-danger">Une erreur est survenue.</div>}
        </div>
      </div>
    );
  }
}
