import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import {FormGroup, Label} from 'reactstrap';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Select from 'sharedComponents/select/Select';
import Checkbox from 'sharedComponents/checkbox/Checkbox';

import PreOpInstructionsApi from 'clinic/apis/preOpInstructions.api';
import PostOpInstructionsApi from 'clinic/apis/postOpInstructions.api';
import AmbulisProtocolsApi from 'clinic/apis/ambulisProtocols.api';

export default class EditOperatingMethodForm extends React.PureComponent {
  static props = {
    operatingMethod: PropTypes.object.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    ambulisProtocol: null,
    preopInstructions: null,
    postopInstructions: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: null,
        ambulisProtocol: null,
        preopInstructions: null,
        postopInstructions: null,
      },
      async () => {
        let nextState = {isLoading: false};

        const data = await Promise.all([
          AmbulisProtocolsApi.get(this.props.operatingMethod.ambulisId),
          PreOpInstructionsApi.getAll(),
          PostOpInstructionsApi.getAll(),
        ]).catch((error) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.ambulisProtocol = data[0];

          let preop = data[1].map((p) => {
            return {value: p.id, label: p.title};
          });

          nextState.preopInstructions = _.sortBy(preop, 'label');

          let postop = data[2].map((p) => {
            return {value: p.id, label: p.title};
          });

          nextState.postopInstructions = _.sortBy(postop, 'label');
        }

        this.setState(nextState);
      },
    );
  };

  render() {
    return (
      <div className="createConvocationPlaceForm">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.state.ambulisProtocol && (
            <div>
              <FormGroup>
                <Label>Protocole</Label>
                <div className="ps-3">{this.state.ambulisProtocol.name}</div>
              </FormGroup>
              <FormGroup className="required">
                <Label>Consignes préopératoires ici</Label>
                <Select
                  name="preopInstructionsId"
                  clearable
                  searchable
                  required
                  value={this.props.operatingMethod.preopInstructionsId}
                  options={this.state.preopInstructions}
                />
              </FormGroup>
              <FormGroup>
                <Label>Consignes postopératoires</Label>
                <Select
                  name="postopInstructionsId"
                  clearable
                  searchable
                  value={this.props.operatingMethod.postOpInstructionsId}
                  options={this.state.postopInstructions}
                />
              </FormGroup>
              <FormGroup>
                <Label>Affichage de l'heure de convocation</Label>
                <Checkbox
                  name="displayConvocationDate"
                  label="Oui"
                  id="displayConvocationDate"
                  checked={this.props.operatingMethod.displayConvocationDate}
                />
              </FormGroup>
            </div>
          )}
        </PageDataLoader>
      </div>
    );
  }
}
