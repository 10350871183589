import React from 'react';

import {NotificationManager} from 'react-notifications';
import _ from 'lodash';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, Badge} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import CreateNewsButton from './createNewsButton/CreateNewsButton';
import PreviewNewsButton from './previewNewsButton/PreviewNewsButton';

import NewsApi from 'admin/apis/news.api';

export default class NewsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    news: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des actualités',
      activeMenuKey: 'news',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, news: null},
      async () => {
        let nextState = {isLoading: false};
        let news = await NewsApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (news) {
          nextState.news = _.orderBy(news, 'displayStartDate', 'desc');
        }

        this.setState(nextState);
      },
    );
  };

  onCreateNews = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let createdNews = await NewsApi.add(formData).catch((e) => {
      error = e;
      return null;
    });
    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("L'actualité a été créée", null);
    this.props.history.push(`news/${createdNews.id}`);
    return Promise.resolve();
  };

  deleteNews = async (news) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await NewsApi.delete(news.id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    if (success) {
      NotificationManager.success("L'actualité a été supprimée", null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>actualités</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderType(type) {
    switch (type) {
      case 'video':
        return 'Vidéo';
      case 'external':
        return 'Lien externe';
      default:
        return 'Texte';
    }
  }

  renderContent = () => {
    if (!this.state.news) return null;

    return (
      <div>
        <div className="text-end mb-2">
          <CreateNewsButton onSubmit={this.onCreateNews} />
        </div>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th className="width-auto text-center">Id</th>
              <th>Titre</th>
              <th className="width-auto text-center">Type</th>
              <th className="width-auto text-center"></th>
              <th className="width-auto text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.news.map((n) => (
              <tr key={n.id}>
                <td className="width-auto text-center">{n.id}</td>
                <td>{n.title}</td>
                <td className="width-auto text-center">
                  {this.renderType(n.type)}
                </td>
                <td className="width-auto text-center">
                  <Badge color={n.published ? 'success' : 'danger'}>
                    {n.published ? 'Publiée' : 'Non publiée'}
                  </Badge>
                </td>
                <td className="width-auto text-center text-end">
                  <PreviewNewsButton news={n} />
                  <span className="mx-2">
                    <EditButtonLink to={`news/${n.id}`} />
                  </span>
                  <DeleteButton
                    data={n}
                    title="Supprimer cette actualité"
                    onConfirm={this.deleteNews}>
                    Voulez-vous réellement supprimer cette actualité{' '}
                    <i>"{n.title}"</i> ?
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}
