import React from "react";
import { PropTypes } from "prop-types";
import { Input as ReactStrapInput } from "reactstrap";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ArticleExportModal extends React.PureComponent {
  static propTypes = {
    article: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
  };

  state = {
    content: null,
    copied: false,
    opened: false,
  };

  getFilteredBlocks = () => 
    this.props.article.structuredContent.blocks.map(({ type, data }) => {
      const { healthFacility, ...otherData } = data;
      return { type, ...otherData };
  });

  copyToClipboard = () => {
    const filteredBlocks = this.getFilteredBlocks();
    const jsonContent = JSON.stringify(filteredBlocks);
    navigator.clipboard.writeText(jsonContent).then(() => {
        this.setState({ copied: true });
        setTimeout(() => {
          this.setState({ copied: false });
        }, 5000);
      });
  };

  render() {
    if (!this.props.article) {
      return null;
    }

    const filteredBlocks = this.getFilteredBlocks();

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          {this.props.article.title}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Contenu</Label>
            <ReactStrapInput
              type="textarea"
              value={JSON.stringify(filteredBlocks)}
              readOnly
              rows={5}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color={this.state.copied ? 'success' : 'primary'} onClick={this.copyToClipboard}>
            <FontAwesomeIcon icon="copy" className="me-2" />
            {this.state.copied ? 'Copié !' : 'Copier'}
          </Button>
          <Button color="primary" onClick={this.props.onToggle}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
