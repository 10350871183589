import React from 'react';
import classnames from 'classnames';

import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import GeneralTab from './generalTab/GeneralTab';
import ContentTab from './contentTab/ContentTab';
import ArticlesTab from './articlesTab/ArticlesTab';

import PostOpInstructionsApi from 'clinic/apis/postOpInstructions.api';

export default class EditPostOpInstructionsPage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : 'general',
    isLoading: true,
    loadingError: false,
    postOpInstruction: null,
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: 'Gestion des articles',
      activeMenuKey: 'postOpInstructions',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, postOpInstruction: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.postOpInstruction = await PostOpInstructionsApi.get(
          this.id,
        ).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      },
    );
  };

  goToGeneralTab = () => {
    this.setState({activeTab: 'general'});
    this.props.history.replace('#general');
  };

  goToContentTab = () => {
    this.setState({activeTab: 'content'});
    this.props.history.replace('#content');
  };

  goToArticlesTab = () => {
    this.setState({activeTab: 'articles'});
    this.props.history.replace('#articles');
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong>consigne postop</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement d'une consigne postop">
          {this.state.postOpInstruction && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 'general',
                    })}
                    onClick={this.goToGeneralTab}>
                    Général
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 'content',
                    })}
                    onClick={this.goToContentTab}>
                    Contenu
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 'articles',
                    })}
                    onClick={this.goToArticlesTab}>
                    Articles
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="general">
                  <GeneralTab
                    postOpInstruction={this.state.postOpInstruction}
                  />
                </TabPane>
                <TabPane tabId="content">
                  <ContentTab
                    postOpInstruction={this.state.postOpInstruction}
                  />
                </TabPane>
                <TabPane tabId="articles">
                  <ArticlesTab
                    postOpInstructionId={this.state.postOpInstruction.id}
                  />
                </TabPane>
              </TabContent>
            </>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
