import React from 'react';
import {PropTypes} from 'prop-types';

import {FormGroup, Label} from 'reactstrap';
import Input from 'sharedComponents/input/Input';
import Checkbox from 'sharedComponents/checkbox/Checkbox';

export default class AdminForm extends React.PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      password: PropTypes.string,
      isSuperAdmin: PropTypes.bool,
    }),
  };

  static defaultProps = {
    user: null,
  };

  render() {
    return (
      <div className="userForm">
        <FormGroup className="required">
          <Label>Nom</Label>
          <Input
            name="name"
            required
            value={this.props.user ? this.props.user.name : ''}
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            required
            value={this.props.user ? this.props.user.email : ''}
          />
        </FormGroup>

        <FormGroup className={this.props.user ? '' : 'required'}>
          <Label>Mot de passe</Label>
          <Input
            type="password"
            name="password"
            required={this.props.user == null}
          />
        </FormGroup>

        <FormGroup>
          <Label>Droits</Label>
          <div className="ms-4">
            <Checkbox
              id="isSuperAdmin"
              name="isSuperAdmin"
              label="Super Admin"
              checked={this.props.user && this.props.user.isSuperAdmin}
            />
          </div>
        </FormGroup>
      </div>
    );
  }
}
