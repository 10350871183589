import React from 'react';
import {PropTypes} from 'prop-types';

import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import Input from 'sharedComponents/input/Input';
import ValidationErrors from 'services/ValidationErrors';

export default class EditButton extends React.PureComponent {
  static propTypes = {
    ruleAssociation: PropTypes.object.isRequired,
    ruleModel: PropTypes.object.isRequired,
    treatmentMode: PropTypes.object,
    ambulisProtocol: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    modalVisible: false,
    selectedManagementRule: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleModal = () => {
    if (this._isMounted) {
      this.setState((prevState) => {
        return {modalVisible: !prevState.modalVisible};
      });
    }
  };

  handleSubmit = async (formData) => {
    for (const property in formData.params) {
      const config = this.props.ruleModel.params.find(
        (p) => p.code === property,
      );

      switch (config.type) {
        case 'integer':
          formData.params[property] = parseInt(formData.params[property]);
          break;

        default:
          break;
      }
    }

    if (this.props.onSubmit && typeof this.props.onSubmit === 'function') {
      let success = true;

      await this.props
        .onSubmit(this.props.ruleAssociation, formData)
        .catch((error) => {
          ApiErrorsHelper.manage(error, this.form);
          success = false;
        });

      if (success) {
        this.toggleModal();
      }
    }
  };

  onSelectRule = (data) => {
    const rule = this.props.managementRules.find((r) => r.code === data);
    this.setState({
      selectedManagementRule: rule,
    });
  };

  getValidations = (param) => {
    switch (param.type) {
      case 'hour':
        return {
          matchRegexp: /([01][0-9]|2[0-3]):[0-5][0-9]/,
        };

      case 'integer':
        return 'isInt';

      default:
        return null;
    }
  };

  render() {
    const {ruleAssociation, ruleModel, treatmentMode, ambulisProtocol} =
      this.props;

    return (
      <span className="createButtonContainer">
        <Button
          color="secondary"
          size="sm"
          onClick={this.toggleModal}
          title=" Modifier une règle de gestion">
          <FontAwesomeIcon icon="pen" />
        </Button>
        <Modal isOpen={this.state.modalVisible} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Modifier une règle de gestion
          </ModalHeader>
          <ValidationForm ref={this.form} onSubmit={this.handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label>Mode de traitement</Label>
                <div className="ps-3">
                  {treatmentMode ? treatmentMode.name : 'Tous'}
                </div>
              </FormGroup>

              <FormGroup>
                <Label>Protocole Ambulis</Label>
                <div className="ps-3">
                  {ambulisProtocol ? ambulisProtocol.name : 'Tous'}
                </div>
              </FormGroup>

              <FormGroup>
                <Label>Règle de gestion</Label>
                <div className="ps-3">{ruleModel.title}</div>
              </FormGroup>

              {ruleModel.params && ruleModel.params.length > 0 && (
                <>
                  <hr />
                  {ruleModel.params.map((param) => (
                    <FormGroup className="required">
                      <Label>{param.title}</Label>
                      <Input
                        value={ruleAssociation.params[param.code]}
                        type="text"
                        name={`params[${param.code}]`}
                        validations={this.getValidations(param)}
                        validationErrors={ValidationErrors}
                        required
                      />
                    </FormGroup>
                  ))}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.toggleModal}>
                Annuler
              </Button>
              <Button color="primary" type="submit">
                Valider
              </Button>
            </ModalFooter>
          </ValidationForm>
        </Modal>
      </span>
    );
  }
}
