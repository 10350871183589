import ApiClient from "admin/services/ApiClient";
import queryString from "query-string";

export default class BookingApi {
  static getBookingSpecialities = () => ApiClient.get(`bookingSpecialities`);

  static getInternalSpecialities = (withPreventionChecks = false) => {
    let params = {};
    if (withPreventionChecks) {
      params.withPreventionChecks = null;
    }

    return ApiClient.get(
      `internalBookingSpecialities?${queryString.stringify(params)}`
    );
  };

  static createInternalSpeciality = (formData) =>
    ApiClient.post(`internalBookingSpecialities`, formData);

  static editInternalSpeciality = (id, formData) =>
    ApiClient.patch(`internalBookingSpecialities/${id}`, formData);

  static deleteInternalSpeciality = (id) =>
    ApiClient.delete(`internalBookingSpecialities/${id}`);
}
