import React from 'react';

import {NotificationManager} from 'react-notifications';
import classnames from 'classnames';
import _ from 'lodash';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {FormGroup, Label, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import Block from 'sharedComponents/block/Block';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import HtmlEditor from 'sharedComponents/htmlEditor/HtmlEditor';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import Input from 'sharedComponents/input/Input';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Select from 'sharedComponents/select/Select';
import TooltipSlider from 'sharedComponents/slider/Slider';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import YoutubeInput from 'sharedComponents/youtubeInput/YoutubeInput';

import BookingApi from 'admin/apis/booking.api';
import HealthFoldersApi from 'admin/apis/healthFolders.api';
import PreventionChecksApi from 'admin/apis/preventionChecks.api';

export default class EditPreventionCheckPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    check: null,
    internalSpecialities: null,
    healthFolders: null,
    specificSex: null,
    minAge: 18,
    maxAge: 120,
    bookingType: 'none',
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des contrôles de prévention',
      activeMenuKey: 'prevention_checks',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        healthFolders: null,
        internalSpecialities: null,
      },
      async () => {
        let nextState = {isLoading: false};

        let data = await Promise.all([
          BookingApi.getInternalSpecialities(),
          PreventionChecksApi.get(this.props.match.params.id, true),
          HealthFoldersApi.getAll(false),
        ]).catch((error) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          let check = data[1];

          nextState.internalSpecialities = _.sortBy(data[0], 'name');
          nextState.check = check;
          nextState.addVideo = check.videoId !== null;
          nextState.bookingType = check.bookingType;
          nextState.healthFolders = _.sortBy(data[2], 'title').map((hf) => ({
            label: `${hf.title}${hf.published ? '' : ' (non publié)'}`,
            value: hf.id,
          }));
        }

        this.setState(nextState);
      },
    );
  };

  selectSex = (sex) => {
    this.setState((prevState) => ({
      check: {...prevState.check, specificSex: sex},
    }));
  };

  selectBookingType = (type) => {
    this.setState({
      bookingType: type,
    });
  };

  onSliderChanged = (value) => {
    this.setState((prevState) => ({
      check: {...prevState.check, minAge: value[0], maxAge: value[1]},
    }));
  };

  onAddVideoCheckboxChanged = () => {
    this.setState((prevState) => ({
      addVideo: !prevState.addVideo,
    }));
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    formData.specificSex = this.state.check.specificSex;
    formData.minAge = this.state.check.minAge;
    formData.maxAge = this.state.check.maxAge;
    formData.bookingType = this.state.bookingType;

    let error = null;

    await PreventionChecksApi.edit(this.props.match.params.id, formData).catch(
      (e) => {
        ApiErrorsHelper.manage(e, this.form);
        error = e;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Contrôle modifié avec succès', null);
      this.props.history.push(`/admin/prevention/checks`);
    }
  };

  render() {
    const {
      check,
      isLoading,
      loadingError,
      addVideo,
      bookingType,
      internalSpecialities,
      healthFolders,
    } = this.state;

    return (
      <Block
        irreducible
        title={
          <span>
            Modifier un <strong>contrôle de prévention</strong>
          </span>
        }>
        <PageDataLoader isLoading={isLoading} loadingError={loadingError}>
          {check && (
            <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Titre</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="title"
                    required
                    value={check.title}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Description courte
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="shortDescription"
                    value={check.shortDescription}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Pourquoi se faire dépister ?
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="whyTrack"
                    value={check.whyTrack}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Qui est concerné ?
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="whoConcerned"
                    value={check.whoConcerned}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Quelles modalités de dépistage
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="modalitiesTrack"
                    value={check.modalitiesTrack}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  A qui s'adresser ?
                </Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="whoContact"
                    value={check.whoContact}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">En savoir +</Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="moreInfos"
                    value={check.moreInfos}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-2">Ajouter une vidéo</Label>
                <div className="col-sm-10">
                  <Checkbox
                    id="addVideoInfos"
                    name="addVideoInfos"
                    checked={addVideo}
                    onChange={this.onAddVideoCheckboxChanged}
                    label=""
                  />
                </div>
              </FormGroup>

              {addVideo && (
                <div>
                  <FormGroup className="row">
                    <Label className="col-form-label col-sm-2">
                      Vidéo "En savoir +" (ID Youtube)
                    </Label>
                    <YoutubeInput
                      className="form-control"
                      name="videoId"
                      value={check.videoId}
                      required
                    />
                  </FormGroup>

                  <FormGroup className="row required">
                    <Label className="col-form-label col-sm-2">
                      Titre vidéo
                    </Label>
                    <div className="col-sm-10">
                      <Input
                        className="form-control"
                        name="videoTitle"
                        value={check.videoTitle}
                        required
                      />
                    </div>
                  </FormGroup>

                  <FormGroup
                    className={classnames('row', {
                      required: addVideo && !check.videoId,
                    })}>
                    <Label className="col-form-label col-sm-2">
                      Image vidéo
                    </Label>
                    <div className="col-sm-10">
                      <ImageUploader
                        name="videoPicture"
                        helpText="Taille attendue : 1920px par 1080px"
                        actualUrl={check.videoPictureUrl}
                        required={addVideo && !check.videoId}
                      />
                    </div>
                  </FormGroup>
                </div>
              )}

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Sexe</Label>
                <div className="col-sm-10">
                  <div className="d-flex flex-row justify-content-around">
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={!check.specificSex ? 'primary' : 'light'}
                      onClick={() => this.selectSex(null)}>
                      Tous
                    </Button>
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={check.specificSex === 'M' ? 'primary' : 'light'}
                      onClick={() => this.selectSex('M')}>
                      Homme
                    </Button>
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={check.specificSex === 'F' ? 'primary' : 'light'}
                      onClick={() => this.selectSex('F')}>
                      Femme
                    </Button>
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Âge</Label>
                <div className="col-sm-10">
                  <TooltipSlider
                    min={0}
                    max={120}
                    defaultValue={[check.minAge, check.maxAge]}
                    allowCross={false}
                    marks={{0: '0', 30: '30', 60: '60', 90: '90', 120: '120'}}
                    onAfterChange={this.onSliderChanged}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-2" for="needGeneralPractitioner">
                  Popup parcours de soin
                </Label>
                <div className="col-sm-10">
                  <Checkbox
                    id="needGeneralPractitioner"
                    name="needGeneralPractitioner"
                    checked={check.needGeneralPractitioner}
                    label=""
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Annuaire</Label>
                <div className="col-sm-10">
                  <div className="d-flex flex-row justify-content-around">
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={bookingType === 'none' ? 'primary' : 'light'}
                      onClick={() => this.selectBookingType('none')}>
                      Aucun
                    </Button>
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={bookingType === 'internal' ? 'primary' : 'light'}
                      onClick={() => this.selectBookingType('internal')}>
                      Interne
                    </Button>
                    <Button
                      size="sm"
                      block
                      className="mx-1"
                      color={bookingType === 'external' ? 'primary' : 'light'}
                      onClick={() => this.selectBookingType('external')}>
                      Externe
                    </Button>
                  </div>
                </div>
              </FormGroup>

              {bookingType === 'external' && (
                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">
                    Lien externe annuaire
                  </Label>
                  <div className="col-sm-10">
                    <Input
                      type="url"
                      className="form-control"
                      name="externalBookingUrl"
                      required
                      value={check.externalBookingUrl}
                    />
                  </div>
                </FormGroup>
              )}

              {bookingType === 'internal' && (
                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">
                    Spécialités associées
                  </Label>
                  <div className="col-sm-10">
                    <Select
                      name="bookingSpecialitiesIds"
                      clearable
                      searchable
                      required
                      multi
                      options={internalSpecialities.map((s) => {
                        return {value: s.id, label: s.name};
                      })}
                      value={check.bookingSpecialities.map((s) => s.id)}
                    />
                  </div>
                </FormGroup>
              )}

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Dossier santé</Label>
                <div className="col-sm-10">
                  <Select
                    id="healthFolderId"
                    name="healthFolderId"
                    placeholder="Choisir un dossier santé"
                    searchable
                    options={healthFolders}
                    value={check.healthFolderId}
                  />
                </div>
              </FormGroup>

              <FormGroup className="text-end">
                <Link
                  to="/admin/prevention/checks"
                  className="btn btn-outline-primary me-1"
                  type="button">
                  Retour à la liste
                </Link>
                <Button type="submit" color="primary">
                  Valider
                </Button>
              </FormGroup>
            </ValidationForm>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
