import { Input as ReactStrapInput, FormText } from "reactstrap";
import { withFormsy } from "formsy-react";
import PropTypes from "prop-types";
import React from "react";

import ValidationErrors from "services/ValidationErrors";

class Input extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([
      "color",
      "date",
      "datetime-local",
      "email",
      "file",
      "hidden",
      "image",
      "month",
      "number",
      "password",
      "range",
      "reset",
      "search",
      "tel",
      "text",
      "time",
      "url",
      "week",
      "textarea",
    ]),
    name: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    helpText: PropTypes.string,
    format: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    type: "text",
    readOnly: false,
  };

  changeValue = (event) => {
    let value = event.currentTarget.value;
    if (this.props.format && typeof this.props.format === "function") {
      value = this.props.format(value);
    }

    this.props.setValue(value);

    if (this.props.onChange) {
      this.props.onChange(this.props.name, value);
    }
  };

  onBlur = () => {
    this.props.setValue(this.props.value);
  };

  render() {
    const {
      isPristine,
      isValid,
      onChange,
      helpText,
      showRequired,
      errorMessage,
      value,
      // Formsy props
      attachToForm,
      detachFromForm,
      isFormDisabled,
      isValidValue,
      runValidation,
      validationError,
      validationErrors,
      errorMessages,
      hasValue,
      isFormSubmitted,
      isRequired,
      resetValue,
      setValidations,
      setValue,
      showError,
      validate,
      //end Formsy props
      ...inputProps
    } = this.props;

    return (
      <div className={isPristine ? "" : isValid ? "valid" : "invalid"}>
        <ReactStrapInput
          value={value || ""}
          onBlur={this.onBlur}
          onChange={this.changeValue}
          valid={!isPristine && isValid}
          invalid={!isPristine && !isValid}
          {...inputProps}
        />
        {helpText && <FormText color="muted">{helpText}</FormText>}
        {!isPristine && showRequired ? (
          <div className="invalid-feedback">{ValidationErrors.required}</div>
        ) : (
          <div className="invalid-feedback">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default withFormsy(Input);
