import AddClinicPage from 'admin/pages/editorial/clinics/addClinicPage/AddClinicPage';
import AddEmergencyPage from 'admin/pages/editorial/emergencies/addEmergencyPage/AddEmergencyPage';
import AddFaqQuestionPage from 'admin/pages/editorial/faq/addFaqQuestionPage/AddFaqQuestionPage';
import AddHealthFoldersPage from 'admin/pages/healthMonitoring/healthFolders/addHealthFolderPage/AddHealthFoldersPage';
import AddHealthFormPage from 'admin/pages/healthMonitoring/healthForms/addHealthFormPage/AddHealthFormPage';
import AddMapPlacePage from 'admin/pages/editorial/maps/addMapPlacePage/AddMapPlacePage';
import AddPreventionCheckPage from 'admin/pages/healthMonitoring/preventionChecks/addPreventionCheck/AddPreventionCheckPage';
import AddSelfAssessmentsPage from 'admin/pages/healthMonitoring/selfAssessmentTests/addSelfAssessmentTestPage/AddSelfAssessmentTestPage';
import AdminsPage from 'admin/pages/editorial/admins/adminsPage/AdminsPage';
import ArticlesPage from 'admin/pages/editorial/articles/articlesPage/ArticlesPage';
import ClinicAdminsPage from 'admin/pages/editorial/clinicAdmins/clinicAdminsPage/ClinicAdminsPage';
import ClinicsPage from 'admin/pages/editorial/clinics/clinicsPage/ClinicsPage';
import EditArticlePage from 'admin/pages/editorial/articles/editArticlePage/EditArticlePage';
import EditClinicPage from 'admin/pages/editorial/clinics/editClinicPage/EditClinicPage';
import EditEmergencyPage from 'admin/pages/editorial/emergencies/editEmergencyPage/EditEmergencyPage';
import EditFaqQuestionPage from 'admin/pages/editorial/faq/editFaqQuestionPage/EditFaqQuestionPage';
import EditHealthFolderArticlePage from 'admin/pages/healthMonitoring/healthFolders/editHealthFolderArticlePage/EditHealthFolderArticlePage';
import EditHealthFoldersPage from 'admin/pages/healthMonitoring/healthFolders/editHealthFoldersPage/EditHealthFoldersPage';
import EditHealthFormPage from 'admin/pages/healthMonitoring/healthForms/editHealthFormPage/EditHealthFormPage';
import EditMapPage from 'admin/pages/editorial/maps/editMapPage/EditMapPage';
import EditMapPlacePage from 'admin/pages/editorial/maps/editMapPlacePage/EditMapPlacePage';
import EditPreventionCategoriesPage from 'admin/pages/healthMonitoring/preventionCategories/editPreventionCategoryPage/EditPreventionCategoriesPage';
import EditPreventionCheckPage from 'admin/pages/healthMonitoring/preventionChecks/editPreventionCheck/EditPreventionCheck';
import EditSelfAssessmentsPage from 'admin/pages/healthMonitoring/selfAssessmentTests/editSelfAssessmentTestPage/EditSelfAssessmentTestPage';
import EmergenciesPage from 'admin/pages/editorial/emergencies/emergenciesPage/EmergenciesPage';
import FaqPage from 'admin/pages/editorial/faq/faqPage/FaqPage';
import HealthFoldersPage from 'admin/pages/healthMonitoring/healthFolders/healthFoldersPage/HealthFoldersPage';
import HealthFormsPage from 'admin/pages/healthMonitoring/healthForms/healthFormsPage/HealthFormsPage';
import InternalSpecialitiesPage from 'admin/pages/booking/internalSpecialities/InternalSpecialitiesPage';
import Login from 'admin/pages/login/Login';
import MapsPage from 'admin/pages/editorial/maps/mapsPage/MapsPage';
import PreventionCategoriesPage from 'admin/pages/healthMonitoring/preventionCategories/preventionCategoriesPage/PreventionCategoriesPage';
import PreventionChecksPage from 'admin/pages/healthMonitoring/preventionChecks/PreventionChecksPage/PreventionChecksPage';
import QoDDashboardPage from 'admin/pages/qod/dashboardPage/DashboardPage';
import QoDErrorsDescriptionsPage from 'admin/pages/qod/errorsDescriptionsPage/ErrorsDescriptionsPage';
import QoDSearchPage from 'admin/pages/qod/searchPage/SearchPage';
import SelfAssessmentsPage from 'admin/pages/healthMonitoring/selfAssessmentTests/selfAssessmentTestsPage/SelfAssessmentsPage';
import CreateClinicAdminPage from 'admin/pages/editorial/clinicAdmins/createClinicAdminPage/CreateClinicAdminPage';
import EditClinicAdminPage from 'admin/pages/editorial/clinicAdmins/EditClinicAdminPage/EditClinicAdminPage';
import PagesPage from 'admin/pages/editorial/pages/pagesPage/PagesPage';
import EditPagePage from 'admin/pages/editorial/pages/editPagePage/EditPagePage';
import NewsPage from 'admin/pages/editorial/news/newsPage/NewsPage';
import EditNewsPage from 'admin/pages/editorial/news/editNewsPage/EditNewsPage';
import ReleaseNotesPage from 'admin/pages/releaseNotes/ReleaseNotesPage';
import EditReleaseNotePage from 'admin/pages/releaseNotes/editReleaseNote/EditReleaseNotePage';

export default class AdminRoutes {
  static public = [
    {
      key: 'adminLogin',
      path: '/login',
      exact: true,
      component: Login,
    },
  ];

  static private = [
    {
      key: 'adminHome',
      path: '/',
      exact: true,
      component: ClinicsPage,
    },

    // Release notes
    {
      key: 'releaseNotes',
      path: '/releaseNotes',
      exact: true,
      component: ReleaseNotesPage,
      authorizedIf: (user) => user.isSuperAdmin,
    },
    {
      key: 'releaseNotes',
      path: '/releaseNotes/:id',
      exact: true,
      component: EditReleaseNotePage,
      authorizedIf: (user) => user.isSuperAdmin,
    },
    {
      key: 'adminClinics',
      path: '/clinics',
      exact: true,
      component: ClinicsPage,
    },
    {
      key: 'adminAddClinic',
      path: '/clinics/add',
      exact: true,
      component: AddClinicPage,
    },
    {
      key: 'adminEditClinic',
      path: '/clinics/:id',
      exact: true,
      component: EditClinicPage,
    },
    {
      key: 'adminAdmins',
      path: '/admins',
      exact: true,
      component: AdminsPage,
    },

    {
      key: 'clinicAdmins',
      path: '/clinicAdmins',
      exact: true,
      component: ClinicAdminsPage,
    },
    {
      key: 'addClinicAdmins',
      path: '/clinicAdmins/add',
      exact: true,
      component: CreateClinicAdminPage,
    },
    {
      key: 'editClinicAdmins',
      path: '/clinicAdmins/:id',
      exact: true,
      component: EditClinicAdminPage,
    },

    // Emergencies
    {
      key: 'adminEmergencies',
      path: '/emergencies',
      exact: true,
      component: EmergenciesPage,
    },

    {
      key: 'adminAddEmergency',
      path: '/emergencies/add',
      exact: true,
      component: AddEmergencyPage,
    },
    {
      key: 'adminEditEmergencies',
      path: '/emergencies/:id',
      exact: true,
      component: EditEmergencyPage,
    },

    // Articles
    {
      key: 'adminArticles',
      path: '/articles',
      exact: true,
      component: ArticlesPage,
    },
    {
      key: 'adminEditArticle',
      path: '/articles/:id',
      exact: true,
      component: EditArticlePage,
    },

    // Maps
    {
      key: 'adminMaps',
      path: '/maps',
      exact: true,
      component: MapsPage,
    },
    {
      key: 'adminEditMaps',
      path: '/maps/:id',
      exact: true,
      component: EditMapPage,
    },

    // Map places
    {
      key: 'adminMapsAddPlace',
      path: '/maps/:mapId/place',
      exact: true,
      component: AddMapPlacePage,
    },
    {
      key: 'adminMapsEditPlace',
      path: '/maps/:mapId/places/:id',
      exact: true,
      component: EditMapPlacePage,
    },

    // News
    {
      key: 'news',
      path: '/news',
      exact: true,
      component: NewsPage,
    },
    {
      key: 'news',
      path: '/news/:id',
      exact: true,
      component: EditNewsPage,
    },

    // FAQ
    {
      key: 'faq',
      path: '/faq',
      exact: true,
      component: FaqPage,
    },
    {
      key: 'faqAdd',
      path: '/faq/questions/add',
      exact: true,
      component: AddFaqQuestionPage,
    },
    {
      key: 'editFaq',
      path: '/faq/questions/:id',
      exact: true,
      component: EditFaqQuestionPage,
    },

    // Pages
    {
      key: 'pages',
      path: '/pages',
      exact: true,
      component: PagesPage,
    },
    {
      key: 'editPage',
      path: '/pages/:id',
      exact: true,
      component: EditPagePage,
    },

    // QoD
    {
      key: 'qodDashboard',
      path: '/qod/',
      exact: true,
      component: QoDDashboardPage,
    },
    {
      key: 'qodSearch',
      path: '/qod/search',
      exact: true,
      component: QoDSearchPage,
    },
    {
      key: 'qodErrors',
      path: '/qod/errors',
      exact: true,
      component: QoDErrorsDescriptionsPage,
      authorizedIf: (user) => user.isSuperAdmin,
    },

    // Health Folders
    {
      key: 'healthFolders_folders',
      path: '/healthFolders/folders',
      exact: true,
      component: HealthFoldersPage,
    },
    {
      key: 'healthFoldersAddFolder',
      path: '/healthFolders/folders/add',
      exact: true,
      component: AddHealthFoldersPage,
    },
    {
      key: 'healthFoldersEditFolder',
      path: '/healthFolders/folders/:id',
      exact: true,
      component: EditHealthFoldersPage,
    },
    {
      key: 'healthFoldersEditArticle',
      path: '/healthFolders/articles/:id',
      exact: true,
      component: EditHealthFolderArticlePage,
    },
    {
      key: 'healthFolders_forms',
      path: '/healthFolders/forms',
      exact: true,
      component: HealthFormsPage,
    },
    {
      key: 'healthFolders_forms_add',
      path: '/healthFolders/forms/add',
      exact: true,
      component: AddHealthFormPage,
    },
    {
      key: 'healthFolders_forms_edit',
      path: '/healthFolders/forms/:id',
      exact: true,
      component: EditHealthFormPage,
    },
    {
      key: 'prevention_checks',
      path: '/prevention/checks',
      exact: true,
      component: PreventionChecksPage,
    },
    {
      key: 'prevention_checks_add',
      path: '/prevention/checks/add',
      exact: true,
      component: AddPreventionCheckPage,
    },
    {
      key: 'prevention_checks_edit',
      path: '/prevention/checks/:id',
      exact: true,
      component: EditPreventionCheckPage,
    },
    {
      key: 'prevention_categories',
      path: '/prevention/categories',
      exact: true,
      component: PreventionCategoriesPage,
    },
    {
      key: 'prevention_categories_edit',
      path: '/prevention/categories/:id',
      exact: true,
      component: EditPreventionCategoriesPage,
    },

    // Booking
    {
      key: 'internalBookingSpecialities',
      path: '/booking/internalSpecialities',
      exact: true,
      component: InternalSpecialitiesPage,
    },

    // Self Assessment Tests
    {
      key: 'selfAssessmentTests',
      path: '/selfAssessmentTests',
      exact: true,
      component: SelfAssessmentsPage,
    },
    {
      key: 'addSelfAssessmentTests',
      path: '/selfAssessmentTests/add',
      exact: true,
      component: AddSelfAssessmentsPage,
    },
    {
      key: 'EditSelfAssessmentTests',
      path: '/selfAssessmentTests/:id',
      exact: true,
      component: EditSelfAssessmentsPage,
    },
  ];
}
