import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import CreateMapButton from './createMapButton/CreateMapButton';

import MapsApi from 'admin/apis/maps.api';

export default class MapsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    maps: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des cartes interactives',
      activeMenuKey: 'maps',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, maps: null},
      async () => {
        let nextState = {isLoading: false};
        let maps = await MapsApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (maps) {
          nextState.maps = _.orderBy(maps, 'title');
        }

        this.setState(nextState);
      },
    );
  };

  onCreateMap = async (formData) => {
    let error = null;
    let createdMap = await MapsApi.add(formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('La carte interactive a été créée', null);
    this.props.history.push(`maps/${createdMap.id}`);
    return Promise.resolve();
  };

  deleteMap = async (map) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await MapsApi.delete(map.id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    if (success) {
      NotificationManager.success('La carte a été supprimée', null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>cartes interactives</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.maps) return null;

    return (
      <div>
        <div className="text-end mb-2">
          <CreateMapButton onSubmit={this.onCreateMap} />
        </div>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th>Code interne</th>
              <th>Marqueur</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.maps.map((map) => (
              <tr key={map.id}>
                <td>{map.title}</td>
                <td>{map.internalCode}</td>
                <td>
                  <img
                    src={map.placeImageUrl}
                    className="thumbnail-s"
                    alt="thumbnail"
                  />
                </td>
                <td className="width-auto">
                  <span className="mx-2">
                    <EditButtonLink to={`maps/${map.id}`} />
                  </span>
                  <DeleteButton
                    data={map}
                    title="Supprimer une carte"
                    onConfirm={this.deleteMap}>
                    Voulez-vous réellement supprimer la carte .
                    <i>"{map.title}"</i> ?
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}
