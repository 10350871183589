import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, FormGroup} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import AddCategoryButton from './addCategoryButton/AddCategoryButton';

import PreventionCategoriesApi from 'admin/apis/preventionCategories.api';
import MapsApi from 'admin/apis/maps.api';

export default class PreventionCategoriesPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    categories: null,
    maps: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des catégories de prévention',
      activeMenuKey: 'prevention_categories',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, categories: null},
      async () => {
        let nextState = {isLoading: false};

        let data = await Promise.all([
          PreventionCategoriesApi.getAll(),
          MapsApi.getAll(),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.categories = _.sortBy(data[0], 'title');
          nextState.maps = data[1];
        }

        this.setState(nextState);
      },
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await PreventionCategoriesApi.create(formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      NotificationManager.success('La catégorie a été créée', null);
      this.loadData();
      return Promise.resolve();
    }
  };

  onDelete = async (categoryId) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await PreventionCategoriesApi.delete(categoryId).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('La catégorie a été supprimée', null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>catégories de prévention</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.categories) return null;

    return (
      <div>
        <FormGroup className="text-end">
          <AddCategoryButton onSubmit={this.onCreate} maps={this.state.maps} />
        </FormGroup>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.categories.map((category) => (
              <tr key={`${category.id}`}>
                <td>{category.title}</td>
                <td className="text-center width-auto">
                  <EditButtonLink
                    to={`/admin/prevention/categories/${category.id}`}
                    tooltip={`Modifier ${category.title}`}
                    className="me-1"
                  />
                  <DeleteButton
                    data={category.id}
                    onConfirm={this.onDelete}
                    tooltip={`Supprimer ${category.title}`}>
                    Voulez-vous réellement supprimer la catégorie de prévention
                    <i>{category.title}</i> ?
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}
