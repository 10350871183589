import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import OrderingHelper from 'helpers/OrderingHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';
import {Link} from 'react-router-dom';

import {Badge, Button, FormGroup} from 'reactstrap';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import AddArticleButton from './addArticleButton/AddArticleButton';
import ArticlePreviewModal from './articlePreviewModal/ArticlePreviewModal';

import ArticlesApi from 'clinic/apis/articles.api';
import PreOpInstructionsApi from 'clinic/apis/preOpInstructions.api';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class ArticlesTab extends React.PureComponent {
  static propTypes = {
    preOpInstruction: PropTypes.number.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    articles: [],
    selectedArticles: [],
    previewModalOpened: false,
    previewArticle: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({isLoading: true, loadingError: false}, async () => {
      let nextState = {isLoading: false};

      let data = await Promise.all([
        ArticlesApi.getAllSummaries(
          this.props.preOpInstruction.healthFacilityId,
        ),
        PreOpInstructionsApi.getArticlesIds(this.props.preOpInstruction.id),
      ]).catch((error) => {
        nextState.loadingError = true;
        return null;
      });

      if (data) {
        nextState.articles = _.sortBy(data[0], 'title');
        nextState.selectedArticles = _.sortBy(data[1], 'order');
      }
      this.setState(nextState);
    });
  };

  preview = async (id) => {
    this.props.layoutContextProvider.showLoader();
    let article = await ArticlesApi.get(id).catch((e) => {
      NotificationManager.error('Une erreur est survenue', null);
      return null;
    });

    this.props.layoutContextProvider.hideLoader();
    if (article) {
      this.setState({
        previewModalOpened: true,
        previewArticle: article,
      });
    }
  };

  onModalToggle = () => {
    this.setState({
      previewModalOpened: false,
      previewArticle: null,
    });
  };

  addArticle = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    let error = null;

    await PreOpInstructionsApi.addArticle(
      this.props.preOpInstruction.id,
      formData,
    ).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Article ajouté avec succès', null);
      this.loadData();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };

  removeArticle = async (articleId) => {
    this.props.layoutContextProvider.showLoader();
    PreOpInstructionsApi.removeArticle(
      this.props.preOpInstruction.id,
      articleId,
    )
      .then(() => {
        this.props.layoutContextProvider.hideLoader();
        NotificationManager.success('Article retiré avec succès', null);
        this.loadData();
      })
      .catch((e) => {
        this.props.layoutContextProvider.hideLoader();
        ApiErrorsHelper.manage(e);
      });
  };

  onDrop = (result) => {
    if (!result.destination || result.source.index === result.destination.index)
      return;

    this.props.layoutContextProvider.showLoader();
    let articlesInPreviousOrder = this.state.selectedArticles;
    let reorderedArticles = OrderingHelper.reorder(
      this.state.selectedArticles,
      result.source.index,
      result.destination.index,
    );

    for (var i = 0; i < reorderedArticles.length; i++) {
      reorderedArticles[i].order = i + 1;
    }

    this.setState({selectedArticles: reorderedArticles}, async () => {
      let movedArticle = articlesInPreviousOrder[result.source.index];
      let error = null;
      await PreOpInstructionsApi.changeArticleOrder(
        this.props.preOpInstruction.id,
        movedArticle.articleId,
        result.destination.index,
      ).catch((e) => {
        error = e;
      });

      if (error) {
        NotificationManager.error(error.message, 'Une erreur est survenue');
        this.setState({selectedArticles: articlesInPreviousOrder});
      } else {
        NotificationManager.success("L'article a été déplacé", null);
      }

      this.props.layoutContextProvider.hideLoader();
    });
  };

  render() {
    let availableArticles = this.state.articles.filter(
      (a) => !this.state.selectedArticles.find((ar) => ar.articleId === a.id),
    );

    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        loadingText="Chargement des articles">
        <div className="text-end mb-2">
          <AddArticleButton
            articles={availableArticles}
            onSubmit={this.addArticle}
          />
        </div>
        <div className="mb-3">
          <DragDropContext onDragEnd={this.onDrop}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className={`droppable ${
                    snapshot.isDraggingOver ? 'dragging' : 'notDragging'
                  }`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {this.state.selectedArticles.map((articleLinked, i) => {
                    const article = this.state.articles.find(
                      (a) => a.id === articleLinked.articleId,
                    );

                    if (!article) {
                      return null;
                    }

                    return (
                      <Draggable
                        key={`article-${articleLinked.order}`}
                        draggableId={`${articleLinked.order}`}
                        index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <div className="d-flex flex-row align-items-center justify-content-between draggableRow">
                              <div>
                                <FontAwesomeIcon icon="arrows-alt" />
                                <span className="ms-4">{article.title}</span>
                              </div>
                              <div>
                                <span className="me-2">
                                  <Badge
                                    color={
                                      article.published
                                        ? 'success'
                                        : 'secondary'
                                    }>
                                    {article.published
                                      ? 'Publié'
                                      : 'Non publié'}
                                  </Badge>
                                </span>
                                {article.type === 'video' && (
                                  <a
                                    href={`https://www.youtube.com/watch?v=${article.videoId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-primary btn-sm">
                                    <FontAwesomeIcon icon="play" />
                                  </a>
                                )}

                                {article.type === 'text' && (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => this.preview(article.id)}>
                                    <FontAwesomeIcon icon="search" />
                                  </Button>
                                )}

                                <DeleteButton
                                  title={
                                    <span>
                                      Retirer <i>{article.title}</i>
                                    </span>
                                  }
                                  data={article.id}
                                  onConfirm={this.removeArticle}
                                  className="ms-1"
                                  tooltip={`Retirer ${article.title}`}>
                                  <div>
                                    Voulez-vous réellement retirer l'article{' '}
                                    <i>{article.title}</i> de cette consigne
                                    preop ?
                                  </div>
                                </DeleteButton>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <ArticlePreviewModal
          article={this.state.previewArticle}
          isOpen={this.state.previewModalOpened}
          onToggle={this.onModalToggle}
        />
        <FormGroup className="text-end">
          <Link
            to="/clinic/preOpInstructions"
            className="btn btn-outline-primary"
            type="button">
            Retour à la liste
          </Link>
        </FormGroup>
      </PageDataLoader>
    );
  }
}

export default withLayoutContextProvider(ArticlesTab);
