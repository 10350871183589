import ApiClient from 'admin/services/ApiClient';
import queryString from 'query-string';

export default class ClinicAdminsApi {
  static getAll = (clinicId = null) => {
    let params = {};
    if (clinicId) {
      params.clinicId = clinicId;
    }
    return ApiClient.get(`clinicAdmins?${queryString.stringify(params)}`);
  };

  static getById = (id) => ApiClient.get(`clinicAdmins/${id}`);

  static create = (data) => ApiClient.post('clinicAdmins', data);

  static delete = (id) => ApiClient.delete(`clinicAdmins/${id}`);

  static update = (id, data) => ApiClient.patch(`clinicAdmins/${id}`, data);
}
