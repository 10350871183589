import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import validationErrors from 'services/ValidationErrors';

import {Link} from 'react-router-dom';
import {FormGroup, Label, Button} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import FaqQuestionsApi from 'admin/apis/faqQuestions.api';

export default class AddFaqQuestionPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion de la FAQ',
      activeMenuKey: 'faq',
    });
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    const question = await FaqQuestionsApi.create(formData).catch((error) => {
      ApiErrorsHelper.manage(error, this.form);
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (question) {
      NotificationManager.success('La question a été créée', null);
      this.props.history.push(`/admin/faq/questions/${question.id}`);
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Création d'une <strong>question</strong>
          </span>
        }>
        <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Question</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="question"
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Réponse</Label>
            <div className="col-sm-10">
              <Input
                type="textarea"
                className="form-control"
                name="answer"
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Lien externe</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="externalLink"
                validations="maxLength:255"
                validationError={validationErrors.maxLength(255)}
              />
            </div>
          </FormGroup>

          <FormGroup className="text-end">
            <Link
              to="/admin/faq"
              className="btn btn-outline-primary me-1"
              type="button">
              Retour à la liste
            </Link>
            <Button type="submit" color="primary">
              Valider
            </Button>
          </FormGroup>
        </ValidationForm>
      </Block>
    );
  }
}
