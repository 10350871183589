import Input from 'sharedComponents/input/Input';
import React from 'react';
import YoutubeButton from 'sharedComponents/youtubeButton/YoutubeButton';

export default class YoutubeInput extends React.PureComponent {
  state = {
    videoId: this.props.value,
  };

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({videoId: this.props.value});
    }
  }

  onChange = (name, value) => {
    this.setState({videoId: value});
    this.props.onChange && this.props.onChange(name, value);
  };

  render() {
    return (
      <div className="d-flex flex-row align-items-center">
        <Input {...this.props} type="text" onChange={this.onChange} />
        <div className="ms-2">
          <YoutubeButton videoId={this.state.videoId} />
        </div>
      </div>
    );
  }
}
