import React from 'react';
import {PropTypes} from 'prop-types';

import queryString from 'query-string';

import {Row, Col, Badge} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import YoutubeButton from 'sharedComponents/youtubeButton/YoutubeButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PreviewTextArticleButton from './previewTextArticleButton/PreviewTextArticleButton';

export default class ArticleItem extends React.PureComponent {
  static propTypes = {
    healthFolderId: PropTypes.number.isRequired,
    article: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['text', 'video', 'external']).isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  render() {
    const {article, healthFolderId, onDelete} = this.props;

    const editUrlParams = {
      redirectTo: `/admin/healthFolders/folders/${healthFolderId}#informations`,
    };

    return (
      <Row className="align-items-center">
        <Col xs="auto">
          <FontAwesomeIcon icon="arrows-alt" />
        </Col>
        <Col>
          <strong>{article.title}</strong>
        </Col>

        <Col
          xs="auto"
          className="text-center"
          style={{width: '100px', textAlign: 'center'}}>
          <Badge color={article.published ? 'success' : 'secondary'}>
            {article.published ? 'Publié' : 'Non publié'}
          </Badge>
        </Col>

        <Col xs="auto" style={{width: '145px', textAlign: 'right'}}>
          <span className="me-1">
            {article.type === 'text' && (
              <PreviewTextArticleButton article={article} />
            )}
            {article.type === 'video' && (
              <YoutubeButton title="Visualiser" videoId={article.youtubeId} />
            )}
            {article.type === 'external' && (
              <a
                href={article.externalUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-sm">
                <FontAwesomeIcon icon="external-link-alt" />
              </a>
            )}
          </span>
          <EditButtonLink
            to={`/admin/healthFolders/articles/${
              article.id
            }?${queryString.stringify(editUrlParams)}`}
            tooltip={`Modifier ${article.title}`}
            className="mx-1"
          />
          <DeleteButton
            title={
              <span>
                Supprimer <i>{article.title}</i>
              </span>
            }
            data={article.id}
            onConfirm={onDelete}
            className="ms-1"
            tooltip={`Supprimer ${article.title}`}>
            Voulez-vous réellement supprimer l'article <i>{article.title}</i> ?
          </DeleteButton>
        </Col>
      </Row>
    );
  }
}
