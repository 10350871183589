import React from 'react';
import {PropTypes} from 'prop-types';

export default class ParamsDetails extends React.PureComponent {
  static propTypes = {
    ruleAssociation: PropTypes.object.isRequired,
    ruleModel: PropTypes.object.isRequired,
  };

  render() {
    const {ruleAssociation, ruleModel} = this.props;
    let params = [];

    for (const property in ruleAssociation.params) {
      let paramDetails = ruleModel.params.find((p) => p.code === property);

      params.push(
        `${paramDetails.title} : ${ruleAssociation.params[property]}`,
      );
    }

    if (!ruleAssociation.params || ruleAssociation.params.length === 0) {
      return null;
    }

    return (
      <ul>
        {params.map((param, index) => {
          return <li key={index}>{param}</li>;
        })}
      </ul>
    );
  }
}
