import React from 'react';
import {PropTypes} from 'prop-types';

import {Label, FormGroup} from 'reactstrap';
import Select from 'sharedComponents/select/Select';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';

export default class AddArticleButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    articles: PropTypes.array.isRequired,
  };

  state = {
    modalVisible: false,
    articles: [],
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return {modalVisible: !prevState.modalVisible};
    });

  onSubmit = (formData) => {
    return this.props.onSubmit(formData);
  };

  render() {
    let articlesOptions = this.props.articles.map((article) => ({
      label: article.title,
      value: article.id,
    }));

    return (
      <CreateButtonModal
        text="Ajouter un article"
        icon="plus-circle"
        onSubmit={this.onSubmit}>
        <FormGroup>
          <Label>Article</Label>
          <Select
            id="articleId"
            name="articleId"
            placeholder="Choisir un article"
            searchable
            options={articlesOptions}
            value=""
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Présent sur l'écran récap</Label>
          <Checkbox name="summaryVisible" label="Oui" id="summaryVisible" />
        </FormGroup>
        <FormGroup>
          <Label>Afficher en preop</Label>
          <Checkbox name="preopVisible" label="Oui" id="preopVisible" />
        </FormGroup>
        <FormGroup>
          <Label>Afficher en postop</Label>
          <Checkbox name="postopVisible" label="Oui" id="postopVisible" />
        </FormGroup>
      </CreateButtonModal>
    );
  }
}
