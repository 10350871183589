import ApiClient from "admin/services/ApiClient";

export default class EmergenciesApi {
  static getAll() {
    return ApiClient.get("emergencies");
  }

  static get(id) {
    return ApiClient.get(`emergencies/${id}`);
  }

  static add(data) {
    return ApiClient.post("emergencies", data);
  }

  static edit(id, data) {
    return ApiClient.patch(`emergencies/${id}`, data);
  }

  static delete(id) {
    return ApiClient.delete(`emergencies/${id}`);
  }
}
