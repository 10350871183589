import Authentication from "clinic/services/Authentication";
import StringHelper from "helpers/StringHelper";
import React from "react";

export default class HomePage extends React.PureComponent {
  static propTypes = {};

  state = {};

  componentDidMount() {
    const { user } = Authentication.loadFromStorage();

    let home = "";
    if (user.isEditorialAdmin) {
      home = "/clinic/healthFacility";
    } else if (user.isQoDAdmin) {
      home = "/clinic/qod/";
    } else if (user.isUsersAdmin) {
      home = "/clinic/admin/users/";
    } else if (user.isRoomReservationsAdmin) {
      home = "/clinic/roomReservations";
    }

    if (StringHelper.isNullOrEmpty(home)) {
      Authentication.logout();
    } else {
      this.props.history.replace(home);
    }
  }

  render() {
    return <div>HOME</div>;
  }
}
