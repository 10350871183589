import React from "react";
import { PropTypes } from "prop-types";

import { Droppable, Draggable } from "react-beautiful-dnd";
import ArticleItem from "./articleItem/ArticleItem";

export default class ArticlesList extends React.PureComponent {
  static propTypes = {
    healthFolderId: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired,
    articles: PropTypes.arrayOf(ArticleItem.propTypes.article).isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  render() {
    return !this.props.articles || this.props.articles.length === 0 ? (
      <i>Cette catégorie ne contient aucun article</i>
    ) : (
      <div>
        <Droppable
          droppableId={`CATARTICLES-${this.props.categoryId}`}
          type={`ARTICLE-${this.props.categoryId}`}
        >
          {(provided, snapshot) => (
            <div
              className={`droppable ${
                snapshot.isDraggingOver ? "dragging" : "notDragging"
              }`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {this.props.articles.map((article, index) => (
                <Draggable
                  key={`article-${this.props.categoryId}-${article.order}`}
                  draggableId={`article-${this.props.categoryId}-${article.order}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`item ${
                        snapshot.isDragging ? "dragging" : "notDragging"
                      }`}
                    >
                      <ArticleItem
                        article={article}
                        healthFolderId={this.props.healthFolderId}
                        onDelete={this.props.onDelete}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}
