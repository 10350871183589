import React from "react";
import { PropTypes } from "prop-types";

import { Label, FormGroup, Button } from "reactstrap";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import Input from "sharedComponents/input/Input";
import Select from "sharedComponents/select/Select";
import YoutubeInput from "sharedComponents/youtubeInput/YoutubeInput";

export default class AddArticleButton extends React.PureComponent {
  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    type: "text",
  };

  selectTextArticle = () => this.setState({ type: "text" });

  selectVideoArticle = () => this.setState({ type: "video" });

  selectExternalArticle = () => this.setState({ type: "external" });

  onSubmit = (formData) => {
    let data = { ...formData, type: this.state.type };

    if (this.state.type !== "video") {
      delete data.videoId;
    }

    return this.props.onSubmit(data);
  };

  render() {
    let categoriesOptions = this.props.categories.map((c) => ({
      label: c.title,
      value: c.id,
    }));

    return (
      <CreateButtonModal
        text="Nouvel article"
        icon="plus-circle"
        onSubmit={this.onSubmit}
      >
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input type="text" name="title" required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Catégorie</Label>
          <Select
            id="healthFolderCategoryId"
            name="healthFolderCategoryId"
            placeholder="Choisir une catégorie"
            searchable
            options={categoriesOptions}
            value=""
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Type</Label>
          <div className="d-flex flex-row justify-content-around">
            <Button
              size="sm"
              block
              className="mx-1"
              color={this.state.type === "text" ? "primary" : "light"}
              onClick={this.selectTextArticle}
            >
              Texte
            </Button>
            <Button
              size="sm"
              block
              className="mx-1"
              color={this.state.type === "video" ? "primary" : "light"}
              onClick={this.selectVideoArticle}
            >
              Vidéo
            </Button>
            <Button
              size="sm"
              block
              className="mx-1"
              color={this.state.type === "external" ? "primary" : "light"}
              onClick={this.selectExternalArticle}
            >
              Externe
            </Button>
          </div>
        </FormGroup>

        <FormGroup className="required">
          <Label>Petite image (aperçu sur la liste des articles)</Label>
          <ImageUploader
            name="smallPictureFile"
            helpText={`Taille attendue : ${
              this.state.type === "video"
                ? "1920px par 1080px"
                : "417px par 417px"
            }`}
            required
          />
        </FormGroup>

        <div className={this.state.type === "text" ? "" : "d-none"}>
          <FormGroup className="required">
            <Label>Grande image (entête de l'article)</Label>
            <ImageUploader
              name="largePictureFile"
              helpText="Taille attendue : 1125px par 788px"
              required={this.state.type === "text"}
            />
          </FormGroup>
        </div>

        <div className={this.state.type === "video" ? "" : "d-none"}>
          <FormGroup className="required">
            <Label>ID YouTube de la vidéo</Label>
            <YoutubeInput
              name="videoId"
              required={this.state.type === "video"}
            />
          </FormGroup>
        </div>

        <div className={this.state.type === "external" ? "" : "d-none"}>
          <FormGroup className="required">
            <Label>URL</Label>
            <Input
              type="text"
              name="externalUrl"
              required={this.state.type === "external"}
            />
          </FormGroup>
        </div>
      </CreateButtonModal>
    );
  }
}
