import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";

export default class ValidationForm extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    validationErrors: PropTypes.object
  };

  static defaultProps = {
    validationErrors: null
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.formsyRef = React.createRef();
  }

  onInvalidSubmit = () => {
    window.requestAnimationFrame(() => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const rect = this.formRef.current
        .querySelector(".invalid")
        .getBoundingClientRect();
      const top = scrollTop + rect.top;

      window.scrollTo(0, top - 70);
    });
  };

  onValidSubmit = data => {
    if (this.props.onSubmit) {
      this.props.onSubmit(data);
    }
  };

  onChange = values => {
    if (this.props.onChange) {
      this.props.onChange(values);
    }
  };

  updateInputsWithError = errors => {
    if (typeof errors !== "string" && typeof errors === "object") {
      try {
        this.formsyRef.current.updateInputsWithError(errors);
      } catch {}
    }
  };

  render() {
    return (
      <div ref={this.formRef}>
        <Formsy
          ref={this.formsyRef}
          onInvalidSubmit={this.onInvalidSubmit}
          onValidSubmit={this.onValidSubmit}
          onChange={this.onChange}
          validationErrors={this.props.validationErrors}
          noValidate
        >
          {this.props.children}
        </Formsy>
      </div>
    );
  }
}
