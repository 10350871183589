import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React from "react";

import classnames from "classnames";
import queryString from "query-string";

import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import Block from "sharedComponents/block/Block";
import ContentTab from "./contentTab/ContentTab";
import GeneralTab from "./generalTab/GeneralTab";

import HealthFoldersArticlesApi from "admin/apis/healthFoldersArticles.api";
import HealthFoldersCategoriesApi from "admin/apis/healthFoldersCategories.api";

export default class EditHealthFolderArticlePage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : "general",
    isLoading: true,
    loadingError: false,
    article: null,
    categories: null,
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.returnUrl = queryString.parse(this.props.location.search).redirectTo;
    this.props.layoutContextProvider.change({
      title: "Gestion des dossiers santé",
      activeMenuKey: "healthFolders_folders",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        articles: null,
        categories: null,
      },
      async () => {
        let nextState = { isLoading: false };

        // Get article
        nextState.article = await HealthFoldersArticlesApi.get(
          this.id,
          true
        ).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        // Get categories
        nextState.categories =
          await HealthFoldersCategoriesApi.getByHealthFolderId(
            nextState.article.category.healthFolderId,
            false
          ).catch((e) => {
            nextState.loadingError = e;
            return null;
          });

        this.setState(nextState);
      }
    );
  };

  goToGeneralTab = () => {
    this.setState({ activeTab: "general" });
    this.props.history.replace("#general");
  };

  goToContentTab = () => {
    this.setState({ activeTab: "content" });
    this.props.history.replace("#content");
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier un <strong>article</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de l'article"
        >
          {this.state.article && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "general",
                    })}
                    onClick={this.goToGeneralTab}
                  >
                    Général
                  </NavLink>
                </NavItem>
                {this.state.article.type === "text" && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "content",
                      })}
                      onClick={this.goToContentTab}
                    >
                      Contenu
                    </NavLink>
                  </NavItem>
                )}
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="general">
                  <GeneralTab
                    article={this.state.article}
                    categories={this.state.categories}
                    returnUrl={this.returnUrl}
                  />
                </TabPane>
                {this.state.article.type === "text" && (
                  <TabPane tabId="content">
                    <ContentTab
                      article={this.state.article}
                      returnUrl={this.returnUrl}
                    />
                  </TabPane>
                )}
              </TabContent>
            </>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
