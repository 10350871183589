import ApiClient from "clinic/services/ApiClient";

export default class OperatingMethodsLinksApi {
  static getAll = () => ApiClient.get("operatingMethodsLinks");

  static create = (data) => ApiClient.post("operatingMethodsLinks", data);

  static delete = (mdtCode, practitionerCode) =>
    ApiClient.delete(`operatingMethodsLinks/${mdtCode}/${practitionerCode}`);

  static edit = (mdtCode, practitionerCode, data) =>
    ApiClient.patch(
      `operatingMethodsLinks/${mdtCode}/${practitionerCode}`,
      data
    );
}
