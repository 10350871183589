import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Link} from 'react-router-dom';
import {Button, FormGroup, Label} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';

import HealthFormsApi from 'admin/apis/healthForms.api';

export default class AddHealthFormPage extends React.PureComponent {
  state = {
    type: 'internal',
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des questionnaires santé',
      activeMenuKey: 'healthFolders_forms',
    });
  }

  changeType = (type) => {
    this.setState({
      type: type,
    });
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await HealthFormsApi.add(formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Questionnaire créé avec succès', null);
      this.props.history.push(`/admin/healthFolders/forms`);
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Création d'un <strong>questionnaire santé</strong>
          </span>
        }>
        <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Titre</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="title" required />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Type</Label>
            <div className="col-sm-10">
              <div className="d-flex flex-row justify-content-around">
                <Button
                  size="sm"
                  block
                  className="mx-1"
                  color={this.state.type === 'internal' ? 'primary' : 'light'}
                  onClick={() => this.changeType('internal')}>
                  Interne
                </Button>
                <Button
                  size="sm"
                  block
                  className="mx-1"
                  color={this.state.type === 'external' ? 'primary' : 'light'}
                  onClick={() => this.changeType('external')}>
                  Externe
                </Button>
              </div>
            </div>
          </FormGroup>

          {this.state.type === 'internal' && (
            <>
              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Code interne</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="internalCode"
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Version minimale de l'app
                </Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="minimalAppVersion"
                    helpText="Format attendu : X.Y.Z"
                    required
                  />
                </div>
              </FormGroup>
            </>
          )}

          {this.state.type === 'external' && (
            <FormGroup className="row required">
              <Label className="col-form-label col-sm-2">URL</Label>
              <div className="col-sm-10">
                <Input className="form-control" name="externalUrl" required />
              </div>
            </FormGroup>
          )}

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Résumé</Label>
            <div className="col-sm-10">
              <Input
                type="textarea"
                className="form-control"
                name="summary"
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">En savoir +</Label>
            <div className="col-sm-10">
              <Input
                type="textarea"
                className="form-control"
                name="description"
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Image</Label>
            <div className="col-sm-10">
              <ImageUploader
                name="picture"
                helpText="Taille attendue : 100px par 100px"
              />
            </div>
          </FormGroup>

          <FormGroup className="text-end">
            <Link
              to="/admin/healthFolders/forms"
              className="btn btn-outline-primary me-1"
              type="button">
              Retour à la liste
            </Link>
            <Button type="submit" color="primary">
              Valider
            </Button>
          </FormGroup>

          <Input type="hidden" name="type" value={this.state.type} />
        </ValidationForm>
      </Block>
    );
  }
}
