import React from "react";
import classnames from "classnames";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Block from "sharedComponents/block/Block";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import GeneralTab from "./generalTab/GeneralTab";
import ContentTab from "./contentTab/ContentTab";

import PagesApi from "admin/apis/pages.api";

export default class EditPagePage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : "general",
    isLoading: true,
    loadingError: false,
    page: null,
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: "Gestion des pages",
      activeMenuKey: "pages",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, page: null },
      async () => {
        let nextState = { isLoading: false };
        nextState.page = await PagesApi.get(this.id).catch((e) => {
          nextState.loadingError = e;
          return null;
        });

        this.setState(nextState);
      }
    );
  };

  goToGeneralTab = () => {
    this.setState({ activeTab: "general" });
    this.props.history.replace("#general");
  };

  goToContentTab = () => {
    this.setState({ activeTab: "content" });
    this.props.history.replace("#content");
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong>page</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de la page"
        >
          {this.state.page && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "general",
                    })}
                    onClick={this.goToGeneralTab}
                  >
                    Général
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "content",
                    })}
                    onClick={this.goToContentTab}
                  >
                    Contenu
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="general">
                  <GeneralTab page={this.state.page} />
                </TabPane>
                <TabPane tabId="content">
                  <ContentTab page={this.state.page} />
                </TabPane>
              </TabContent>
            </>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
