import ApiClient from "admin/services/ApiClient";
import queryString from "query-string";

export default class PreventionChecksApi {
  static getAll = (withBookingSpecialites = false) => {
    let params = {};
    if (withBookingSpecialites) {
      params.withBookingSpecialites = null;
    }

    return ApiClient.get(`preventionChecks?${queryString.stringify(params)}`);
  };

  static get = (id, withBookingSpecialites = false) => {
    let params = {};
    if (withBookingSpecialites) {
      params.withBookingSpecialites = null;
    }

    return ApiClient.get(
      `preventionChecks/${id}?${queryString.stringify(params)}`
    );
  };

  static changeOrder = (id, newOrder) =>
    ApiClient.put(`preventionChecks/${id}/order`, {
      newOrder: newOrder,
    });

  static create = (formData) =>
    ApiClient.post("preventionChecks", formData, true);

  static delete = (id) => ApiClient.delete(`preventionChecks/${id}`);

  static edit = (id, formData) =>
    ApiClient.post(`preventionChecks/${id}`, formData, true);
}
