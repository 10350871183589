import React from 'react';
import PropTypes from 'prop-types';

import {NotificationManager} from 'react-notifications';

import {withLayoutContextProvider} from 'services/LayoutContext';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Link, withRouter} from 'react-router-dom';
import {FormGroup, Button, Label} from 'reactstrap';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import Input from 'sharedComponents/input/Input';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';

import PagesApi from 'admin/apis/pages.api';

class GeneralTab extends React.PureComponent {
  static props = {
    page: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit = async (formData) => {
    if (!formData.smallPictureFile) {
      delete formData.smallPictureFile;
    }

    if (!formData.largePictureFile) {
      delete formData.largePictureFile;
    }

    this.props.layoutContextProvider.showLoader();

    let error = null;

    await PagesApi.editGeneral(this.props.page.id, formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    if (!error) {
      NotificationManager.success('La page a été modifié', null);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Titre</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="title"
              required
              value={this.props.page.title}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-sm-2">Code interne</Label>
          <div className="col-sm-10">{this.props.page.internalCode}</div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">
            Petite image (aperçu sur la liste des pages)
          </Label>
          <div className="col-sm-10">
            <ImageUploader
              name="smallPictureFile"
              helpText="Taille attendue : 417px par 417px"
              actualUrl={this.props.page.smallPictureUrl}
            />
          </div>
        </FormGroup>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">
            Grande image (entête de la page)
          </Label>
          <div className="col-sm-10">
            <ImageUploader
              name="largePictureFile"
              helpText="Taille attendue : 1125px par 788px"
              actualUrl={this.props.page.largePictureUrl}
            />
          </div>
        </FormGroup>

        <FormGroup className="text-end">
          <Link
            to="/admin/pages"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(withRouter(GeneralTab));
