import { PropTypes } from 'prop-types'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class YoutubeButton extends React.PureComponent {
  static propTypes = {
    videoId: PropTypes.string.isRequired
  }

  render () {
    return (
      <a
        href={`https://www.youtube.com/watch?v=${this.props.videoId}`}
        target='_blank'
        rel="noopener noreferrer"
        className='btn btn-primary btn-sm'
      >
        <FontAwesomeIcon icon='play' />
      </a>
    )
  }
}
