import React from 'react';
import {PropTypes} from 'prop-types';

import {NotificationManager} from 'react-notifications';
import OrderingHelper from 'helpers/OrderingHelper';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormGroup, Row, Col} from 'reactstrap';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import AddFormsForm from './AddFormsForm/AddFormsForm';

import HealthFoldersApi from 'admin/apis/healthFolders.api';

export class FormsTab extends React.PureComponent {
  static propTypes = {
    healthFolder: PropTypes.object.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    forms: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, forms: null},
      async () => {
        let nextState = {isLoading: false};
        let forms = await HealthFoldersApi.getForms(
          this.props.healthFolder.id,
        ).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (forms) {
          nextState.forms = forms;
        }

        this.setState(nextState);
      },
    );
  };

  saveAddModal = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await HealthFoldersApi.addForm(this.props.healthFolder.id, formData).catch(
      (e) => {
        error = e;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('Questionnaire ajouté avec succès', null);
    this.loadData();
    return Promise.resolve(true);
  };

  removeForm = async (form) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await HealthFoldersApi.removeForm(
      this.props.healthFolder.id,
      form.id,
    ).catch((e) => {
      ApiErrorsHelper.manage(e);
      error = e;
    });
    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Questionnaire retiré avec succès', null);
      this.loadData();
    }
  };

  onDrop = (result) => {
    if (!result.destination || result.source.index === result.destination.index)
      return;

    let orderedForms = OrderingHelper.reorder(
      this.state.forms,
      result.source.index,
      result.destination.index,
    );

    for (var i = 0; i < orderedForms.length; i++) {
      orderedForms[i].order = i + 1;
    }

    let lastSateOrder = [...this.state.forms];
    const formId = this.state.forms[result.source.index].id;

    this.setState({forms: orderedForms}, async () => {
      this.props.layoutContextProvider.showLoader();
      await HealthFoldersApi.changeFormOrder(
        this.props.healthFolder.id,
        formId,
        result.destination.index + 1,
      ).catch((error) => {
        this.setState({forms: lastSateOrder});
      });
      this.props.layoutContextProvider.hideLoader();
    });
  };

  render() {
    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        loadingText="Chargement du dossier santé">
        {this.state.forms && (
          <>
            <FormGroup className="text-end">
              <CreateButtonModal
                text="Associer un questionnaire"
                onSubmit={this.saveAddModal}>
                <AddFormsForm healthFolderId={this.props.healthFolder.id} />
              </CreateButtonModal>
            </FormGroup>

            <DragDropContext onDragEnd={this.onDrop}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className={`droppable ${
                      snapshot.isDraggingOver ? 'dragging' : 'notDragging'
                    }`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {this.state.forms.map((form, i) => (
                      <Draggable
                        key={`healthFolder-${form.order}`}
                        draggableId={`${form.order}`}
                        index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <div className="item">
                              <Row className="align-items-center">
                                <Col xs="auto">
                                  <FontAwesomeIcon icon="arrows-alt" />
                                </Col>
                                <Col>{form.title}</Col>
                                <Col xs="auto">
                                  {form.type === 'internal'
                                    ? 'Interne'
                                    : 'Externe'}
                                </Col>
                                <Col xs="auto">
                                  <DeleteButton
                                    data={form}
                                    title="Retirer un questionnaire"
                                    onConfirm={this.removeForm}>
                                    Voulez-vous réellement retirer le
                                    questionnaire <i>"{form.title}"</i> de ce
                                    dossier santé ?
                                  </DeleteButton>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </PageDataLoader>
    );
  }
}

export default withLayoutContextProvider(FormsTab);
