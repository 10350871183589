import React from "react";

import classnames from "classnames";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Block from "sharedComponents/block/Block";
import GeneralTab from "./generalTab/GeneralTab";
import PlacesTab from "./placesTab/PlacesTab";

export default class EditMapPage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : "general",
  };

  componentDidMount = () => {
    this.id = +this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: "Gestion des cartes interactives",
      activeMenuKey: "maps",
    });
  };

  goToGeneralTab = () => {
    this.setState({ activeTab: "general" });
    this.props.history.replace("#general");
  };

  goToPlacesTab = () => {
    this.setState({ activeTab: "places" });
    this.props.history.replace("#places");
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong>carte interactive</strong>
          </span>
        }
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "general",
              })}
              onClick={this.goToGeneralTab}
            >
              Général
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "places",
              })}
              onClick={this.goToPlacesTab}
            >
              Marqueurs
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="general">
            <GeneralTab mapId={this.id} />
          </TabPane>
          <TabPane tabId="places">
            <PlacesTab mapId={this.id} />
          </TabPane>
        </TabContent>
      </Block>
    );
  }
}
