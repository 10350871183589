import React from "react";
import { PropTypes } from "prop-types";

import { FormGroup, Label } from "reactstrap";
import Input from "sharedComponents/input/Input";

export default class ErrorDescriptionForm extends React.PureComponent {
  static propTypes = {
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    createMode: PropTypes.bool
  };

  static defaultProps = {
    data: null,
    createMode: false
  };

  render() {
    return (
      <>
        <FormGroup className="required">
          <Label htmlFor="code">Code</Label>
          <Input
            id="code"
            name="code"
            value={this.props.code}
            required
            readOnly={!this.props.createMode}
          />
        </FormGroup>
        <FormGroup className="required">
          <Label htmlFor="description">Description</Label>
          <Input
            id="description"
            name="description"
            value={this.props.description}
            required
          />
        </FormGroup>
      </>
    );
  }
}
