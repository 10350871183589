import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { PropTypes } from "prop-types";
import React from "react";
import StructuredContent from "sharedComponents/structuredContent/StructuredContent";

export default class ArticlePreviewModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    article: PropTypes.object,
    onToggle: PropTypes.func.isRequired,
  };

  render() {
    if (!this.props.article) {
      return null;
    }

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          {this.props.article.title}
        </ModalHeader>
        <ModalBody>
          <StructuredContent
            blocks={this.props.article.structuredContent?.blocks}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={this.props.onToggle} block>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
