import moment from 'moment';
import queryString from 'query-string';

import ApiClient from 'clinic/services/ApiClient';

export default class QoDApi {
  static getLogsFromMedicalStay = (medicalStayGapId) =>
    ApiClient.get(`qod/search?medicalStayGapId=${medicalStayGapId}`);

  static getErrorsDescriptions = () => ApiClient.get(`qod/errorsDescriptions`);

  static dashboard = (startDate, endDate, treatmentModeId = null) => {
    let params = {};

    params.startDate = moment(startDate).format('YYYY-MM-DD');
    params.endDate = moment(endDate).format('YYYY-MM-DD');
    params.treatmentModeId = treatmentModeId;

    return ApiClient.get(`qod/dashboard?${queryString.stringify(params)}`);
  };
}
