import {Button, Label, FormGroup} from 'reactstrap';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import Block from 'sharedComponents/block/Block';
import InlineLoader from 'sharedComponents/inlineLoader/InlineLoader';
import Input from 'sharedComponents/input/Input';
import LoadingError from 'sharedComponents/loadingError/LoadingError';
import React from 'react';
import validationErrors from 'services/ValidationErrors';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ClinicApi from 'clinic/apis/clinic.api';

export default class MyClinicPage extends React.PureComponent {
  static propTypes = {};

  state = {
    isLoading: true,
    loadingError: null,

    id: null,
    shortName: null,
    ambulisId: null,
    longName: null,
    clinicDirectionSpecifier: null,
    addressLine2: null,
    addressLine3: null,
    addressLine4: null,
    addressLine5: null,
    addressLine6: null,
    addressLine7: null,
    latitude: null,
    longitude: null,
    locationMap: null,
    interactiveLocationMap: null,
    website: null,
    qodFlag: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Mon établissement',
      activeMenuKey: 'myClinic',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState({isLoading: true, loadingError: null}, async () => {
      let nextState = {isLoading: false};
      let data = await ClinicApi.getMine().catch((e) => {
        nextState.loadingError = e;
        return null;
      });

      if (data) {
        nextState = {...nextState, ...data};
      }

      this.setState(nextState);
    });
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await ClinicApi.updateMine(formData).catch((e) => {
      success = false;
      if (e.raw) {
        this.form.current.updateInputsWithError(
          ApiErrorsHelper.convertApiValidationError(e.raw),
        );
      }
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("L'établissement a été modifié", '');
    }
  };

  render() {
    let content = null;
    if (this.state.isLoading) {
      content = (
        <InlineLoader block xl text="Chargement de votre établissement" />
      );
    } else {
      content = (
        <div className="healthFacilityPage">
          {this.state.loadingError && (
            <LoadingError
              error={this.state.loadingError}
              onRetry={this.loadData}
            />
          )}
          {this.state.id && (
            <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
              <FormGroup className="row">
                <Label className="col-sm-2">Numéro finess</Label>
                <div className="col-sm-10">{this.state.id}</div>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-2">Trigramme</Label>
                <div className="col-sm-10">{this.state.shortName}</div>
              </FormGroup>
              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Nom</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="name"
                    required
                    value={this.state.name}
                  />
                </div>
              </FormGroup>
              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Article de direction
                </Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="directionSpecifier"
                    value={this.state.directionSpecifier}
                    helpText='Préfixe correspondant au nom de l&apos;établissement  "à la ", "au ", "à l&apos;" en pensant à ajouter un espace à la fin si nécessaire'
                    required
                  />
                </div>
              </FormGroup>

              <fieldset>
                <legend>Coordonnées</legend>

                <FormGroup className="row">
                  <Label className="col-form-label col-sm-2">
                    Destinataire et/ou service
                  </Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="addressLine2"
                      value={this.state.addressLine2}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row">
                  <Label className="col-form-label col-sm-2">Complément</Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="addressLine3"
                      helpText="Entrée, Tour, Immeuble, Bâtiment, Résidence, Zone industrielle, ..."
                      value={this.state.addressLine3}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">N° et voie</Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="addressLine4"
                      value={this.state.addressLine4}
                      required
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row">
                  <Label className="col-form-label col-sm-2">
                    Mentions spéciales
                  </Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="addressLine5"
                      helpText="BP, Tri Service Arrivée, ..."
                      value={this.state.addressLine5}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">
                    Code postal et ville
                  </Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="addressLine6"
                      value={this.state.addressLine6}
                      required
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">Pays</Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="addressLine7"
                      required
                      value={this.state.addressLine7}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">Latitude</Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="latitude"
                      required
                      validations="isNumeric"
                      validationError={validationErrors.isNumeric}
                      value={this.state.latitude}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">Longitude</Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="longitude"
                      required
                      validations="isNumeric"
                      validationError={validationErrors.isNumeric}
                      value={this.state.longitude}
                    />
                  </div>
                </FormGroup>
              </fieldset>
              <fieldset>
                <legend>Paramétrage</legend>

                <FormGroup className="row">
                  <Label className="col-sm-2">Identifiant Ambulis</Label>
                  <div className="col-sm-10">{this.state.ambulisId}</div>
                </FormGroup>
              </fieldset>

              <FormGroup className="text-end">
                <Button type="submit" color="primary">
                  Valider
                </Button>
              </FormGroup>
            </ValidationForm>
          )}
        </div>
      );
    }

    return <Block irreducible>{content}</Block>;
  }
}
