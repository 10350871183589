import React from "react";
import { PropTypes } from "prop-types";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import YouTube from "react-youtube";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StructuredContent from "sharedComponents/structuredContent/StructuredContent";

import NewsApi from "admin/apis/news.api";


export default class PreviewTextNewsButton extends React.PureComponent {
  static propTypes = {
    news: PropTypes.object.isRequired,
  };

  state = {
    modalVisible: false,
    structuredContent: null,
  };

  preview = async () => {
    if (this.props.news.type === "external") {
      window.open(this.props.news.externalUrl);
    } else if (this.props.news.type === "video") {
      this.setState({ isOpen: true });
    } else {
      let nextState = { isOpen: true };

      if (this.state.structuredContent === null) {
        let news = null;

        news = await NewsApi.get(this.props.news.id).catch((e) => {
          return null;
        });

        if (news === null) {
          nextState.isOpen = false;
        } else {
          nextState.structuredContent = news.structuredContent;
        }
      }

      this.setState(nextState);
    }
  };

  onToggle = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    if (!this.props.news) {
      return null;
    }

    const withPopup =
      this.props.news.type === "text" || this.props.news.type === "video";

    return (
      <span>
        <Button
          size="sm"
          color="primary"
          onClick={this.preview}
          title="Visualiser"
        >
          <FontAwesomeIcon icon="eye" />
        </Button>
        {withPopup && (
          <Modal isOpen={this.state.isOpen} toggle={this.onToggle}>
            <ModalHeader toggle={this.props.onToggle}>
              {this.props.news.title}
            </ModalHeader>
            <ModalBody>
              {this.props.news.type === "text" && (
                <StructuredContent
                  blocks={this.state.structuredContent?.blocks}
                />
              )}
              {this.props.news.type === "video" && (
                <YouTube
                  videoId={this.props.news.videoId}
                  opts={{ width: "100%" }}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.onToggle} block>
                Fermer
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </span>
    );
  }
}
