import React from "react";

export default class ElsanLogo extends React.PureComponent {
  render() {
    return (
      <svg
        className="logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 199.23 187.14"
      >
        <title>logo Elsan</title>
        <path
          d="M101.09,164.82c-6.34,5.94-12.31,11.72-18.47,17.28C57.19,205,15,195.14,4.07,163.59c-5.95-17.18-1.48-32.16,10.82-45,5.76-6,12-11.47,18.44-17.52L18.35,87C-.53,69.2-3.85,47.16,9.46,27.87,22.61,8.83,49.93,1.68,71,12.77,78.46,16.72,84.72,23.06,91.4,28.5a5.53,5.53,0,0,1,1.52,4c-.25,5.72-4.21,8.82-8.81,11.13-4,2-7.69,1.32-10.91-2C67.58,35.81,61.59,30.7,53,29.59,42.18,28.18,31.7,33.16,27,42.4c-4.8,9.4-2.81,19.45,5.73,27.66,8.77,8.43,17.71,16.67,26.54,25,4.74,4.49,4.73,7.56,0,12.1-8.46,8-17,16-25.46,24-10.79,10.25-12.06,24-3.2,33.74a25.84,25.84,0,0,0,36.07,1.79c9.08-8.07,17.79-16.58,26.64-24.92,6.74-6.35,9-6.33,15.87.15,8.36,7.9,16.65,15.86,25.13,23.62,10.71,9.79,26.55,9.94,36.28.53,10-9.66,9.59-24.55-1.17-33.95-16.06-14-32-28.26-48.53-41.68-19.39-15.71-21-36.76-15.46-50.74C113.09,20.33,133.27,7,154,7.65c25.1.82,44.75,18.53,46.42,42.21,1,14.23-4.43,26.05-15,35.51-4.23,3.8-10.34,2.7-15-2.38-4.49-4.92-4.65-9.13-.35-13.47,7.3-7.34,9.64-15.85,5.77-25.58-3.63-9.13-13.18-14.93-23.52-14.73-15.57.29-28.32,14-26.7,28.63.71,6.35,4.11,11.1,8.81,15.14q24.45,21,48.86,42.07c16.33,14.11,21.39,32.8,13.89,51.1-7.28,17.76-27,29.65-47,28.42a49.72,49.72,0,0,1-32.31-13.93C112.49,175.47,107,170.38,101.09,164.82Z"
          transform="translate(-1.4 -7.52)"
        />
      </svg>
    );
  }
}
