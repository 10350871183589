import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import {Table, Badge, Alert} from 'reactstrap';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import Block from 'sharedComponents/block/Block';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import CreateOperatingMethodForm from './createOeratingMethodForm/CreateOperatingMethodForm';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import EditOperatingMethodForm from './editOperatingMethodForm/EditOperatingMethodForm';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import AmbulisProtocolsApi from 'clinic/apis/ambulisProtocols.api';
import OperatingMethodsLinksApi from 'clinic/apis/operatingMethodsLinks.api';
import TreatmentModesApi from 'clinic/apis/treatmentModes.api';
import WorkflowsApi from 'clinic/apis/workflows.api';

export default class OperatingMethodsLinksPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,

    operatingMethodsLinks: [],
    protocols: [],
    treatmentModes: [],
    workflows: [],
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion de l'affection des protocoles",
      activeMenuKey: 'operatingMethodsLinks',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState({isLoading: true, loadingError: null}, async () => {
      let nextState = {isLoading: false};

      let data = await Promise.all([
        OperatingMethodsLinksApi.getAll(),
        AmbulisProtocolsApi.getAll(),
        TreatmentModesApi.getAll(),
        WorkflowsApi.getAll(),
      ]).catch((e) => {
        nextState.loadingError = true;
        return [];
      });

      nextState.operatingMethodsLinks = _.sortBy(data[0], 'practitionerCode');
      nextState.protocols = _.sortBy(data[1], 'name');
      nextState.treatmentModes = _.sortBy(data[2], 'name');
      nextState.workflows = _.sortBy(data[3], 'name');

      this.setState(nextState);
    });
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let createdPlace = await OperatingMethodsLinksApi.create(formData).catch(
      (e) => {
        error = e;
        return null;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (createdPlace) {
      NotificationManager.success('Correspondance créée', null);
      this.loadData();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };

  onEdit = async (originalOML, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await OperatingMethodsLinksApi.edit(
      originalOML.mdtCode,
      originalOML.practitionerCode,
      formData,
    ).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Correspondance modifiée', null);
      this.loadData();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };

  onDelete = async (operation) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await OperatingMethodsLinksApi.delete(
      operation.mdtCode,
      operation.practitionerCode,
    ).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Association supprimée', null);
      this.loadData();
    }
  };

  render() {
    const deletedProtocoleLinksLength = this.state.operatingMethodsLinks.filter(
      (o) =>
        this.state.protocols.find((p) => p.id === o.operatingMethodId) ===
        undefined,
    ).length;

    return (
      <Block
        irreducible
        title={
          <span>
            Table de <strong>correspondance</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement de la table de corresondance"
          loadingError={this.state.loadingError}>
          {this.state.operatingMethodsLinks && this.state.protocols && (
            <div>
              <div className="clearfix">
                <CreateButtonModal
                  text="Nouvelle correspondance"
                  className="float-end mb-2"
                  onSubmit={this.onCreate}>
                  <CreateOperatingMethodForm
                    protocols={this.state.protocols}
                    treatmentModes={this.state.treatmentModes}
                    workflows={this.state.workflows}
                  />
                </CreateButtonModal>
              </div>
              {deletedProtocoleLinksLength > 0 && (
                <Alert color="danger">
                  {deletedProtocoleLinksLength} ligne(s) correspondant à un
                  protocole supprimé
                </Alert>
              )}
              <Table responsive hover striped>
                <thead>
                  <tr>
                    <td>MDT</td>
                    <td>Code praticien</td>
                    <td>Praticien</td>
                    <td>Protocole</td>
                    <td>Parcours</td>
                    <td className="width-auto text-center">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.operatingMethodsLinks.map((c) => {
                    const protocol = this.state.protocols.find(
                      (x) => x.id === c.operatingMethodId,
                    );
                    return (
                      <tr key={`${c.mdtCode}-${c.practitionerCode}`}>
                        <td>{c.mdtCode}</td>
                        <td>{c.practitionerCode}</td>
                        <td>{c.practitionerName}</td>
                        <td>
                          {c.operatingMethodId} -{' '}
                          {protocol ? protocol.name : ''}
                          {!protocol && (
                            <Badge className="ms-2" color="danger">
                              Supprimé
                            </Badge>
                          )}
                        </td>
                        <td>
                          {
                            this.state.workflows.find(
                              (x) => x.id === c.workflowId,
                            ).name
                          }
                        </td>
                        <td className="width-auto text-center">
                          <EditButtonModal
                            data={c}
                            onSubmit={this.onEdit}
                            title="Modifier une méthode d'opération"
                            className="me-1">
                            <EditOperatingMethodForm
                              operatingMethodLink={c}
                              protocols={this.state.protocols}
                              treatmentModes={this.state.treatmentModes}
                              workflows={this.state.workflows}
                            />
                          </EditButtonModal>
                          <DeleteButton
                            data={c}
                            title="Supprimer une correspondance"
                            onConfirm={this.onDelete}
                            className="ms-1">
                            Voulez-vous réellement supprimer cette
                            correspondance ?
                          </DeleteButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
