import { PropTypes } from "prop-types";
import React from "react";

import { Label } from "reactstrap";
import Input from "sharedComponents/input/Input";


export default class QuoteBlockEditor extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object
  };

  render() {
    return (
      <div className="quoteBlockEditor">
        <Label>Citation</Label>
        <Input
          name="text"
          value={this.props.data ? this.props.data.text : ""}
          required
        />
      </div>
    );
  }
}
