import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import {PropTypes} from 'prop-types';
import {Table, FormGroup} from 'reactstrap';
import React from 'react';

import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import TreatmentModeForm from './treatmentModeForm/TreatmentModeForm';

import ClinicsApi from 'admin/apis/clinics.api';

export default class TreatmentModesTab extends React.PureComponent {
  static propTypes = {
    clinicId: PropTypes.string.isRequired,
    layoutContextProvider: PropTypes.object.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    treatmentModes: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        protocols: null,
      },
      async () => {
        let nextState = {isLoading: false};

        nextState.treatmentModes = await ClinicsApi.getTreatmentModes(
          this.props.clinicId,
        ).catch((error) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.treatmentModes) {
          nextState.treatmentModes = _.sortBy(nextState.treatmentModes, 'name');
        }

        this.setState(nextState);
      },
    );
  };

  saveAddModal = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await ClinicsApi.addTreatmentMode(this.props.clinicId, formData).catch(
      (e) => {
        error = e;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('Le code MDT a été créé', null);
    this.loadData();
    return Promise.resolve();
  };

  saveEditModal = async (id, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await ClinicsApi.editTreatmentMode(this.props.clinicId, id, formData).catch(
      (e) => {
        error = e;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('Modification effectuée', null);
    this.loadData();
    return Promise.resolve();
  };

  deleteTreatmentMode = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await ClinicsApi.deleteTreatmentMode(this.props.clinicId, id).catch(
      (error) => {
        ApiErrorsHelper.manage(error);
        success = false;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Le code MDT a été supprimé', null);
      this.loadData();
    }
  };

  render() {
    return (
      <div className="treatmentModesTab">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement des codes MDT"
          loadingError={this.state.loadingError}>
          {this.state.treatmentModes && this.renderContent()}
        </PageDataLoader>
      </div>
    );
  }

  renderContent = () => (
    <>
      <FormGroup className="text-end">
        <CreateButtonModal text="Nouveau code MDT" onSubmit={this.saveAddModal}>
          <TreatmentModeForm editMDT />
        </CreateButtonModal>
      </FormGroup>
      <Table responsive hover striped>
        <thead>
          <tr>
            <th className="text-center width-auto">#</th>
            <th className="width-auto">Code MDT</th>
            <th>Nom</th>
            <th className="text-center width-auto">Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.treatmentModes.map((treatmentMode) => {
            return (
              <tr key={treatmentMode.id}>
                <td className="width-auto text-center">{treatmentMode.id}</td>
                <td className="width-auto">{treatmentMode.mdtCode}</td>
                <td>{treatmentMode.name}</td>
                <td className="width-auto text-center">
                  <EditButtonModal
                    title="Modifier un code MDT"
                    data={treatmentMode.id}
                    onSubmit={this.saveEditModal}
                    className="me-1">
                    <TreatmentModeForm treatmentMode={treatmentMode} />
                  </EditButtonModal>
                  <DeleteButton
                    title={
                      <span>
                        Supprimer <i>{treatmentMode.name}</i>
                      </span>
                    }
                    data={treatmentMode.id}
                    onConfirm={this.deleteTreatmentMode}
                    className="ms-1">
                    Voulez-vous réellement supprimer <i>{treatmentMode.name}</i>{' '}
                    ({treatmentMode.mdtCode}) ?
                  </DeleteButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
