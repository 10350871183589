import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import validationErrors from 'services/ValidationErrors';

import {Link} from 'react-router-dom';
import {Button, FormGroup, Label} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ClinicsApi from 'admin/apis/clinics.api';

export default class AddClinicPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des établissements',
      activeMenuKey: 'clinics',
    });
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let createdClinic = await ClinicsApi.add(formData).catch((response) => {
      ApiErrorsHelper.manage(response, this.form);

      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (createdClinic) {
      NotificationManager.success('Etablissement créé avec succès', null);
      this.props.history.push(`/admin/clinics/${createdClinic.id}`);
    }
  };

  render() {
    return (
      <Block
        title={
          <span>
            Création d'un <strong>établissement</strong>
          </span>
        }>
        <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Numéro finess</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="healthFacilityId"
                validations="isLength:9"
                validationError={validationErrors.isLength(9)}
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Trigramme</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="shortName"
                required
                maxLength={3}
                validations="maxLength:3"
                validationError={validationErrors.maxLength(3)}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Nom</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="name" required />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              Article de direction
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="directionSpecifier"
                helpText='Préfixe correspondant au nom de l&apos;établissement  "à la ", "au ", "à l&apos;" en pensant à ajouter un espace à la fin si nécessaire'
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Site web</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="webSite"
                validations="isUrl"
                validationError={validationErrors.isUrl}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Lien plan</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="locationMap"
                validations="isUrl"
                validationError={validationErrors.isUrl}
              />
            </div>
          </FormGroup>

          <fieldset>
            <legend>Coordonnées</legend>

            <FormGroup className="row">
              <Label className="col-form-label col-sm-2">
                Destinataire et/ou service
              </Label>
              <div className="col-sm-10">
                <Input className="form-control" name="addressLine2" />
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-form-label col-sm-2">Complément</Label>
              <div className="col-sm-10">
                <Input
                  className="form-control"
                  name="addressLine3"
                  helpText="Entrée, Tour, Immeuble, Bâtiment, Résidence, Zone industrielle, ..."
                />
              </div>
            </FormGroup>

            <FormGroup className="row required">
              <Label className="col-form-label col-sm-2">N° et voie</Label>
              <div className="col-sm-10">
                <Input className="form-control" name="addressLine4" required />
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-form-label col-sm-2">
                Mentions spéciales
              </Label>
              <div className="col-sm-10">
                <Input
                  className="form-control"
                  name="addressLine5"
                  helpText="BP, Tri Service Arrivée, ..."
                />
              </div>
            </FormGroup>

            <FormGroup className="row required">
              <Label className="col-form-label col-sm-2">
                Code postal et ville
              </Label>
              <div className="col-sm-10">
                <Input className="form-control" name="addressLine6" required />
              </div>
            </FormGroup>

            <FormGroup className="row required">
              <Label className="col-form-label col-sm-2">Pays</Label>
              <div className="col-sm-10">
                <Input className="form-control" name="addressLine7" required />
              </div>
            </FormGroup>

            <FormGroup className="row required">
              <Label className="col-form-label col-sm-2">Latitude</Label>
              <div className="col-sm-10">
                <Input
                  className="form-control"
                  name="latitude"
                  required
                  validations="isNumeric"
                  validationError={validationErrors.isNumeric}
                />
              </div>
            </FormGroup>

            <FormGroup className="row required">
              <Label className="col-form-label col-sm-2">Longitude</Label>
              <div className="col-sm-10">
                <Input
                  className="form-control"
                  name="longitude"
                  required
                  validations="isNumeric"
                  validationError={validationErrors.isNumeric}
                />
              </div>
            </FormGroup>
          </fieldset>

          <fieldset>
            <legend>Paramétrage</legend>

            <FormGroup className="row required">
              <Label className="col-form-label col-sm-2">
                Identifiant Ambulis
              </Label>
              <div className="col-sm-10">
                <Input
                  className="form-control"
                  name="ambulisId"
                  type="text"
                  validations="isInt"
                  validationError={validationErrors.isInt}
                  required
                />
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-form-label col-sm-2">
                Type document DPI retour Ambulis
              </Label>
              <div className="col-sm-10">
                <Input
                  className="form-control"
                  name="hprimDocumentType"
                  type="text"
                />
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label
                className="col-form-label col-sm-2"
                htmlFor="mdtHl7Segment">
                HL7 Segment MDT
              </Label>
              <div className="col-sm-10">
                <Input
                  className="form-control"
                  name="mdtHl7Segment"
                  type="text"
                />
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label
                className="col-form-label col-sm-2"
                htmlFor="convocationHl7Segment">
                HL7 Lieu de convocation
              </Label>
              <div className="col-sm-10">
                <Input
                  className="form-control"
                  name="convocationHl7Segment"
                  type="text"
                  helpText="Segment HL7 où récupérer la clé pour le calcul du lieu de convocation"
                />
              </div>
            </FormGroup>
          </fieldset>

          <FormGroup className="text-end">
            <Link
              to="/admin/clinics"
              className="btn btn-outline-primary me-1"
              type="button">
              Retour à la liste
            </Link>
            <Button type="submit" color="primary">
              Valider
            </Button>
          </FormGroup>
        </ValidationForm>
      </Block>
    );
  }
}
