import React from 'react';
import {PropTypes} from 'prop-types';

import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';

import {FormGroup, Label, Button} from 'reactstrap';
import HtmlEditor from 'sharedComponents/htmlEditor/HtmlEditor';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
// import Checkbox from "sharedComponents/checkbox/Checkbox";
import DatePicker from 'sharedComponents/datePicker/DatePicker';

import WorkflowsApi from 'clinic/apis/workflows.api';
import Checkbox from 'sharedComponents/checkbox/Checkbox';

class GeneralTab extends React.PureComponent {
  static propTypes = {
    workflow: PropTypes.object.isRequired,
  };

  state = {
    addRoomReservation: this.props.workflow.roomReservationEnabled,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  addRoomReservationOnCheckboxChange = () => {
    this.setState((prevState) => ({
      addRoomReservation: !prevState.addRoomReservation,
    }));
  };

  handleSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await WorkflowsApi.update(this.props.workflow.id, formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      success = false;
    });

    if (success) {
      NotificationManager.success('Le parcours a été mis à jour', null);
    }

    this.props.layoutContextProvider.hideLoader();
    this.props.reload();
  };

  render() {
    return (
      <div>
        <ValidationForm ref={this.form} onSubmit={this.handleSubmit}>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Nom</Label>
            <div className="col-sm-10">
              <Input name="name" required value={this.props.workflow.name} />
            </div>
          </FormGroup>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              Documents à fournir
            </Label>
            <div className="col-sm-10">
              <HtmlEditor
                type="textarea"
                className="form-control"
                name="hospitalizationRequirements"
                required
                value={this.props.workflow.hospitalizationRequirements}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Lien plan interactif
            </Label>
            <div className="col-sm-10">
              <Input
                name="interactiveMapUrl"
                value={this.props.workflow.interactiveLocationMap}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Date début notifications patient
            </Label>
            <div className="col-sm-10">
              <DatePicker
                name="patientNotificationStartDate"
                id="patientNotificationStartDate"
                className="form-control"
                value={this.props.workflow.patientNotificationStartDate}
              />
            </div>
          </FormGroup>
          {/* <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Activation offre hôtelière
            </Label>
            <div className="col-sm-10">
              <Checkbox
                id="roomReservationEnabled"
                name="roomReservationEnabled"
                onChange={this.addRoomReservationOnCheckboxChange}
                checked={this.props.workflow.roomReservationEnabled}
              />
            </div>
          </FormGroup> */}
          <FormGroup
            className={this.state.addRoomReservation ? 'row required' : 'row'}>
            <Label className="col-form-label col-sm-2">
              Numéro de tel offre hôtelière
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="roomReservationPhoneNumber"
                required={this.state.addRoomReservation}
                value={this.props.workflow.roomReservationPhoneNumber}
              />
            </div>
          </FormGroup>
          <FormGroup
            className={this.state.addRoomReservation ? 'row required' : 'row'}>
            <Label className="col-form-label col-sm-2">
              Email offre hôtelière
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="roomReservationEmail"
                required={this.state.addRoomReservation}
                value={this.props.workflow.roomReservationEmail}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Afficher le déroulé Jour-J
            </Label>
            <div className="col-sm-10">
              <Checkbox
                id="displayHospitalizationSteps"
                name="displayHospitalizationSteps"
                checked={this.props.workflow.displayHospitalizationSteps}
              />
            </div>
          </FormGroup>

          <FormGroup className="text-end">
            <Link
              to="/clinic/workflows"
              className="btn btn-outline-primary me-2"
              type="button">
              Retour à la liste
            </Link>
            <Button color="primary" type="submit">
              Valider
            </Button>
          </FormGroup>
        </ValidationForm>
      </div>
    );
  }
}

export default withLayoutContextProvider(GeneralTab);
