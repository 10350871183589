import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCopy,
  faHome,
  faHospitalAlt,
  faFile,
  faFileAlt,
  faPowerOff,
  faSearch,
  faImage,
  faCircle,
  faEdit,
  faInfoCircle,
  faSyncAlt,
  faTimes,
  faList,
  faBoxes,
  faPlus,
  faPlusCircle,
  faCode,
  faExclamationCircle,
  faExclamationTriangle,
  faCaretSquareDown,
  faHeart,
  faPen,
  faListUl,
  faTrash,
  faHospital,
  faTable,
  faStethoscope,
  faMapMarkerAlt,
  faHeartbeat,
  faBinoculars,
  faClipboard,
  faUsers,
  faKey,
  faArrowUp,
  faChartLine,
  faEye,
  faQuoteLeft,
  faQuoteRight,
  faArrowsAlt,
  faNewspaper,
  faPlay,
  faFolderPlus,
  faQuestionCircle,
  faClipboardList,
  faExternalLinkAlt,
  faGlobe,
  faLink,
  faCommentDots,
  faArchive,
  faMapMarkedAlt,
  faShoppingCart,
  faCheck,
  faBed,
  faTasks,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { faSkyatlas } from "@fortawesome/free-brands-svg-icons";

export default class FontAwesomeLibrary {
  static registerIcons() {
    library.add(faArchive);
    library.add(faArrowUp);
    library.add(faArrowsAlt);
    library.add(faBars);
    library.add(faBed);
    library.add(faBinoculars);
    library.add(faBoxes);
    library.add(faCaretSquareDown);
    library.add(faChartLine);
    library.add(faCheck);
    library.add(faChevronDown);
    library.add(faChevronLeft);
    library.add(faChevronRight);
    library.add(faChevronUp);
    library.add(faCircle);
    library.add(faClipboard);
    library.add(faClipboardList);
    library.add(faCode);
    library.add(faCopy);
    library.add(faCommentDots);
    library.add(faEdit);
    library.add(faExclamationCircle);
    library.add(faExclamationTriangle);
    library.add(faExternalLinkAlt);
    library.add(faEye);
    library.add(faFile);
    library.add(faFileAlt);
    library.add(faFolderPlus);
    library.add(faGlobe);
    library.add(faHeart);
    library.add(faHeartbeat);
    library.add(faHome);
    library.add(faHospital);
    library.add(faHospitalAlt);
    library.add(faImage);
    library.add(faInfoCircle);
    library.add(faKey);
    library.add(faLink);
    library.add(faList);
    library.add(faListUl);
    library.add(faMapMarkedAlt);
    library.add(faMapMarkerAlt);
    library.add(faNewspaper);
    library.add(faPen);
    library.add(faPlay);
    library.add(faPlus);
    library.add(faPlusCircle);
    library.add(faPowerOff);
    library.add(faQuestionCircle);
    library.add(faQuoteLeft);
    library.add(faQuoteRight);
    library.add(faSearch);
    library.add(faShoppingCart);
    library.add(faSkyatlas);
    library.add(faStethoscope);
    library.add(faSyncAlt);
    library.add(faTable);
    library.add(faTasks);
    library.add(faTimes);
    library.add(faTrash);
    library.add(faUsers);
    library.add(faUser);
    // Add icons in alphabetical order please
  }
}
