import React from "react";
import { PropTypes } from "prop-types";

import ApiErrorsHelper from "helpers/ApiErrorsHelper";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidationForm from "sharedComponents/validationForm/ValidationForm";

export default class CreateButtonModal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.string,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    text: "Créer un élément",
    icon: "plus-circle",
  };

  state = {
    modalVisible: false,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleModal = () => {
    if (this._isMounted) {
      this.setState((prevState) => {
        return { modalVisible: !prevState.modalVisible };
      });
    }
  };

  handleSubmit = async (formData) => {
    if (this.props.onSubmit && typeof this.props.onSubmit === "function") {
      let success = true;

      await this.props.onSubmit(formData).catch((error) => {
        ApiErrorsHelper.manage(error, this.form);
        success = false;
      });

      if (success) {
        this.toggleModal();
      }
    }
  };

  render() {
    const { text, children, onSubmit, data, ...otherProps } = this.props;

    return (
      <span className="createButtonContainer">
        <Button color="primary" onClick={this.toggleModal} {...otherProps}>
          <FontAwesomeIcon icon={this.props.icon} /> {text}
        </Button>
        <Modal isOpen={this.state.modalVisible} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>{text}</ModalHeader>
          <ValidationForm ref={this.form} onSubmit={this.handleSubmit}>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.toggleModal}>
                Annuler
              </Button>
              <Button color="primary" type="submit">
                Valider
              </Button>
            </ModalFooter>
          </ValidationForm>
        </Modal>
      </span>
    );
  }
}
