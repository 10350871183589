import ApiClient from 'clinic/services/ApiClient';

export default class PreOpInstructionsApi {
  static getAll = () => ApiClient.get('preOpInstructions');

  static get = (id) => ApiClient.get(`preOpInstructions/${id}`);

  static getArticlesIds(preOpInstructionsId) {
    return ApiClient.get(`preOpInstructions/${preOpInstructionsId}/articles`);
  }

  static add = (data) => ApiClient.post('preOpInstructions', data);

  static updateGeneral = (id, data) =>
    ApiClient.patch(`preOpInstructions/${id}`, data);

  static delete = (id) => ApiClient.delete(`preOpInstructions/${id}`);

  static addBlock = (id, blockType, data) =>
    ApiClient.post(`preOpInstructions/${id}/blocks/${blockType}`, data, true);

  static editBlock = (id, blockId, data) =>
    ApiClient.post(
      `preOpInstructions/${id}/blocks/${blockId}/content`,
      data,
      true,
    );

  static moveBlock = (id, blockId, data) =>
    ApiClient.patch(`preOpInstructions/${id}/blocks/${blockId}/order`, data);

  static deleteBlock = (id, blockId) =>
    ApiClient.delete(`preOpInstructions/${id}/blocks/${blockId}`);

  static addArticle(preOpInstructionsId, data) {
    return ApiClient.post(
      `preOpInstructions/${preOpInstructionsId}/articles`,
      data,
    );
  }

  static removeArticle(preOpInstructionsId, articleId) {
    return ApiClient.delete(
      `preOpInstructions/${preOpInstructionsId}/articles/${articleId}`,
    );
  }

  static changeArticleOrder = (preOpInstructionsId, articleId, newOrder) =>
    ApiClient.patch(
      `preOpInstructions/${preOpInstructionsId}/articles/${articleId}/order`,
      {
        position: newOrder,
      },
    );

  static duplicate = (id) =>
    ApiClient.post(`preOpInstructions/${id}/duplicate`);
}
