import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import OrderingHelper from 'helpers/OrderingHelper';

import {Badge, Row, Col, FormGroup} from 'reactstrap';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Block from 'sharedComponents/block/Block';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import HealthFoldersApi from 'admin/apis/healthFolders.api';

export default class HealthFoldersPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    healthFolders: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des dossiers santé',
      activeMenuKey: 'healthFolders_folders',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, articles: null},
      async () => {
        let nextState = {isLoading: false};
        let folders = await HealthFoldersApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (folders) {
          nextState.healthFolders = _.sortBy(folders, 'order');
        }

        this.setState(nextState);
      },
    );
  };

  onDrop = (result) => {
    if (!result.destination || result.source.index === result.destination.index)
      return;

    let orderedFolders = OrderingHelper.reorder(
      this.state.healthFolders,
      result.source.index,
      result.destination.index,
    );

    for (var i = 0; i < orderedFolders.length; i++) {
      orderedFolders[i].order = i + 1;
    }

    let lastSateOrder = [...this.state.healthFolders];
    const healthFolderId = this.state.healthFolders[result.source.index].id;

    this.setState({healthFolders: orderedFolders}, async () => {
      this.props.layoutContextProvider.showLoader();
      await HealthFoldersApi.changeOrder(
        healthFolderId,
        result.destination.index + 1,
      ).catch((error) => {
        this.setState({healthFolders: lastSateOrder});
      });

      this.props.layoutContextProvider.hideLoader();
    });
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await HealthFoldersApi.delete(id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    if (success) {
      NotificationManager.success('Dossier santé supprimé avec succès', null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>dossiers santé</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.healthFolders) return null;

    return (
      <div>
        <FormGroup className="text-end">
          <CreateButtonLink
            to="/admin/healthFolders/folders/add"
            text="Nouveau dossier santé"
          />
        </FormGroup>

        <DragDropContext onDragEnd={this.onDrop}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className={`droppable ${
                  snapshot.isDraggingOver ? 'dragging' : 'notDragging'
                }`}
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {this.state.healthFolders.map((folder, i) => (
                  <Draggable
                    key={`healthFolder-${folder.order}`}
                    draggableId={`${folder.order}`}
                    index={i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div className="item">
                          <Row className="align-items-center">
                            <Col xs="auto">
                              <FontAwesomeIcon icon="arrows-alt" />
                            </Col>
                            <Col>{folder.title}</Col>
                            <Col
                              xs="auto"
                              className="text-center"
                              style={{width: '100px', textAlign: 'center'}}>
                              <Badge
                                color={
                                  folder.published ? 'success' : 'secondary'
                                }>
                                {folder.published ? 'Publié' : 'Non publié'}
                              </Badge>
                            </Col>
                            <Col
                              xs="auto"
                              className="text-center"
                              style={{
                                width: '120px',
                                textAlign: 'center',
                              }}>
                              {folder.onHomePage && (
                                <Badge color="success">Écran d'accueil</Badge>
                              )}
                            </Col>
                            <Col xs="auto">
                              <EditButtonLink
                                to={`/admin/healthFolders/folders/${folder.id}`}>
                                <FontAwesomeIcon icon="pen" />
                              </EditButtonLink>

                              <DeleteButton
                                title={
                                  <span>
                                    Supprimer <i>{folder.title}</i>
                                  </span>
                                }
                                data={folder.id}
                                onConfirm={this.onDelete}
                                className="ms-1"
                                tooltip={`Supprimer ${folder.title}`}>
                                <div>
                                  Voulez-vous réellement supprimer le dossier
                                  santé <i>{folder.title}</i> ?
                                </div>
                                <div>
                                  <FontAwesomeIcon icon="exclamation-triangle" />
                                  &nbsp; Cela supprimera les articles qu'il
                                  contient.
                                </div>
                              </DeleteButton>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };
}
