import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import React from "react";

export default class EditButtonLink extends React.PureComponent {
  static propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    tooltip: "Modifier",
  };

  render() {
    return (
      <Link
        className={`btn btn-warning btn-sm ${this.props.className}`}
        to={this.props.to}
        title={this.props.tooltip}
      >
        <FontAwesomeIcon icon="pen" />
      </Link>
    );
  }
}
