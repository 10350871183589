import React from "react";
import { PropTypes } from "prop-types";

import { FormGroup, Label } from "reactstrap";
import Input from "sharedComponents/input/Input";

export default class CategoryForm extends React.PureComponent {
  static propTypes = {
    category: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  };

  render() {
    return (
      <div className="categoryForm">
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input
            name="title"
            required
            value={this.props.category ? this.props.category.title : ""}
          />
        </FormGroup>
      </div>
    );
  }
}
