import React from "react";
import classnames from "classnames";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Block from "sharedComponents/block/Block";

import ClinicArticlesTab from "./clinicArticlesTab/ClinicArticlesTab";
import GroupArticlesTab from "./groupArticlesTab/GroupArticlesTab";

export default class ArticlesListPage extends React.PureComponent {
  state = {
    activeTab: window.location.hash ? window.location.hash.substr(1) : "clinic"
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des articles",
      activeMenuKey: "articles"
    });
  }

  goToTab = tab => {
    this.setState({ activeTab: tab });
    this.props.history.replace(`#${tab}`);
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>articles</strong>
          </span>
        }
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "clinic"
              })}
              onClick={() => this.goToTab("clinic")}
            >
              Articles clinique
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "group"
              })}
              onClick={() => this.goToTab("group")}
            >
              Articles Groupe
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="clinic">
            <ClinicArticlesTab />
          </TabPane>
          <TabPane tabId="group">
            <GroupArticlesTab />
          </TabPane>
        </TabContent>
      </Block>
    );
  }
}
