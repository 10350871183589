import {PropTypes} from 'prop-types';
import React from 'react';
import BlockRenderer from './blockRenderer/StructuredContentRenderer';
import _ from 'lodash';
import {Alert} from 'reactstrap';
import AddBlockButton from './addBlockButton/AddBlockButton';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import PreviewModalButton from './previewModalButton/PreviewModalButton';
import OrderingHelper from 'helpers/OrderingHelper';

export default class StructuredContent extends React.PureComponent {
  static propTypes = {
    blocks: PropTypes.arrayOf(BlockRenderer.propTypes.block),
    editable: PropTypes.bool,

    onBlockAdded: PropTypes.func,
    onBlockEdited: PropTypes.func,
    onBlockMoved: PropTypes.func,
    onBlockDeleted: PropTypes.func,
  };

  static defaultProps = {
    blocks: [],
    editable: false,
    onBlockAdded: () => {},
    onBlockEdited: () => {},
    onBlockMoved: () => {},
    onBlockDeleted: () => {},
  };

  onDrop = (result) => {
    if (!result.destination || result.source.index === result.destination.index)
      return;

    let orderedBlocks = OrderingHelper.reorder(
      this.props.blocks,
      result.source.index,
      result.destination.index,
    );

    for (var i = 0; i < orderedBlocks.length; i++) {
      orderedBlocks[i].order = i + 1;
    }

    this.props.onBlockMoved(
      {
        from: result.source.index,
        to: result.destination.index,
      },
      orderedBlocks,
    );
  };

  renderButtons = (position) => {
    if (
      position === 'bottom' &&
      (!this.props.blocks || this.props.blocks.length === 0)
    ) {
      return null;
    }

    const {blocks, onChange, ...renderersProps} = this.props;

    const readonlyContent = blocks ? (
      <>
        {blocks.map((b) => (
          <BlockRenderer
            key={`blockRenderer-${b.order}`}
            block={b}
            {...renderersProps}
            editable={false}
          />
        ))}
      </>
    ) : null;

    return (
      <div className="text-end form-group">
        {this.props.blocks && this.props.blocks.length > 0 && (
          <PreviewModalButton>{readonlyContent}</PreviewModalButton>
        )}
        <AddBlockButton
          block
          text="Ajouter un élément"
          onSubmit={this.props.onBlockAdded}
          nextOrderValue={
            !blocks || blocks.length === 0
              ? 1
              : _.maxBy(this.props.blocks, (b) => b.order).order + 1
          }
        />
      </div>
    );
  };

  render() {
    const {blocks, onBlockDeleted, onBlockEdited, ...renderersProps} =
      this.props;

    const readonlyContent = blocks ? (
      <>
        {blocks.map((b) => (
          <BlockRenderer
            key={`blockRenderer-${b.order}`}
            block={b}
            {...renderersProps}
            editable={false}
          />
        ))}
      </>
    ) : null;

    if (!this.props.editable) {
      return <>{readonlyContent}</>;
    }

    return (
      <div className="structuredContent">
        {this.renderButtons('top')}

        {(!this.props.blocks || this.props.blocks.length === 0) && (
          <Alert color="info">
            Ce contenu n'est pas renseigné pour l'instant
          </Alert>
        )}

        {this.props.blocks && this.props.blocks.length > 0 && (
          <div className="mb-3">
            <DragDropContext onDragEnd={this.onDrop}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className={`droppable ${
                      snapshot.isDraggingOver ? 'dragging' : 'notDragging'
                    }`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {blocks &&
                      blocks.map((b, i) => (
                        <Draggable
                          key={`blockRenderer-${b.order}`}
                          draggableId={`${b.order}`}
                          index={i}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              <BlockRenderer
                                block={b}
                                {...renderersProps}
                                onEdit={onBlockEdited}
                                onRemove={onBlockDeleted}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        {this.renderButtons('bottom')}
      </div>
    );
  }
}
