import React from 'react';
import PropTypes from 'prop-types';

import {NotificationManager} from 'react-notifications';
import {withLayoutContextProvider} from 'services/LayoutContext';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {FormGroup, Button, Label} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import YoutubeInput from 'sharedComponents/youtubeInput/YoutubeInput';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ArticlesApi from 'admin/apis/articles.api';

class GeneralTab extends React.PureComponent {
  static props = {
    article: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      videoProvider: PropTypes.string,
      videoId: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit = async (formData) => {
    if (!formData.smallPictureFile) {
      delete formData.smallPictureFile;
    }

    if (!formData.largePictureFile) {
      delete formData.largePictureFile;
    }

    this.props.layoutContextProvider.showLoader();

    let error = null;

    await ArticlesApi.editGeneral(this.props.article.id, formData).catch(
      (e) => {
        ApiErrorsHelper.manage(e, this.form);
        error = e;
      },
    );

    if (!error) {
      NotificationManager.success("L'article a été modifié", null);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row required">
          <Label className="col-sm-2">Titre</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="title"
              value={this.props.article.title}
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-sm-2">Titre application</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="appTitle"
              value={this.props.article.appTitle}
              required
            />
          </div>
        </FormGroup>

        {this.props.article.type === 'video' && (
          <FormGroup className="row required">
            <Label className="col-sm-2">ID YouTube de la vidéo</Label>
            <div className="col-sm-10">
              <YoutubeInput
                required
                name="videoId"
                value={this.props.article.videoId}
              />
            </div>
          </FormGroup>
        )}

        <FormGroup className="row">
          <Label className="col-sm-2">
            Petite image (aperçu sur la liste des articles)
          </Label>
          <div className="col-sm-10">
            <ImageUploader
              name="smallPictureFile"
              actualUrl={this.props.article.smallPictureUrl}
              helpText="Taille attendue : 417px par 417px"
            />
          </div>
        </FormGroup>

        {this.props.article.type === 'text' && (
          <FormGroup className="row">
            <Label className="col-sm-2">
              Grande image (entête de l'article)
            </Label>
            <div className="col-sm-10">
              <ImageUploader
                name="largePictureFile"
                actualUrl={this.props.article.largePictureUrl}
                helpText="Taille attendue : 1125px par 788px"
              />
            </div>
          </FormGroup>
        )}

        <FormGroup className="row">
          <div className="col-sm-10">
            <Checkbox
              name="published"
              id="published"
              checked={this.props.article.published}
              label="Publié"
            />
          </div>
        </FormGroup>

        <FormGroup className="text-end">
          <Link
            to="/admin/articles"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(withRouter(GeneralTab));
