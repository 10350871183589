import ApiClient from "admin/services/ApiClient";
import queryString from "query-string";

export default class HealthFoldersApi {
  static getAll(onlyPublished = false) {
    let params = {};
    if (onlyPublished) {
      params.onlyPublished = null;
    }

    return ApiClient.get(`healthFolders?${queryString.stringify(params)}`);
  }

  static get = (id) => ApiClient.get(`healthFolders/${id}`);

  static changeOrder = (healthFolderId, newOrder) =>
    ApiClient.put(`healthFolders/${healthFolderId}/order`, {
      newOrder: newOrder,
    });

  static create = (formData) => ApiClient.post("healthFolders", formData, true);

  static edit = (id, formData) =>
    ApiClient.post(`healthFolders/${id}`, formData, true);

  static delete = (id) => ApiClient.delete(`healthFolders/${id}`);

  static getForms = (id) => ApiClient.get(`healthFolders/${id}/forms`);

  static addForm = (id, formData) =>
    ApiClient.post(`healthFolders/${id}/forms`, formData);

  static removeForm = (id, formId) =>
    ApiClient.delete(`healthFolders/${id}/forms/${formId}`);

  static getAvailableForms = (id) =>
    ApiClient.get(`healthFolders/${id}/availableForms`);

  static changeFormOrder = (healthFolderId, formId, newOrder) =>
    ApiClient.put(`healthFolders/${healthFolderId}/forms/${formId}/order`, {
      newOrder: newOrder,
    });
}
