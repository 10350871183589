import React from "react";
import { PropTypes } from "prop-types";

import { Button } from "reactstrap";

export default class LinkButton extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  render() {
    const { children, onClick } = this.props;
    return (
        <>
      <Button onClick={onClick} style={{boxShadow: 'none'}} color="link">
        {children}
      </Button>
      </>
    );
  }
}
