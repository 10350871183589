import React from 'react';
import {PropTypes} from 'prop-types';

import _ from 'lodash';

import {Table, FormGroup, Label} from 'reactstrap';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import Select from 'sharedComponents/select/Select';

export default class HealthFoldersTab extends React.PureComponent {
  static propTypes = {
    allHealthFolders: PropTypes.arrayOf(PropTypes.object).isRequired,
    categoryHealthFolders: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  onAdd = (data) => this.props.onAdd(data.healthFolderId);

  onDelete = (id) => this.props.onDelete(id);

  render() {
    const {categoryHealthFolders, allHealthFolders} = this.props;

    const usedIds = categoryHealthFolders.map((f) => f.id);
    const addableHealthFolders = _.filter(
      allHealthFolders,
      (f) => !usedIds.includes(f.id),
    ).map((f) => ({
      label: f.title,
      value: f.id,
    }));

    const orderedHealthFolders = _.sortBy(categoryHealthFolders, 'title');

    return (
      <div>
        <FormGroup className="text-end">
          <CreateButtonModal
            text="Ajouter un dossier santé"
            onSubmit={this.onAdd}>
            <Label>Dossier santé</Label>
            <Select
              id="healthFolderId"
              name="healthFolderId"
              placeholder="Choisir un dossier santé"
              searchable
              options={addableHealthFolders}
              required
            />
          </CreateButtonModal>
        </FormGroup>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orderedHealthFolders.map((healthFolder) => (
              <tr key={`${healthFolder.id}`}>
                <td>{healthFolder.title}</td>
                <td className="text-center width-auto">
                  <DeleteButton
                    data={healthFolder.id}
                    onConfirm={this.onDelete}
                    tooltip={`Enlever ${healthFolder.title}`}>
                    Voulez-vous réellement enlever le dossier
                    <i>{healthFolder.title}</i> de la catégorie ?
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
