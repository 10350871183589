import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class CreateButtonLink extends React.PureComponent {
  static propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    text: "Nouveau",
    className: ""
  };

  render() {
    return (
      <Link
        to={this.props.to}
        className={`btn btn-primary ${this.props.className}`}
      >
        <FontAwesomeIcon icon="plus-circle" /> {this.props.text}
      </Link>
    );
  }
}
