import React from 'react';
import {PropTypes} from 'prop-types';

import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class PreviewHtmlButton extends React.PureComponent {
  static propTypes = {
    html: PropTypes.string.isRequired,
  };

  state = {
    modalVisible: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return {modalVisible: !prevState.modalVisible};
    });

  render() {
    return (
      <span>
        <Button
          size="sm"
          color="primary"
          onClick={this.toggleModal}
          title="Visualiser">
          <FontAwesomeIcon icon="eye" />
        </Button>
        <Modal isOpen={this.state.modalVisible} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal} />
          <ModalBody>
            <div dangerouslySetInnerHTML={{__html: this.props.html}}></div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleModal}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}
