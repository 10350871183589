import { PropTypes } from 'prop-types'
import React from 'react'
import './QuoteBlockRenderer.scss'

export default class QuoteBlockRenderer extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    trunk: PropTypes.bool
  }

  static defaultProps = {
    trunk: false
  }

  render () {
    return (
      <div className='quoteBlockRenderer d-flex flex-row justify-content-between'>
        <div className={`quoteText ${this.props.trunk ? 'trunk' : ''}`}>
          {this.props.data.text}
        </div>
      </div>
    )
  }
}
