import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import validationErrors from 'services/ValidationErrors';

import {Link} from 'react-router-dom';
import {FormGroup, Label, Button} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import Input from 'sharedComponents/input/Input';

import FaqQuestionsApi from 'admin/apis/faqQuestions.api';

export default class EditFaqQuestionPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    question: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: 'Gestion de la FAQ',
      activeMenuKey: 'faq',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, question: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.question = await FaqQuestionsApi.get(this.id).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    let error = false;

    await FaqQuestionsApi.edit(this.state.question.id, formData).catch((e) => {
      ApiErrorsHelper.manage(error, this.form);
      error = true;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success(
        'La question a été modifiée avec succès',
        null,
      );
      this.props.history.push('/admin/faq');
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong> question</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de la question/réponse">
          {this.state.question && (
            <>
              <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">Question</Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="question"
                      validations="maxLength:500"
                      validationError={validationErrors.maxLength(500)}
                      value={this.state.question.question}
                      required
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">Réponse</Label>
                  <div className="col-sm-10">
                    <Input
                      type="textarea"
                      className="form-control"
                      name="answer"
                      value={this.state.question.answer}
                      required
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row">
                  <Label className="col-form-label col-sm-2">
                    Lien externe
                  </Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="externalLink"
                      value={this.state.question.externalLink}
                      validationError={validationErrors.maxLength(255)}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="col-form-label pt-0 col-sm-2"
                    for="published">
                    Publié
                  </Label>
                  <div className="col-sm-10">
                    <Checkbox
                      id="published"
                      name="published"
                      label="Oui"
                      checked={this.state.question.published}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="text-end">
                  <Link
                    to="/admin/faq"
                    className="btn btn-outline-primary me-1"
                    type="button">
                    Retour à la liste
                  </Link>
                  <Button type="submit" color="primary">
                    Valider
                  </Button>
                </FormGroup>
              </ValidationForm>
            </>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
