import React from 'react';
import PropTypes from 'prop-types';

import {NotificationManager} from 'react-notifications';
import validationErrors from 'services/ValidationErrors';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Button, FormGroup, Label} from 'reactstrap';
import {Link} from 'react-router-dom';
import Input from 'sharedComponents/input/Input';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ClinicsApi from 'admin/apis/clinics.api';

export default class GeneralTab extends React.PureComponent {
  static propTypes = {
    clinicId: PropTypes.string.isRequired,
    layoutContextProvider: PropTypes.object.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: null,
    clinic: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.loadClinic();
  }

  loadClinic = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        clinic: null,
      },
      async () => {
        let nextState = {isLoading: false};

        nextState.clinic = await ClinicsApi.get(this.props.clinicId).catch(
          (e) => {
            nextState.loadingError = true;
            return null;
          },
        );

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;

    await ClinicsApi.edit(this.props.clinicId, formData).catch((error) => {
      ApiErrorsHelper.manage(error, this.form);

      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Modification effectuée.', null);
    }
  };

  render() {
    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        loadingText="Chargement de l'établissement">
        {this.state.clinic && this.renderContent()}
      </PageDataLoader>
    );
  }

  renderContent = () => {
    const clinic = this.state.clinic;
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row">
          <Label className="col-sm-2">Numéro finess</Label>
          <div className="col-sm-10">{this.props.clinicId}</div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-sm-2">Trigramme</Label>
          <div className="col-sm-10">{clinic.shortName}</div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Nom</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="name"
              required
              value={clinic.name}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">
            Article de direction
          </Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="directionSpecifier"
              value={clinic.directionSpecifier}
              helpText='Préfixe correspondant au nom de l&apos;établissement  "à la ", "au ", "à l&apos;" en pensant à ajouter un espace à la fin si nécessaire'
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Site web</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="webSite"
              validations="isUrl"
              validationError={validationErrors.isUrl}
              value={clinic.webSite}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Lien plan</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="locationMap"
              validations="isUrl"
              validationError={validationErrors.isUrl}
              value={clinic.locationMap}
            />
          </div>
        </FormGroup>

        <fieldset>
          <legend>Coordonnées</legend>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Destinataire et/ou service
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine2"
                value={clinic.addressLine2}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Complément</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine3"
                helpText="Entrée, Tour, Immeuble, Bâtiment, Résidence, Zone industrielle, ..."
                value={clinic.addressLine3}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">N° et voie</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine4"
                value={clinic.addressLine4}
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Mentions spéciales
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine5"
                helpText="BP, Tri Service Arrivée, ..."
                value={clinic.addressLine5}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              Code postal et ville
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine6"
                value={clinic.addressLine6}
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Pays</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine7"
                value={clinic.addressLine7}
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Latitude</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="latitude"
                required
                validations="isNumeric"
                validationError={validationErrors.isNumeric}
                value={clinic.latitude}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Longitude</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="longitude"
                required
                validations="isNumeric"
                validationError={validationErrors.isNumeric}
                value={clinic.longitude}
              />
            </div>
          </FormGroup>
        </fieldset>

        <fieldset>
          <legend>Paramétrage</legend>

          <FormGroup className="row required">
            <Label className="col-sm-2">Identifiant Ambulis</Label>
            <div className="col-sm-10">{clinic.ambulisId}</div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Type document DPI retour Ambulis
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="hprimDocumentType"
                type="text"
                value={clinic.hprimDocumentType}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label for="displayEmailPreadAlert" className="col-sm-2">
              Alerte pread email
            </Label>
            <div className="col-sm-10">
              <Checkbox
                id="displayEmailPreadAlert"
                name="displayEmailPreadAlert"
                checked={clinic.displayEmailPreadAlert}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label for="deployed" className="col-sm-2">
              Etablissement déployé
            </Label>
            <div className="col-sm-10">
              <Checkbox
                id="deployed"
                name="deployed"
                checked={clinic.deployed}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2" htmlFor="mdtHl7Segment">
              HL7 Segment MDT
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="mdtHl7Segment"
                type="text"
                value={clinic.mdtHl7Segment}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label
              className="col-form-label col-sm-2"
              htmlFor="convocationHl7Segment">
              HL7 Lieu de convocation
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="convocationHl7Segment"
                type="text"
                value={clinic.convocationHl7Segment}
                helpText="Segment HL7 où récupérer la clé pour le calcul du lieu de convocation"
              />
            </div>
          </FormGroup>
        </fieldset>

        <FormGroup className="text-end">
          <Link
            to="/admin/clinics"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  };
}
