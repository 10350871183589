import { PropTypes } from "prop-types";
import React from "react";
import Input from "sharedComponents/input/Input";
import { Label, FormGroup } from "reactstrap";

export default class TitleBlockEditor extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  render() {
    return (
      <FormGroup>
        <Label>Titre</Label>
        <Input
          name="text"
          value={this.props.data ? this.props.data.text : ""}
          required
        />
      </FormGroup>
    );
  }
}
