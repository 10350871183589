import React from 'react';
import PropTypes from 'prop-types';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';

import {FormGroup, Button, Label} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import Input from 'sharedComponents/input/Input';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import MapsApi from 'admin/apis/maps.api';

class GeneralTab extends React.PureComponent {
  static props = {
    mapId: PropTypes.number.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    map: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, articles: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.map = await MapsApi.get(this.props.mapId).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.map) {
          nextState.activeTab = 'general';
        }

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    if (!formData.placeImageUrl) {
      delete formData.placeImageUrl;
    }

    this.props.layoutContextProvider.showLoader();

    let error = null;

    await MapsApi.editGeneral(this.state.map.id, formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    if (!error) {
      NotificationManager.success('La carte interactive a été modifiée', null);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        loadingText="Chargement de la carte interactive">
        {this.state.map && (
          <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
            <FormGroup className="row">
              <Label className="col-sm-2">Code interne</Label>
              <div className="col-sm-10">{this.state.map.internalCode}</div>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-2">Titre</Label>
              <div className="col-sm-10">
                <Input name="title" value={this.state.map.title} required />
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-2">Visuel marqueur</Label>
              <div className="col-sm-10">
                <ImageUploader
                  name="placeImageFile"
                  helpText="Taille attendue : 1125px par 788px"
                  actualUrl={this.state.map.placeImageUrl}
                />
              </div>
            </FormGroup>

            <FormGroup className="text-end">
              <Link
                to="/admin/maps"
                className="btn btn-outline-primary me-1"
                type="button">
                Retour à la liste
              </Link>
              <Button type="submit" color="primary">
                Valider
              </Button>
            </FormGroup>
          </ValidationForm>
        )}
      </PageDataLoader>
    );
  }
}

export default withLayoutContextProvider(withRouter(GeneralTab));
