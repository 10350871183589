import {Label, FormGroup} from 'reactstrap';
import {PropTypes} from 'prop-types';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import React from 'react';

export default class EditArticleButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    article: PropTypes.object.isRequired,
    summaryVisible: PropTypes.bool.isRequired,
    preopVisible: PropTypes.bool.isRequired,
    postopVisible: PropTypes.bool.isRequired,
  };

  state = {
    modalVisible: false,
    articles: [],
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return {modalVisible: !prevState.modalVisible};
    });

  onSubmit = async (id, formData) => {
    return this.props.onSubmit(id, formData);
  };

  render() {
    return (
      <EditButtonModal
        title={
          <span>
            Modifier <i>{this.props.article.title}</i>
          </span>
        }
        data={this.props.article.id}
        onSubmit={this.onSubmit}
        className="ms-1">
        <FormGroup>
          <Label>Présent sur l'écran récap</Label>
          <Checkbox
            name="summaryVisible"
            label="Oui"
            id="summaryVisible"
            checked={this.props.summaryVisible}
          />
        </FormGroup>
        <FormGroup>
          <Label>Afficher en preop</Label>
          <Checkbox
            name="preopVisible"
            label="Oui"
            id="preopVisible"
            checked={this.props.preopVisible}
          />
        </FormGroup>
        <FormGroup>
          <Label>Afficher en postop</Label>
          <Checkbox
            name="postopVisible"
            label="Oui"
            id="postopVisible"
            checked={this.props.postopVisible}
          />
        </FormGroup>
      </EditButtonModal>
    );
  }
}
