import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, FormGroup, Badge, Alert} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import CreateOperatingMethodForm from './createOperatingMethodForm/CreateOperatingMethodForm';

import AmbulisProtocolsApi from 'clinic/apis/ambulisProtocols.api';
import OperatingMethodsApi from 'clinic/apis/operatingMethods.api';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import EditOperatingMethodForm from './editOperatingMethodForm/EditOperatingMethodForm';

export default class OperatingMethodsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    ambulisProtocols: null,
    operatingMethods: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des protocoles',
      activeMenuKey: 'ambulisOperatingMethods',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        operatingMethods: null,
        ambulisProtocols: null,
      },
      async () => {
        let nextState = {isLoading: false};

        let data = await Promise.all([
          OperatingMethodsApi.getAll(),
          AmbulisProtocolsApi.getAll(),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.operatingMethods = data[0];
          nextState.ambulisProtocols = data[1];
        }

        this.setState(nextState);
      },
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await OperatingMethodsApi.add(formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('Le protocole a été créé', null);
    this.loadData();
    return Promise.resolve();
  };

  onEdit = async (id, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await OperatingMethodsApi.edit(id, formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('Le protocole a été modifié', null);
    this.loadData();
    return Promise.resolve();
  };

  onDelete = async (operatingMethod) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await OperatingMethodsApi.delete(operatingMethod.ambulisId).catch((e) => {
      error = e;
      ApiErrorsHelper.manage(e);
    });

    if (!error) {
      NotificationManager.success('Le protocole a été supprimé', null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>protocoles</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des protocoles">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.operatingMethods) return null;

    const ambulisProtocolsNotCreated = this.state.ambulisProtocols.filter(
      (p) =>
        this.state.operatingMethods.find((o) => o.ambulisId === p.id) == null,
    );

    return (
      <div className="preOpInstructionsPage">
        <FormGroup className="text-end">
          <CreateButtonModal text="Nouveau protocole" onSubmit={this.onCreate}>
            <CreateOperatingMethodForm />
          </CreateButtonModal>
        </FormGroup>

        {ambulisProtocolsNotCreated.length > 0 && (
          <Alert color="danger">
            Les protocoles suivants ne sont pas créés :
            <ul>
              {ambulisProtocolsNotCreated.map((p) => (
                <li key={p.id}>{p.name}</li>
              ))}
            </ul>
          </Alert>
        )}

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Protocole</th>
              <th>Consignes preop</th>
              <th>Consignes postop</th>
              <th>Heure de convocation</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.operatingMethods.map((operatingMethod) => {
              const ambulisProtocol = this.state.ambulisProtocols.find(
                (p) => p.id === operatingMethod.ambulisId,
              );

              return (
                <tr key={operatingMethod.ambulisId}>
                  <td>
                    {ambulisProtocol ? ambulisProtocol.name : ''}
                    {!ambulisProtocol && (
                      <Badge className="ms-2" color="danger">
                        Supprimé
                      </Badge>
                    )}
                  </td>
                  <td>{operatingMethod.preopInstructions.title}</td>
                  <td>
                    {operatingMethod.postopInstructions &&
                      operatingMethod.postopInstructions.title}
                  </td>
                  <td className="text-center">
                    <Badge
                      color={
                        operatingMethod.displayConvocationDate
                          ? 'success'
                          : 'secondary'
                      }>
                      {operatingMethod.displayConvocationDate ? 'Oui' : 'Non'}
                    </Badge>
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonModal
                      title="Modifier un protocole"
                      data={operatingMethod.ambulisId}
                      onSubmit={this.onEdit}
                      className="me-1">
                      <EditOperatingMethodForm
                        operatingMethod={operatingMethod}
                      />
                    </EditButtonModal>
                    <DeleteButton
                      data={operatingMethod}
                      title="Supprimer ce protocole"
                      onConfirm={this.onDelete}>
                      Voulez-vous réellement supprimer ce protocole ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
