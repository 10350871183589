import React from "react";
import { PropTypes } from "prop-types";
import { FormGroup, Label } from "reactstrap";
import Input from "sharedComponents/input/Input";

export default class TreatmentModeForm extends React.PureComponent {
  static propTypes = {
    treatmentMode: PropTypes.shape({
      mdtCode: PropTypes.string,
      name: PropTypes.string,
    }),
    editMDT: PropTypes.bool,
  };

  static defaultProps = {
    treatmentMode: {
      mdtCode: "",
      name: "",
    },
    editMDT: false,
  };

  render() {
    const { editMDT, treatmentMode } = this.props;

    return (
      <div className="userForm">
        {editMDT && (
          <FormGroup className="required">
            <Label>Code MDT</Label>
            <Input name="mdtCode" value={treatmentMode?.mdtCode ?? ""} />
          </FormGroup>
        )}
        <FormGroup className="required">
          <Label>Nom</Label>
          <Input name="name" value={treatmentMode?.name ?? ""} />
        </FormGroup>
      </div>
    );
  }
}
