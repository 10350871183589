import React from 'react';
import {PropTypes} from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Block.scss';

export default class Block extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    irreducible: PropTypes.bool,
    color: PropTypes.oneOf(['default', 'success', 'danger', 'warning']),
    reduced: PropTypes.bool,
  };

  static defaultProps = {
    irreducible: false,
    reduced: false,
  };

  state = {
    reduced: this.props.reduced,
    color: 'default', // white
  };

  toggleReduced = () => {
    if (!this.props.irreducible) {
      this.setState((prevState) => {
        return {reduced: !prevState.reduced};
      });
    }
  };

  render() {
    return (
      <div
        className={`block my-2 clearfix ${this.props.color} ${
          this.props.irreducible ? 'irreducible' : 'reducible'
        }`}>
        <div className="title py-3" onClick={this.toggleReduced}>
          {!this.props.irreducible && (
            <div className="float-end p-0 me-3">
              <FontAwesomeIcon
                size="xs"
                title={this.state.reduced ? `Agrandir` : 'Réduire'}
                icon={this.state.reduced ? 'chevron-down' : 'chevron-up'}
              />
            </div>
          )}
          {this.props.title && (
            <div className="blockTitle ms-3">{this.props.title}</div>
          )}
        </div>
        <div
          className={`blockContent container-fluid pb-3 ${
            this.state.reduced ? 'reduced' : ''
          }`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
