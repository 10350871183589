import React from "react";
import { PropTypes } from "prop-types";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StructuredContent from "sharedComponents/structuredContent/StructuredContent";

export default class PreviewTextArticleButton extends React.PureComponent {
  static propTypes = {
    article: PropTypes.object.isRequired,
  };

  state = {
    modalVisible: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return { modalVisible: !prevState.modalVisible };
    });

  render() {
    return (
      <span>
        <Button
          size="sm"
          color="primary"
          onClick={this.toggleModal}
          title="Visualiser"
        >
          <FontAwesomeIcon icon="eye" />
        </Button>
        <Modal isOpen={this.state.modalVisible} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            {this.props.article.title}
          </ModalHeader>
          <ModalBody>
            <StructuredContent
              blocks={this.props.article.structuredContent?.blocks}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={this.toggleModal}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}
