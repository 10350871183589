import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import { Row, Col } from "reactstrap";
import DeleteButton from "sharedComponents/buttons/deleteButton/DeleteButton";
import EditButtonModal from "sharedComponents/buttons/editButtonModal/EditButtonModal";
import React from "react";

import ImageBlockEditor from "./imageBlock/ImageBlockEditor";
import ImageBlockRenderer from "./imageBlock/ImageBlockRenderer";
import QuoteBlockEditor from "./quoteBlock/QuoteBlockEditor";
import QuoteBlockRenderer from "./quoteBlock/QuoteBlockRenderer";
import SubtitleBlockEditor from "./subtitleBlock/SubtitleBlockEditor";
import SubtitleBlockRenderer from "./subtitleBlock/SubtitleBlockRenderer";
import TextBlockEditor from "./textBlock/TextBlockEditor";
import TextBlockRenderer from "./textBlock/TextBlockRenderer";
import TitleBlockEditor from "./titleBlock/TitleBlockEditor";
import TitleBlockRenderer from "./titleBlock/TitleBlockRenderer";

import "./StructuredContentRenderer.scss";

export default class StructuredContentRenderer extends React.PureComponent {
  static propTypes = {
    block: PropTypes.shape({
      order: PropTypes.number.isRequired,
      type: PropTypes.oneOf(["title", "subtitle", "text", "quote", "image"])
        .isRequired
    }),
    editable: PropTypes.bool,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
  };

  onEdit = (block, formData) => {
    let editedBlock = { ...block, data: { ...formData } };
    return this.props.onEdit(editedBlock);
  };

  onRemove = () => {
    this.props.onRemove(this.props.block);
  };

  render() {
    let blockRenderer = null;
    let blockEditor = null;
    switch (this.props.block.type) {
      case "title":
        blockRenderer = blockRenderer = (
          <TitleBlockRenderer
            data={this.props.block.data}
            trunk={this.props.editable}
          />
        );
        blockEditor = <TitleBlockEditor data={this.props.block.data} />;
        break;
      case "subtitle":
        blockRenderer = blockRenderer = (
          <SubtitleBlockRenderer
            data={this.props.block.data}
            trunk={this.props.editable}
          />
        );
        blockEditor = <SubtitleBlockEditor data={this.props.block.data} />;
        break;
      case "text":
        blockRenderer = (
          <TextBlockRenderer
            data={this.props.block.data}
            trunk={this.props.editable}
          />
        );
        blockEditor = <TextBlockEditor data={this.props.block.data} />;
        break;
      case "quote":
        blockRenderer = (
          <QuoteBlockRenderer
            data={this.props.block.data}
            trunk={this.props.editable}
          />
        );
        blockEditor = <QuoteBlockEditor data={this.props.block.data} />;
        break;
      case "image":
        blockRenderer = (
          <ImageBlockRenderer
            data={this.props.block.data}
            storagePath={this.props.storagePath}
          />
        );
        blockEditor = (
          <ImageBlockEditor
            storagePath={this.props.storagePath}
            data={this.props.block.data}
          />
        );
        break;
      default:
        blockRenderer = JSON.stringify(this.props.block.data);
        blockEditor = JSON.stringify(this.props.block.data);
        break;
    }

    return (
      <>
        <div
          className={`blockContainer align-items-start ${
            this.props.editable ? "editable" : ""
          }`}
        >
          <Row>
            {this.props.editable && (
              <Col xs="auto">
                <FontAwesomeIcon icon="arrows-alt" />
              </Col>
            )}
            <Col className="blockRendererContainer">{blockRenderer}</Col>
            {this.props.editable && (
              <Col className="actionButtons" xs="auto">
                <EditButtonModal
                  largeModal
                  data={this.props.block}
                  title="Modifier un élément"
                  onSubmit={this.onEdit}
                >
                  {blockEditor}
                </EditButtonModal>
                <DeleteButton
                  data={this.props.block}
                  title="Supprimer un élément"
                  onConfirm={this.onRemove}
                >
                  Voulez-vous réellement supprimer cet élément ?
                </DeleteButton>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}
