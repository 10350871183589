import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import {Table, FormGroup} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import React from 'react';

import WorkflowsApi from 'clinic/apis/workflows.api';

import CreateForm from './createForm/CreateForm';

export default class WorkflowsListPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    workflows: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des parcours',
      activeMenuKey: 'workflows',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, clinics: null},
      async () => {
        let nextState = {isLoading: false};

        nextState.workflows = await WorkflowsApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.workflows) {
          nextState.worflows = _.orderBy(nextState.workflows, 'name');
        }

        this.setState(nextState);
      },
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await WorkflowsApi.create(formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('Le parcours a été créé', null);
    this.loadData();
    return Promise.resolve(true);
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>parcours</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des parcours">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.workflows) return null;

    return (
      <div className="ClinicsPage">
        <FormGroup className="text-end">
          <CreateButtonModal text="Nouveau parcours" onSubmit={this.onCreate}>
            <CreateForm />
          </CreateButtonModal>
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.workflows.map((workflow) => {
              return (
                <tr key={workflow.id}>
                  <td>{workflow.name}</td>
                  <td className="width-auto text-center">
                    <EditButtonLink to={`/clinic/workflows/${workflow.id}`} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
