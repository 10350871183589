import _ from 'lodash';
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import React from 'react';
import StringHelper from 'helpers/StringHelper';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ClinicAdminsApi from 'admin/apis/clinicAdmins.api';
import ClinicsApi from 'admin/apis/clinics.api';

import ClinicAdminForm from '../clinicAdminForm/ClinicAdminForm';

export default class CreateClinicAdminPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    clinics: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Créer un admin clinique',
      activeMenuKey: 'clinicAdmins',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        clinics: null,
      },
      async () => {
        let nextState = {
          isLoading: false,
        };

        nextState.clinics = await ClinicsApi.getAll().catch(() => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    if (
      !formData.isEditorialAdmin &&
      !formData.isQoDAdmin &&
      !formData.isUsersAdmin &&
      !formData.isRoomReservationsAdmin
    ) {
      NotificationManager.error(
        "Veuillez attribuer au moins un droit à l'utilisateur",
        'Erreur',
      );

      return;
    }

    let cleanClinics = _.filter(
      formData.clinics,
      (c) => !StringHelper.isNullOrEmpty(c),
    );
    formData.clinics = cleanClinics;

    if (formData.clinics.length === 0) {
      NotificationManager.error(
        "Veuillez attribuer au moins une clinique à l'utilisateur",
        'Erreur',
      );
      return;
    }

    this.props.layoutContextProvider.showLoader();

    let error = null;
    await ClinicAdminsApi.create(formData).catch((e) => {
      ApiErrorsHelper.manage(e);
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success("L'admin clinique a été créé", null);
      this.props.history.push('/admin/clinicAdmins');
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Créer un <strong>admin clinique</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
            <ClinicAdminForm clinics={this.state.clinics} />
            <div className="text-end">
              <Link
                to="/admin/clinicAdmins"
                className="btn btn-outline-primary"
                type="button">
                Retour à la liste
              </Link>
              <Button color="primary" type="submit" className="ms-2">
                Valider
              </Button>
            </div>
          </ValidationForm>
        </PageDataLoader>
      </Block>
    );
  }
}
