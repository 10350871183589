import React from 'react';
import {PropTypes} from 'prop-types';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';

import {Table, Badge} from 'reactstrap';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import WorkflowsApi from 'clinic/apis/workflows.api';

class ServicesTab extends React.PureComponent {
  static propTypes = {
    workflowId: PropTypes.number.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    services: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({isLoading: true, loadingError: false}, async () => {
      let nextState = {isLoading: false, services: null};

      nextState.services = await WorkflowsApi.getServices(
        this.props.workflowId,
      ).catch((error) => {
        nextState.loadingError = true;
        return null;
      });

      if (nextState.services) {
        nextState.services = nextState.services.map((s) => ({
          ...s,
          workingHours: s.workingHours
            ? s.workingHours.replace(/\|/g, '\n')
            : s.workingHours,
        }));
      }

      this.setState(nextState);
    });
  };

  onDelete = async (serviceId) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;

    await WorkflowsApi.deleteService(this.props.workflowId, serviceId).catch(
      (e) => {
        ApiErrorsHelper.manage(e);
        success = false;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Service supprimé', '');
      this.loadData();
    }
  };

  render() {
    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        loadingText="Chargement des services">
        {!this.state.isLoading && this.renderContent()}
      </PageDataLoader>
    );
  }

  renderContent = () => {
    const {workflowId} = this.props;

    return (
      <div className="servicesTab">
        <div className="text-end mb-2">
          <CreateButtonLink
            to={`/clinic/workflows/${workflowId}/services/add`}
            text="Nouveau service"
          />
        </div>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className="width-auto">Contact principal</th>
              <th className="width-auto">Affiché après questionnaire</th>
              <th className="width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.services.map((s) => (
              <tr key={`${workflowId}-${s.id}`}>
                <td>{s.name}</td>
                <td className="width-auto text-center">
                  <Badge color={s.mainContact ? 'success' : 'secondary'}>
                    {s.mainContact ? 'oui' : 'non'}
                  </Badge>
                </td>
                <td className="width-auto text-center">
                  <Badge
                    color={
                      s.displayedAfterAmbulisForm ? 'success' : 'secondary'
                    }>
                    {s.displayedAfterAmbulisForm ? 'oui' : 'non'}
                  </Badge>
                </td>
                <td className="width-auto text-center">
                  <EditButtonLink
                    to={`/clinic/workflows/${workflowId}/services/${s.id}`}
                    tooltip={`Modifier le service ${s.name}`}
                  />
                  <span className="ms-2">
                    <DeleteButton
                      data={s.id}
                      title={`Supprimer ${s.name}`}
                      tooltip={`Supprimer ${s.name}`}
                      onConfirm={this.onDelete}>
                      Voulez-vous réellement supprimer le service{' '}
                      <i>{s.name}</i> ?
                    </DeleteButton>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}

export default withLayoutContextProvider(ServicesTab);
