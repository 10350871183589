import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, FormGroup, Button} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import CreatePreOpInstructionForm from './createPreOpInstructionForm/CreatePreOpInstructionForm';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';

import preOpInstructionsApi from 'clinic/apis/preOpInstructions.api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class PreOpInstructionsListPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    preOpInstructions: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des consignes préop',
      activeMenuKey: 'preOpInstructions',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, preOpInstructions: null},
      async () => {
        let nextState = {isLoading: false};

        nextState.preOpInstructions = await preOpInstructionsApi
          .getAll()
          .catch((e) => {
            nextState.loadingError = true;
            return null;
          });

        this.setState(nextState);
      },
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let preOp = await preOpInstructionsApi.add(formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('La consigne a été créé', null);
    this.props.history.push(`preOpInstructions/${preOp.id}`);
    return Promise.resolve();
  };

  onDeletePreOpInstructions = async (preOpInstruction) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await preOpInstructionsApi.delete(preOpInstruction.id).catch((e) => {
      error = e;
      ApiErrorsHelper.manage(e);
    });

    if (!error) {
      NotificationManager.success('La consigne a été supprimée', null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  onDuplicate = async (preOpInstructionId) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let preOp = await preOpInstructionsApi
      .duplicate(preOpInstructionId)
      .catch((e) => {
        error = e;
      });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Consigne préop dupliquée avec succès', null);
      this.props.history.push(`preOpInstructions/${preOp.id}`);
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>consignes préop</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des consignes préop">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.preOpInstructions) return null;

    return (
      <div className="preOpInstructionsPage">
        <FormGroup className="text-end">
          <CreateButtonModal text="Nouvelle consigne" onSubmit={this.onCreate}>
            <CreatePreOpInstructionForm />
          </CreateButtonModal>
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.preOpInstructions.map((preOpInstruction) => {
              const webLink = `${process.env.REACT_APP_APP_WEBSITE_URL}/preopInstructions/${preOpInstruction.id}`;

              return (
                <tr key={preOpInstruction.id}>
                  <td>{preOpInstruction.title}</td>
                  <td className="width-auto text-center">
                    <a
                      href={webLink}
                      rel="noreferrer"
                      target="_blank"
                      className="btn btn-primary btn-sm"
                      title="Prévisualiser">
                      <FontAwesomeIcon icon="eye" />
                    </a>
                    <span className="mx-2">
                      <EditButtonLink
                        to={`/clinic/preOpInstructions/${preOpInstruction.id}`}
                      />
                    </span>
                    <Button
                      size="sm"
                      className="me-2"
                      color="warning"
                      onClick={() => {
                        this.onDuplicate(preOpInstruction.id);
                      }}>
                      <FontAwesomeIcon icon="copy" />
                    </Button>

                    <DeleteButton
                      data={preOpInstruction}
                      title="Supprimer cette consigne préopératoire"
                      onConfirm={this.onDeletePreOpInstructions}>
                      Voulez-vous réellement supprimer cette consigne{' '}
                      <i>"{preOpInstruction.title}"</i> ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
