import React from 'react';
import {PropTypes} from 'prop-types';

import moment from 'moment';
import validationErrors from 'services/ValidationErrors';

import {Label, FormGroup, Button} from 'reactstrap';
import Input from 'sharedComponents/input/Input';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import YoutubeButton from 'sharedComponents/youtubeButton/YoutubeButton';
import ContentDeeplinkEditor from 'sharedComponents/contentDeeplinkEditor/ContentDeeplinkEditor';
import DatePicker from 'sharedComponents/datePicker/DatePicker';

export default class CreateNewsButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    modalVisible: false,
    type: 'text',
    videoId: '',
    externalUrl: '',
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return {modalVisible: !prevState.modalVisible};
    });

  onVideoIdChange = (name, videoId) => this.setState({videoId: videoId});

  onExternalUrlChange = (name, externalUrl) =>
    this.setState({externalUrl: externalUrl});

  onSubmit = (formData) => {
    let data = {
      ...formData,
      type: this.state.type,
      displayStartDate: moment(formData.displayStartDate).format('YYYY-MM-DD'),
      displayEndDate: moment(formData.displayEndDate).format('YYYY-MM-DD'),
    };

    if (this.state.type !== 'video') {
      delete data.videoId;
    }

    return this.props.onSubmit(data);
  };

  selectTextNews = () => this.setState({type: 'text'});

  selectVideoNews = () => this.setState({type: 'video'});

  selectExternalNews = () => this.setState({type: 'external'});

  render() {
    return (
      <CreateButtonModal
        text="Nouvelle actualité"
        icon="plus-circle"
        onSubmit={this.onSubmit}>
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input
            type="text"
            name="title"
            required
            validations="maxLength:500"
            validationError={validationErrors.maxLength(500)}
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Résumé</Label>
          <Input
            type="text"
            name="summary"
            required
            validations="maxLength:500"
            validationError={validationErrors.maxLength(500)}
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Date de début de publication</Label>
          <DatePicker
            name="displayStartDate"
            id="displayStartDate"
            className="form-control"
          />
        </FormGroup>

        <FormGroup>
          <Label>Date de fin de publication</Label>
          <DatePicker
            name="displayEndDate"
            id="displayEndDate"
            className="form-control"
          />
        </FormGroup>

        <FormGroup className={this.state.type === 'text' ? '' : 'd-none'}>
          <Label>Lien profond</Label>
          <ContentDeeplinkEditor name="contentDeeplink" />
        </FormGroup>

        <FormGroup className={this.state.type === 'text' ? '' : 'd-none'}>
          <Label>Titre du lien profond</Label>
          <Input
            type="text"
            name="contentDeeplinkTitle"
            validations="maxLength:500"
            validationError={validationErrors.maxLength(500)}
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Type</Label>
          <div className="d-flex flex-row justify-content-around">
            <Button
              size="sm"
              block
              className="mx-1"
              color={this.state.type === 'text' ? 'primary' : 'light'}
              onClick={this.selectTextNews}>
              Texte
            </Button>
            <Button
              size="sm"
              block
              className="mx-1"
              color={this.state.type === 'video' ? 'primary' : 'light'}
              onClick={this.selectVideoNews}>
              Vidéo
            </Button>
            <Button
              size="sm"
              block
              className="mx-1"
              color={this.state.type === 'external' ? 'primary' : 'light'}
              onClick={this.selectExternalNews}>
              Lien externe
            </Button>
          </div>
        </FormGroup>

        <FormGroup className="required">
          <Label>Petite image (aperçu sur la liste des actualités)</Label>
          <ImageUploader
            name="smallPictureFile"
            helpText="Taille attendue : 417px par 417px"
            required
          />
        </FormGroup>

        <div className={this.state.type === 'text' ? '' : 'd-none'}>
          <FormGroup className="required">
            <Label>Grande image (entête de l'actualité)</Label>
            <ImageUploader
              name="largePictureFile"
              helpText="Taille attendue : 1125px par 788px"
              required={this.state.type === 'text'}
            />
          </FormGroup>
        </div>

        <div className={this.state.type === 'video' ? '' : 'd-none'}>
          <FormGroup className="required">
            <Label>ID YouTube de la vidéo</Label>
            <div className="d-flex flex-row align-items-center">
              <Input
                type="text"
                name="videoId"
                required={this.state.type === 'video'}
                onChange={this.onVideoIdChange}
              />

              <div className="ms-2">
                <YoutubeButton videoId={this.state.videoId} />
              </div>
            </div>
          </FormGroup>
        </div>

        <div className={this.state.type === 'external' ? '' : 'd-none'}>
          <FormGroup className="required">
            <Label>Lien externe</Label>
            <div className="d-flex flex-row align-items-center">
              <Input
                name="externalUrl"
                required={this.state.type === 'external'}
                onChange={this.onExternalUrlhange}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>
        </div>

        <FormGroup>
          <Label>Version minimale de l'app</Label>
          <Input
            type="text"
            name="minimalAppVersion"
            helpText="Format attendu : X.Y.Z"
            validations="maxLength:500"
            validationError={validationErrors.maxLength(500)}
          />
        </FormGroup>
      </CreateButtonModal>
    );
  }
}
