import React from "react";
import { PropTypes } from "prop-types";

import { Label, FormGroup } from "reactstrap";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";
import Input from "sharedComponents/input/Input";

export default class CreateMapButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    modalVisible: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return { modalVisible: !prevState.modalVisible };
    });

  onSubmit = (formData) => {
    let data = { ...formData };

    return this.props.onSubmit(data);
  };

  render() {
    return (
      <CreateButtonModal
        text="Nouvelle carte interactive"
        icon="plus-circle"
        onSubmit={this.onSubmit}
      >
        <FormGroup>
          <Label>Titre</Label>
          <Input type="text" name="title" required />
        </FormGroup>

        <FormGroup>
          <Label>Code interne</Label>
          <Input type="text" name="internalCode" required />
        </FormGroup>

        <FormGroup>
          <Label>Visuel marqueur</Label>
          <ImageUploader
            name="placeImageFile"
            helpText="Taille attendue : 1125px par 788px"
            required
          />
        </FormGroup>
      </CreateButtonModal>
    );
  }
}
