import React from 'react';

import moment from 'moment';
import _ from 'lodash';

import {Row, Col, FormGroup, Label, Button, Alert} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import DashboardContent from 'sharedPagesComponents/qod/dashboardContent/DashboardContent';
import DatePicker from 'sharedComponents/datePicker/DatePicker';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import Select from 'sharedComponents/select/Select';

import QoDApi from 'admin/apis/qod.api';
import ClinicsApi from 'admin/apis/clinics.api';

export default class DashboardPage extends React.PureComponent {
  state = {
    isLoading: false,
    loadingError: false,
    clinics: [],
    errorsDescriptions: [],

    isSearching: false,
    isSearched: false,
    searchingError: null,
    days: [],
  };

  constructor(props) {
    super(props);

    this.defaultStartDate = moment();
    this.defaultEndDate = moment().add(3, 'days');
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'QoD - Dashboard',
      activeMenuKey: 'qod_dashboard',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        errorsDescriptions: null,
        clinics: null,
      },
      async () => {
        let nextState = {isLoading: false};
        let data = await Promise.all([
          QoDApi.getErrorsDescriptions(),
          ClinicsApi.getAll(),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.errorsDescriptions = data[0];
          nextState.clinics = _.sortBy(data[1], 'longName');
        }

        this.setState(nextState);
      },
    );
  };

  onSubmit = (formData) => {
    this.setState(
      {
        isSearched: false,
        isSearching: true,
        searchingError: null,
        days: null,
      },
      async () => {
        let nextState = {isSearching: false, isSearched: true};

        let data = await QoDApi.dashboard(
          formData.clinicCode,
          formData.startDate,
          formData.endDate,
        ).catch((e) => {
          nextState.searchingError = e;
          return null;
        });

        if (data) {
          nextState.days = data.sort((a, b) => {
            var dateA = moment(a.date, 'YYYY-MM-DD');
            var dateB = moment(b.date, 'YYYY-MM-DD');

            return dateA.isAfter(dateB) ? 1 : -1;
          });
        }

        this.setState(nextState);
      },
    );
  };

  loadDetails = async (medicalStay) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let data = await QoDApi.getLogsFromMedicalStay(
      medicalStay.clinicCode,
      medicalStay.medicalStayGapId,
    ).catch((e) => {
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      return Promise.resolve(data);
    }
  };

  render() {
    const clinicSelectOptions = this.state.clinics
      ? this.state.clinics.map((c) => ({
          label: `${c.shortName} - ${c.name}`,
          value: c.shortName,
        }))
      : [];

    return (
      <div className="qodDashboardPage">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des données"
          useBlock>
          {this.state.clinics && (
            <>
              <Block
                title={
                  <span>
                    Filtres de <strong>Recherche</strong>
                  </span>
                }>
                <ValidationForm onSubmit={this.onSubmit}>
                  <Row className="align-items-start">
                    <Col sm={4}>
                      <Label>Clinique</Label>
                      <FormGroup>
                        <Select
                          id="clinicCode"
                          name="clinicCode"
                          required
                          searchable
                          options={clinicSelectOptions}
                          value=""
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="auto">
                      <FormGroup>
                        <Label>Du</Label>{' '}
                        <DatePicker
                          id="startDate"
                          name="startDate"
                          className="form-control"
                          value={this.defaultStartDate}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="auto">
                      <FormGroup>
                        <Label>Au</Label>{' '}
                        <DatePicker
                          id="endDate"
                          name="endDate"
                          className="form-control"
                          value={this.defaultEndDate}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button color="primary" type="submit">
                    Rechercher
                  </Button>
                </ValidationForm>
              </Block>

              <PageDataLoader
                isLoading={this.state.isSearching}
                loadingError={this.state.searchingError}
                loadingText="Chargement des données"
                useBlock>
                {!this.state.isSearched && (
                  <Alert color="info">
                    Sélectionnez un établissement, une période et lancer la
                    recherche.
                  </Alert>
                )}

                {this.state.isSearched && this.state.days.length === 0 && (
                  <Alert color="info">Aucune donnée pour cette période.</Alert>
                )}

                <DashboardContent
                  days={this.state.days}
                  errorsDescriptions={this.state.errorsDescriptions}
                  onLoadDetails={this.loadDetails}
                />
              </PageDataLoader>
            </>
          )}
        </PageDataLoader>
      </div>
    );
  }
}
