import {Row, Col, FormGroup, Label, Button, Alert} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import DashboardContent from 'sharedPagesComponents/qod/dashboardContent/DashboardContent';
import DatePicker from 'sharedComponents/datePicker/DatePicker';
import moment from 'moment';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import React from 'react';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import QoDApi from 'clinic/apis/qod.api';

import './QoDDashboard.scss';
import Select from 'sharedComponents/select/Select';
import TreatmentModesApi from 'clinic/apis/treatmentModes.api';

export default class QoDDashboard extends React.PureComponent {
  state = {
    isLoading: false,
    isLoaded: false,
    treatmentModes: null,
    days: [],
    errorsDescriptions: [],
    loadingError: null,
  };

  constructor(props) {
    super(props);

    this.defaultStartDate = moment();
    this.defaultEndDate = moment().add(3, 'days');
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Dashboard QoD',
      activeMenuKey: 'qod_dashboard',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        treatmentModes: null,
      },
      async () => {
        let nextState = {
          isLoading: false,
        };

        nextState.treatmentModes = (
          await TreatmentModesApi.getAll().catch(() => [])
        ).map((t) => ({label: `${t.name} (${t.mdtCode})`, value: t.id}));

        this.setState(nextState, () => {
          this.onSubmit({
            startDate: this.defaultStartDate,
            endDate: this.defaultEndDate,
          });
        });
      },
    );
  };

  loadDetails = async (medicalStay) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let data = await QoDApi.getLogsFromMedicalStay(
      medicalStay.medicalStayGapId,
    ).catch((e) => {
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      return Promise.resolve(data);
    }
  };

  onSubmit = (formData) => {
    if (
      moment(formData.startDate)
        .startOf('day')
        .isSameOrBefore(moment(formData.endDate).startOf('day'))
    ) {
      this.setState(
        {
          isLoading: true,
          isLoaded: false,
          days: [],
          errorsDescriptions: [],
        },
        async () => {
          let nextState = {
            isLoading: false,
          };

          let data = await Promise.all([
            QoDApi.getErrorsDescriptions(),
            QoDApi.dashboard(
              formData.startDate,
              formData.endDate,
              formData.treatmentModeId,
            ),
          ]).catch((e) => {
            nextState.loadingError = e;
            return null;
          });

          if (data) {
            nextState.isLoaded = true;
            nextState.errorsDescriptions = data[0];
            nextState.days = data[1].sort((a, b) => {
              var dateA = moment(a.date, 'YYYY-MM-DD');
              var dateB = moment(b.date, 'YYYY-MM-DD');

              return dateA.isAfter(dateB) ? 1 : -1;
            });
          }

          this.setState(nextState);
        },
      );
    }
  };

  render() {
    return (
      <div className="qodDashboardPage">
        <Block
          title={
            <span>
              Filtres de <strong>Recherche</strong>
            </span>
          }>
          <ValidationForm onSubmit={this.onSubmit}>
            <Row className="align-items-end">
              <Col sm={4}>
                <FormGroup>
                  <Label>Du</Label>{' '}
                  <DatePicker
                    id="startDate"
                    name="startDate"
                    className="form-control"
                    value={this.defaultStartDate}
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Au</Label>{' '}
                  <DatePicker
                    id="endDate"
                    name="endDate"
                    className="form-control"
                    value={this.defaultEndDate}
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Code MDT</Label>
                  <Select
                    id="treatmentModeId"
                    name="treatmentModeId"
                    placeholder="Choisir un mode de traitement"
                    searchable
                    clearable
                    options={
                      this.state.treatmentModes ? this.state.treatmentModes : []
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button color="primary" type="submit">
              Filtrer
            </Button>
          </ValidationForm>
        </Block>

        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des données"
          useBlock>
          {this.state.isLoaded && this.state.days.length === 0 && (
            <Alert color="info">Aucune donnée pour cette période.</Alert>
          )}

          <DashboardContent
            days={this.state.days}
            errorsDescriptions={this.state.errorsDescriptions}
            onLoadDetails={this.loadDetails}
          />
        </PageDataLoader>
      </div>
    );
  }
}
