import React from 'react';

import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import validationErrors from 'services/ValidationErrors';
import StringHelper from 'helpers/StringHelper';

import {FormGroup, Label, Button} from 'reactstrap';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import Input from 'sharedComponents/input/Input';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Block from 'sharedComponents/block/Block';

import WorkflowsApi from 'clinic/apis/workflows.api';

export default class EditService extends React.PureComponent {
  state = {};

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.workflowId = +this.props.match.params.workflowId;
    this.serviceId = +this.props.match.params.serviceId;
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des parcours',
      activeMenuKey: 'workflows',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, service: null},
      async () => {
        let nextState = {isLoading: false};

        nextState.service = await WorkflowsApi.getService(
          this.workflowId,
          this.serviceId,
        ).catch((error) => {
          nextState.loadingError = error;
          return null;
        });

        if (nextState.service && nextState.service.workingHours) {
          nextState.service.workingHours =
            nextState.service.workingHours.replace(/\|/g, '\n');
        }

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await WorkflowsApi.editService(
      this.workflowId,
      this.serviceId,
      formData,
    ).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();
    if (success) {
      NotificationManager.success('Le service a été modifié', '');
      this.props.history.push(`/clinic/workflows/${this.workflowId}#services`);
    }
  };

  render() {
    return (
      <Block
        title={
          <span>
            Modification d'un <strong>service</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement de l'étape jour J"
          loadingError={this.state.loadingError}>
          {this.state.service && this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Nom</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="name"
              required
              value={this.state.service.name}
            />
          </div>
        </FormGroup>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Heures d'ouverture</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="workingHours"
              required
              type="textarea"
              value={this.state.service.workingHours}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Email de contact</Label>
          <div className="col-sm-10">
            <Input
              type="email"
              className="form-control"
              name="contactEmail"
              value={this.state.service.contactEmail}
              validations="isEmail"
              validationError={validationErrors.isEMail}
            />
          </div>
        </FormGroup>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Téléphone</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="contactPhoneNumber"
              required
              value={StringHelper.formatPhoneNumber(
                this.state.service.contactPhoneNumber,
              )}
              format={StringHelper.formatPhoneNumber}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Tarif</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="contactPhonePrice"
              value={this.state.service.contactPhonePrice}
              helpText="Format attendu : Service 0,20 € / min + prix appel"
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Téléphone Bis</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="contactPhoneNumberBis"
              value={StringHelper.formatPhoneNumber(
                this.state.service.contactPhoneNumberBis,
              )}
              format={StringHelper.formatPhoneNumber}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-form-label col-sm-2" htmlFor="contactWebsite">
            Website
          </Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="contactWebsite"
              id="contactWebsite"
              value={this.state.service.contactWebsite}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Contact principal</Label>
          <div className="col-sm-10">
            <Checkbox
              name="mainContact"
              id="mainContact"
              label=""
              checked={this.state.service.mainContact}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">
            Affiché en fin de questionnaire suivi
          </Label>
          <div className="col-sm-10">
            <Checkbox
              name="displayedAfterAmbulisForm"
              id="displayedAfterAmbulisForm"
              label=""
              checked={this.state.service.displayedAfterAmbulisForm}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Miniature</Label>
          <div className="col-sm-10">
            <ImageUploader
              name="smallPictureFile"
              id="smallPictureFile"
              helpText="Taille attendue : 464px par 234px"
              actualUrl={this.state.service.smallPictureUrl}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Photo</Label>
          <div className="col-sm-10">
            <ImageUploader
              name="largePictureFile"
              id="largePictureFile"
              helpText="Taille attendue : 1125px par 788px"
              actualUrl={this.state.service.largePictureUrl}
            />
          </div>
        </FormGroup>
        <FormGroup className="text-end">
          <Link
            to={`/clinic/workflows/${this.workflowId}#services`}
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  };
}
