import _ from 'lodash';
import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import CreatePageButton from './createPageButton/CreatePageButton';

import PagesApi from 'admin/apis/pages.api';

export default class PagesPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    pages: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des pages',
      activeMenuKey: 'pages',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, pages: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.pages = await PagesApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.pages) {
          nextState.pages = _.orderBy(nextState.pages, 'title');
        }

        this.setState(nextState);
      },
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    const createdPage = await PagesApi.create(formData).catch((e) => {
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('La page a été créée', null);
    this.props.history.push(`/admin/pages/${createdPage.id}`);
    return Promise.resolve();
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await PagesApi.delete(id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('La page a été supprimée.', null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>pages</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des pages">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.pages) return null;

    return (
      <div>
        <div className="text-end mb-2">
          <CreatePageButton onSubmit={this.onCreate} />
        </div>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th className="width-auto">Titre</th>
              <th>Code interne</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.pages.map((p) => {
              return (
                <tr key={`${p.id}`}>
                  <td className="width-auto">{p.title}</td>
                  <td className="truncate" style={{maxWidth: '200px'}}>
                    {p.internalCode}
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonLink to={`pages/${p.id}`} />
                    <DeleteButton
                      data={p.id}
                      title="Supprimer une page"
                      className="ms-1"
                      onConfirm={this.onDelete}>
                      Voulez-vous réellement supprimer la page <i>{p.title}</i>{' '}
                      ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
