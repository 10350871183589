import StringHelper from "helpers/StringHelper";

export default class Authentication {
  static storageKey = "adelEditorialBOAdmin";
  static token = null;
  static refreshToken = null;
  static user = null;

  static loadFromStorage = () => {
    let json = null;
    const item = localStorage.getItem(Authentication.storageKey);

    if (item) {
      try {
        json = JSON.parse(item);

        // check structure & clean if altered
        if (
          StringHelper.isNullOrEmpty(json.token) ||
          StringHelper.isNullOrEmpty(json.token) ||
          !json.user
        ) {
          localStorage.setItem(Authentication.storageKey, null);
          return null;
        }

        Authentication.token = json.token;
        Authentication.refreshToken = json.refreshToken;
        Authentication.user = json.user;
      } catch (e) {}
    }

    return json;
  };

  static isLogged = () => {
    if (
      !Authentication.user ||
      !StringHelper.isNullOrEmpty(Authentication.token)
    ) {
      let json = Authentication.loadFromStorage();
      return json && !StringHelper.isNullOrEmpty(json.token);
    }

    return true;
  };

  static login = (token, refreshToken, user) => {
    let data = {
      token: token,
      refreshToken: refreshToken,
      user: user
    };

    Authentication.token = token;
    Authentication.refreshToken = refreshToken;
    Authentication.user = user;

    localStorage.setItem(Authentication.storageKey, JSON.stringify(data));
  };

  static logout = () => {
    Authentication.token = null;
    Authentication.refreshToken = null;
    Authentication.user = null;

    localStorage.setItem(Authentication.storageKey, null);
  };
}
