import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, FormGroup} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import HealthFormsApi from 'admin/apis/healthForms.api';

export default class HealthFormsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    healthForms: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des questionnaires santé',
      activeMenuKey: 'healthFolders_forms',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, healthForms: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.healthForms = await HealthFormsApi.getAll(true).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.healthForms) {
          nextState.healthForms = _.orderBy(nextState.healthForms, 'title');
        }

        this.setState(nextState);
      },
    );
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await HealthFormsApi.delete(id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    if (success) {
      NotificationManager.success('Questionnaire supprimé avec succès', null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>questionnaires santé</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des cliniques">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.healthForms) return null;

    return (
      <div>
        <FormGroup className="text-end">
          <CreateButtonLink
            to="/admin/healthFolders/forms/add"
            text="Nouveau questionnaire"
          />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="text-center width-auto">Type</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.healthForms.map((form) => {
              const canBeDeleted = !form.folders || form.folders.length === 0;

              return (
                <tr key={form.id}>
                  <td>{form.title}</td>
                  <td className="width-auto text-center">
                    {form.type === 'internal' ? 'Interne' : 'Externe'}
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonLink
                      to={`/admin/healthFolders/forms/${form.id}`}
                    />

                    <DeleteButton
                      title={
                        <span>
                          Supprimer <i>{form.title}</i>
                        </span>
                      }
                      data={form.id}
                      onConfirm={this.onDelete}
                      className="ms-1"
                      tooltip={
                        canBeDeleted
                          ? `Supprimer ${form.title}`
                          : 'Impossible de supprimer ce questionnaire car il est proposé dans un dossier santé.'
                      }
                      disabled={!canBeDeleted}>
                      Voulez-vous réellement supprimer le questionnaire{' '}
                      <i>{form.title}</i> ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
