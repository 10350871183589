import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {Link, withRouter} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';
import validationErrors from 'services/ValidationErrors';

import {FormGroup, Button, Label} from 'reactstrap';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import Input from 'sharedComponents/input/Input';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import ContentDeeplinkEditor from 'sharedComponents/contentDeeplinkEditor/ContentDeeplinkEditor';
import YoutubeButton from 'sharedComponents/youtubeButton/YoutubeButton';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import DatePicker from 'sharedComponents/datePicker/DatePicker';

import NewsApi from 'admin/apis/news.api';

class GeneralTab extends React.PureComponent {
  static props = {
    news: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['text', 'video', 'external']).isRequired,
      smallPictureUrl: PropTypes.string.isRequired,
      contentDeeplink: PropTypes.object,
      contentDeeplinkTitle: PropTypes.string,
      largePictureUrl: PropTypes.string,
      videoProvider: PropTypes.string,
      videoId: PropTypes.string,
    }),
  };

  state = {
    videoId: this.props.news.videoId,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onVideoIdChange = (name, videoId) => this.setState({videoId: videoId});

  onSubmit = async (formData) => {
    let data = {
      ...formData,
      displayStartDate: moment(formData.displayStartDate).format('YYYY-MM-DD'),
      displayEndDate: formData.displayEndDate
        ? moment(formData.displayEndDate).format('YYYY-MM-DD')
        : null,
    };

    if (!data.smallPictureFile) {
      delete data.smallPictureFile;
    }

    if (!data.largePictureFile) {
      delete data.largePictureFile;
    }

    this.props.layoutContextProvider.showLoader();

    let error = null;

    await NewsApi.editGeneral(this.props.news.id, data).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    if (!error) {
      NotificationManager.success("L'actualité a été modifié", null);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  renderType(type) {
    switch (type) {
      case 'video':
        return 'Vidéo';
      case 'external':
        return 'Lien externe';
      default:
        return 'Texte';
    }
  }

  render() {
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Titre</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="title"
              required
              value={this.props.news.title}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Résumé</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="summary"
              required
              value={this.props.news.summary}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Type</Label>
          <div className="col-sm-10">
            {this.renderType(this.props.news.type)}
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">
            Date de début de publication
          </Label>
          <div className="col-sm-10">
            <DatePicker
              name="displayStartDate"
              id="displayStartDate"
              className="form-control"
              value={this.props.news.displayStartDate}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">
            Date de fin de publication
          </Label>
          <div className="col-sm-10">
            <DatePicker
              name="displayEndDate"
              id="displayEndDate"
              className="form-control"
              value={this.props.news.displayEndDate}
            />
          </div>
        </FormGroup>

        {this.props.news.type === 'text' && (
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Lien profond</Label>
            <div className="col-sm-10">
              <ContentDeeplinkEditor
                name="contentDeeplink"
                contentDeeplink={this.props.news.contentDeeplink}
              />
            </div>
          </FormGroup>
        )}

        {this.props.news.type === 'text' && (
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              Titre du lien profond
            </Label>
            <div className="col-sm-10">
              <Input
                type="text"
                name="contentDeeplinkTitle"
                value={this.props.news.contentDeeplinkTitle}
                validations="maxLength:500"
                validationError={validationErrors.maxLength(500)}
              />
            </div>
          </FormGroup>
        )}

        {this.props.news.type === 'external' && (
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Lien externe</Label>
            <div className="col-sm-10">
              <Input
                name="externalUrl"
                value={this.props.news.externalUrl}
                required
              />
            </div>
          </FormGroup>
        )}

        {this.props.news.type === 'video' && (
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              ID YouTube de la vidéo
            </Label>
            <div className="d-flex flex-row align-items-center col-sm-10">
              <Input
                required
                type="text"
                name="videoId"
                value={this.props.news.videoId}
                onChange={this.onVideoIdChange}
              />
              <div className="ms-2">
                <YoutubeButton videoId={this.state.videoId} />
              </div>
            </div>
          </FormGroup>
        )}

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">
            Petite image (aperçu sur la liste des actualités)
          </Label>
          <div className="col-sm-10">
            <ImageUploader
              name="smallPictureFile"
              actualUrl={this.props.news.smallPictureUrl}
              helpText="Taille attendue : 417px par 417px"
            />
          </div>
        </FormGroup>

        {this.props.news.type === 'text' && (
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              Grande image (entête de l'actualité)
            </Label>
            <div className="col-sm-10">
              <ImageUploader
                name="largePictureFile"
                actualUrl={this.props.news.largePictureUrl}
                helpText="Taille attendue : 1125px par 788px"
              />
            </div>
          </FormGroup>
        )}

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">
            Version minimale de l'app
          </Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="minimalAppVersion"
              helpText="Format attendu : X.Y.Z"
              value={this.props.news.minimalAppVersion}
              validations="maxLength:500"
              validationError={validationErrors.maxLength(500)}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label for="published" className="col-sm-2">
            Publié
          </Label>
          <div className="col-sm-10">
            <Checkbox
              id="published"
              name="published"
              checked={this.props.news.published}
              label=""
            />
          </div>
        </FormGroup>

        <FormGroup className="text-end">
          <Link
            to="/admin/news"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(withRouter(GeneralTab));
