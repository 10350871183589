import ApiClient from "admin/services/ApiClient";

export default class ReleaseNotesApi {
  static getAll = () => ApiClient.get("releaseNotes");

  static create = (formData) => ApiClient.post("releaseNotes", formData, true);

  static delete = (id) => ApiClient.delete(`releaseNotes/${id}`);

  static get = (id) => ApiClient.get(`releaseNotes/${id}`);

  static edit = (id, formData) =>
    ApiClient.post(`releaseNotes/${id}`, formData);
}
