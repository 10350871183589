import ApiClient from "clinic/services/ApiClient";

export default class ArticlesApi {
  static getAllSummaries = () => ApiClient.get("articles/summaries");

  static add = (data) => ApiClient.post("articles", data, true);

  static get = (id) => ApiClient.get(`articles/${id}`);

  static editGeneral = (id, data) =>
    ApiClient.post(`articles/${id}/general`, data, true);

  static delete = (id) => ApiClient.delete(`articles/${id}`);

  static getAllGroupSummaries = () => ApiClient.get("groupArticles/summaries");

  static getGroupArticle = (id) => ApiClient.get(`groupArticles/${id}`);

  static duplicateGroupArticle = (id) =>
    ApiClient.post(`groupArticles/${id}/duplicate`);

  static addBlock = (id, type, data) =>
    ApiClient.post(`articles/${id}/blocks/${type}`, data, true);

  static moveBlock = (articleId, blockId, data) =>
    ApiClient.patch(`articles/${articleId}/blocks/${blockId}/order`, data);

  static editBlock = (articleId, blockId, data) =>
    ApiClient.post(
      `articles/${articleId}/blocks/${blockId}/content`,
      data,
      true
    );

  static deleteBlock = (articleId, blockId) =>
    ApiClient.delete(`articles/${articleId}/blocks/${blockId}`);
}
