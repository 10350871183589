import React from 'react';
import {PropTypes} from 'prop-types';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Link} from 'react-router-dom';
import {Button, FormGroup, Label} from 'reactstrap';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ClinicsApi from 'admin/apis/clinics.api';

export default class NotificationsTab extends React.PureComponent {
  static propTypes = {
    clinicId: PropTypes.string.isRequired,
    layoutContextProvider: PropTypes.object.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    clinic: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        clinic: null,
      },
      async () => {
        let nextState = {isLoading: false};

        nextState.clinic = await ClinicsApi.get(this.props.clinicId).catch(
          (e) => {
            nextState.loadingError = true;
            return null;
          },
        );

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;

    await ClinicsApi.editNotifications(this.props.clinicId, formData).catch(
      (error) => {
        ApiErrorsHelper.manage(error, this.form);

        success = false;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Modification effectuée.', null);
    }
  };

  render() {
    return (
      <div className="treatmentModesTab">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement des notifications"
          loadingError={this.state.loadingError}>
          {this.state.clinic && this.renderContent()}
        </PageDataLoader>
      </div>
    );
  }

  renderContent = () => {
    const clinic = this.state.clinic;
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row">
          <Label for="notificationLibherosPre3" className="col-sm-2">
            Libheros J-3
          </Label>
          <div className="col-sm-10">
            <Checkbox
              id="notificationLibherosPre3"
              name="notificationLibherosPre3"
              checked={clinic.notificationLibherosPre3}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label for="notificationLibherosPost0" className="col-sm-2">
            Libheros J0
          </Label>
          <div className="col-sm-10">
            <Checkbox
              id="notificationLibherosPost0"
              name="notificationLibherosPost0"
              checked={clinic.notificationLibherosPost0}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label for="notificationMonAmbulancePre1" className="col-sm-2">
            Mon Ambulance J-1
          </Label>
          <div className="col-sm-10">
            <Checkbox
              id="notificationMonAmbulancePre1"
              name="notificationMonAmbulancePre1"
              checked={clinic.notificationMonAmbulancePre1}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label for="notificationMonAmbulancePost1" className="col-sm-2">
            Mon Ambulance J+1
          </Label>
          <div className="col-sm-10">
            <Checkbox
              id="notificationMonAmbulancePost1"
              name="notificationMonAmbulancePost1"
              checked={clinic.notificationMonAmbulancePost1}
            />
          </div>
        </FormGroup>

        <FormGroup className="text-end">
          <Link
            to="/admin/clinics"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  };
}
