import React from "react";

import classnames from "classnames";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Block from "sharedComponents/block/Block";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import GeneralTab from "./generalTab/GeneralTab";
import InformationsTab from "./informationsTab/InformationsTab";
import FormsTab from "./formsTab/FormsTab";

import HealthFoldersApi from "admin/apis/healthFolders.api";

export default class EditHealthFoldersPage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : "general",

    isLoading: true,
    loadingError: false,
    healthFolder: null,
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: "Gestion des dossiers santé",
      activeMenuKey: "healthFolders_folders",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, articles: null },
      async () => {
        let nextState = { isLoading: false };
        nextState.healthFolder = await HealthFoldersApi.get(this.id).catch(
          (e) => {
            nextState.loadingError = true;
            return null;
          }
        );

        this.setState(nextState);
      }
    );
  };

  goToGeneralTab = () => {
    this.setState({ activeTab: "general" });
    this.props.history.replace("#general");
  };

  goToInformationsTab = () => {
    this.setState({ activeTab: "informations" });
    this.props.history.replace("#informations");
  };

  goToFormsTab = () => {
    this.setState({ activeTab: "forms" });
    this.props.history.replace("#forms");
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier un <strong>dossier santé</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement du dossier santé"
        >
          {this.state.healthFolder && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "general",
                    })}
                    onClick={this.goToGeneralTab}
                  >
                    Général
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "informations",
                    })}
                    onClick={this.goToInformationsTab}
                  >
                    Informations
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "forms",
                    })}
                    onClick={this.goToFormsTab}
                  >
                    Suivi santé
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="general">
                  <GeneralTab healthFolder={this.state.healthFolder} />
                </TabPane>
                <TabPane tabId="informations">
                  <InformationsTab healthFolder={this.state.healthFolder} />
                </TabPane>
                <TabPane tabId="forms">
                  <FormsTab healthFolder={this.state.healthFolder} />
                </TabPane>
              </TabContent>
            </>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
