import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";

import { Table } from "reactstrap";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";

import ClinicsApi from "admin/apis/clinics.api";

export default class ProtocolsTab extends React.PureComponent {
  static propTypes = {
    clinicId: PropTypes.string.isRequired,
    layoutContextProvider: PropTypes.object.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    protocols: null,
  };

  componentDidMount() {
    this.loadProtocols();
  }

  loadProtocols = () => {
    this.setState(
      { isLoading: true, loadingError: false, protocols: null },
      async () => {
        let nextState = { isLoading: false };

        nextState.protocols = await ClinicsApi.getProtocols(
          this.props.clinicId
        ).catch((error) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.protocols) {
          nextState.protocols = _.sortBy(nextState.protocols, "id");
        }

        this.setState(nextState);
      }
    );
  };

  deleteProtocol = async (id) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await ClinicsApi.deleteProtocol(this.props.clinicId, id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Le protocole a été supprimé.", null);
      this.loadProtocols();
    }
  };

  render() {
    return (
      <div className="protocolsTab">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement des protocoles"
          loadingError={this.state.loadingError}
        >
          {this.state.protocols && this.renderContent()}
        </PageDataLoader>
      </div>
    );
  }

  renderContent = () => {
    return (
      <div className="protocolsTab">
        <Table responsive hover striped>
          <thead>
            <tr>
              <th className="text-center width-auto">#</th>
              <th>Nom</th>
            </tr>
          </thead>
          <tbody>
            {this.state.protocols.map((protocol) => {
              return (
                <tr key={protocol.id}>
                  <td className="width-auto text-center">{protocol.id}</td>
                  <td>{protocol.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
