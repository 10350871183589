export default class StringHelper {
  static isNullOrEmpty = text => {
    return !text || typeof text !== "string" || text.trim() === "";
  };

  static formatPhoneNumber = phoneNumber => {
    return StringHelper.isNullOrEmpty(phoneNumber)
      ? phoneNumber
      : phoneNumber
          .replace(/\D/g, "")
          .replace(/(.{2})/g, "$1 ")
          .trim();
  };

  static random = length =>
    [...Array(length)].map(i => (~~(Math.random() * 36)).toString(36)).join("");
}
