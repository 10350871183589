import React from 'react';
import _ from 'lodash';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, FormGroup} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import EmergenciesApi from 'admin/apis/emergencies.api';

export default class EmergenciesPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    clinics: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des urgences et CSNP',
      activeMenuKey: 'emergencies',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, clinics: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.clinics = await EmergenciesApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.clinics) {
          nextState.clinics = _.orderBy(nextState.clinics, 'shortName');
        }

        this.setState(nextState);
      },
    );
  };

  deleteClinic = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await EmergenciesApi.delete(id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Etablissement supprimé avec succès', null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>établissements</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des cliniques">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.clinics) return null;

    return (
      <div className="ClinicsPage">
        <FormGroup className="text-end">
          <CreateButtonLink
            to="/admin/emergencies/add"
            text="Nouvel établissement"
          />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.clinics.map((clinic) => {
              return (
                <tr key={clinic.shortName}>
                  <td>{clinic.name}</td>
                  <td className="width-auto text-center">
                    <EditButtonLink to={`/admin/emergencies/${clinic.id}`} />
                    <DeleteButton
                      title={
                        <span>
                          Supprimer <i>{clinic.longName}</i>
                        </span>
                      }
                      data={clinic.id}
                      onConfirm={this.deleteClinic}
                      className="ms-1">
                      Voulez-vous réellement supprimer <i>{clinic.name}</i>
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
