import React from "react";

import classnames from "classnames";
import { NotificationManager } from "react-notifications";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Block from "sharedComponents/block/Block";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";

import GeneralTab from "./generalTab/GeneralTab";
import HealthFoldersTab from "./healthFoldersTab/HealthFoldersTab";
import PreventionChecksTab from "./preventionChecksTab/PreventionChecksTab";
import SelfAssessmentTestsTab from "./selfAssessmentTestsTab/SelfAssessmentTestsTab";

import HealthFoldersApi from "admin/apis/healthFolders.api";
import MapsApi from "admin/apis/maps.api";
import PreventionCategoriesApi from "admin/apis/preventionCategories.api";
import PreventionChecksApi from "admin/apis/preventionChecks.api";
import SelfAssessmentTestsApi from "admin/apis/selfAssessmentTestsApi";

export default class EditPreventionCategoriesPage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : "general",

    isLoading: true,
    loadingError: false,

    category: null,
    healthFolders: null,
    preventionChecks: null,
    selfAssessmentTests: null,
    maps: null,
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: "Gestion des catégories de prévention",
      activeMenuKey: "prevention_categories",
    });

    this.loadData(true);
  }

  loadData = (resetAll = false) => {
    this.setState(
      { isLoading: true, loadingError: false, category: null },
      async () => {
        let nextState = { isLoading: false };

        let requests = [
          PreventionCategoriesApi.getById(this.id, true, true, true),
        ];

        if (resetAll) {
          requests.push(HealthFoldersApi.getAll());
          requests.push(PreventionChecksApi.getAll());
          requests.push(SelfAssessmentTestsApi.getAll());
          requests.push(MapsApi.getAll());
        }

        const data = await Promise.all(requests).catch((e) => {
          nextState.loadingError = e;
          return null;
        });

        if (data) {
          nextState.category = data[0];

          if (resetAll) {
            nextState.healthFolders = data[1];
            nextState.preventionChecks = data[2];
            nextState.selfAssessmentTests = data[3];
            nextState.maps = data[4];
          }
        }

        this.setState(nextState);
      }
    );
  };

  goToTab = (tabName) => {
    this.setState({ activeTab: tabName });
    this.props.history.replace(`#${tabName}`);
  };

  goToGeneralTab = () => this.goToTab("general");
  goToHealthFoldersTab = () => this.goToTab("healthFolders");
  goToPreventionChecksTab = () => this.goToTab("preventionChecks");
  goToSelfAssessmentTestsTab = () => this.goToTab("selfAssessmentTests");

  onAddHealthFolder = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await PreventionCategoriesApi.addHealthFolder(this.id, id).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("Le dossier santé a été ajouté", null);
    this.loadData();
    return Promise.resolve();
  };

  onDeleteHealthFolder = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await PreventionCategoriesApi.deleteHealthFolder(this.id, id).catch((e) => {
      ApiErrorsHelper.manage(e);
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success("Le dossier santé a été enlevé", null);
      this.loadData();
      return Promise.resolve();
    }
  };

  onAddPreventionCheck = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await PreventionCategoriesApi.addPreventionCheck(this.id, id).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("Le contrôle de prévention a été ajouté", null);

    this.loadData();
    return Promise.resolve();
  };

  onDeletePreventionCheck = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await PreventionCategoriesApi.deletePreventionCheck(this.id, id).catch(
      (e) => {
        ApiErrorsHelper.manage(e);
        error = e;
      }
    );

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success(
        "Le contrôle de prévention a été enlevé",
        null
      );

      this.loadData();
      return Promise.resolve();
    }
  };

  onAddSelfAssessmentTest = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await PreventionCategoriesApi.addSelfAssessmentTest(this.id, id).catch(
      (e) => {
        error = e;
      }
    );

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("Le test d'auto-évaluation a été ajouté", null);
    this.loadData();
    return Promise.resolve();
  };

  onDeleteSelfAssessmentTest = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await PreventionCategoriesApi.deleteSelfAssessmentTest(this.id, id).catch(
      (e) => {
        ApiErrorsHelper.manage(e);
        error = e;
      }
    );

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success(
        "Le test d'auto-évaluation a été enlevé",
        null
      );
      this.loadData();
      return Promise.resolve();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong>catégorie de prévention</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de la catégorie de prévention"
        >
          {this.state.category && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "general",
                    })}
                    onClick={this.goToGeneralTab}
                  >
                    Général
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "healthFolders",
                    })}
                    onClick={this.goToHealthFoldersTab}
                  >
                    Dossiers santé
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "preventionChecks",
                    })}
                    onClick={this.goToPreventionChecksTab}
                  >
                    Contrôles de prévention
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "selfAssessmentTests",
                    })}
                    onClick={this.goToSelfAssessmentTestsTab}
                  >
                    Tests d'auto-évaluation
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="general">
                  <GeneralTab
                    category={this.state.category}
                    maps={this.state.maps}
                  />
                </TabPane>
                <TabPane tabId="healthFolders">
                  <HealthFoldersTab
                    categoryHealthFolders={this.state.category.healthFolders}
                    allHealthFolders={this.state.healthFolders}
                    onAdd={this.onAddHealthFolder}
                    onDelete={this.onDeleteHealthFolder}
                  />
                </TabPane>
                <TabPane tabId="preventionChecks">
                  <PreventionChecksTab
                    categoryPreventionChecks={
                      this.state.category.preventionChecks
                    }
                    allPreventionChecks={this.state.preventionChecks}
                    onAdd={this.onAddPreventionCheck}
                    onDelete={this.onDeletePreventionCheck}
                  />
                </TabPane>
                <TabPane tabId="selfAssessmentTests">
                  <SelfAssessmentTestsTab
                    categorySelfAssessmentTests={
                      this.state.category.selfAssessmentTests
                    }
                    allSelfAssessmentTests={this.state.selfAssessmentTests}
                    onAdd={this.onAddSelfAssessmentTest}
                    onDelete={this.onDeleteSelfAssessmentTest}
                  />
                </TabPane>
              </TabContent>
            </>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
