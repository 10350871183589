import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import classnames from 'classnames';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import React from 'react';

import WorkflowsApi from 'clinic/apis/workflows.api';

import ArticlesTab from './articlesTab/ArticlesTab';
import GeneralTab from './generalTab/GeneralTab';
import HospitalizationStepsTab from './hospitalizationStepsTab/HospitalizationStepsTab';
// import RoomTypesTab from "./roomTypesTab/RoomTypesTab";
import ServicesTab from './servicesTab/ServicesTab';

export default class EditWorkflowPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    workflow: null,
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : 'general',
  };

  constructor(props) {
    super(props);
    this.id = +this.props.match.params.id;
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des parcours',
      activeMenuKey: 'workflows',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, clinics: null},
      async () => {
        let nextState = {isLoading: false};

        nextState.workflow = await WorkflowsApi.get(this.id).catch((e) => {
          nextState.loadingError = e;
          return null;
        });

        if (nextState.workflow) {
          this.props.layoutContextProvider.change({
            title: `Gestion des parcours / ${nextState.workflow.name} `,
            activeMenuKey: 'workflows',
          });
        }

        this.setState(nextState);
      },
    );
  };

  changeTab = (tab) => {
    this.props.history.replace(`#${tab}`);
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modification d'un <strong>parcours</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement du parcours">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.workflow) return null;
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'general',
              })}
              onClick={() => {
                this.changeTab('general');
              }}>
              Général
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'hospitalizationSteps',
              })}
              onClick={() => {
                this.changeTab('hospitalizationSteps');
              }}>
              Parcours Jour J
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'articles',
              })}
              onClick={() => {
                this.changeTab('articles');
              }}>
              Articles
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'services',
              })}
              onClick={() => {
                this.changeTab('services');
              }}>
              Services
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "roomTypes",
              })}
              onClick={() => {
                this.changeTab("roomTypes");
              }}
            >
              Offres hôtelières
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="general">
            <GeneralTab workflow={this.state.workflow} reload={this.loadData}/>
          </TabPane>
          <TabPane tabId="hospitalizationSteps">
            <HospitalizationStepsTab workflowId={this.id} displayHospitalizationSteps={this.state.workflow.displayHospitalizationSteps} />
          </TabPane>
          <TabPane tabId="articles">
            <ArticlesTab workflowId={this.id} />
          </TabPane>
          <TabPane tabId="services">
            <ServicesTab workflowId={this.id} />
          </TabPane>
          {/* <TabPane tabId="roomTypes">
            <RoomTypesTab workflowId={this.id} />
          </TabPane> */}
        </TabContent>
      </>
    );
  };
}
