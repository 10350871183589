import React from 'react';
import {PropTypes} from 'prop-types';

import _ from 'lodash';

import {Alert, FormGroup, Label} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Input from 'sharedComponents/input/Input';
import Select from 'sharedComponents/select/Select';

export default class LinkSpecialitiesForm extends React.PureComponent {
  static propTypes = {
    externalSpeciality: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      externalId: PropTypes.number.isRequired,
    }),
    internalSpeciality: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      externalId: PropTypes.number,
    }),
    externalSpecialities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        isLinked: PropTypes.bool,
      }),
    ).isRequired,
  };

  state = {
    selectedExternalSpeciality: this.props.externalSpeciality,
  };

  onChangeExternalSpeciality = (speId) => {
    const spe = _.find(this.props.externalSpecialities, (s) => s.id === speId);
    this.setState({
      selectedExternalSpeciality: spe,
    });
  };

  externalSpeTemplate = (prop) => {
    return (
      <div>
        {prop.data.isLinked && (
          <span className="me-2">
            <FontAwesomeIcon icon="link" />
          </span>
        )}
        {prop.label}
      </div>
    );
  };

  render() {
    let options = this.props.externalSpecialities.map((spe) => ({
      label: spe.name,
      value: spe.id,
      data: spe,
    }));

    let conflict = false;
    if (this.state.selectedExternalSpeciality) {
      conflict = this.state.selectedExternalSpeciality.isLinked;

      if (conflict && this.props.externalSpeciality) {
        conflict =
          this.state.selectedExternalSpeciality.id !==
          this.props.externalSpeciality.id;
      }
    }

    return (
      <div className="specialitiesLinkForm">
        <FormGroup className="required">
          <Label>Nom</Label>
          <Input
            name="name"
            required
            value={this.props.internalSpeciality?.name}
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Specialité EasyPrat</Label>
          <Select
            id="externalSpecialityId"
            name="externalSpecialityId"
            placeholder="Choisir une spécialité"
            searchable
            options={options}
            optionsTemplate={this.externalSpeTemplate}
            value={this.props.externalSpeciality?.id}
            onChange={this.onChangeExternalSpeciality}
            required
          />
        </FormGroup>
        {conflict && (
          <Alert color="warning">
            La spécialité <i>{this.state.selectedExternalSpeciality.name}</i>{' '}
            est déjà liée à une spécialité interne.
          </Alert>
        )}
      </div>
    );
  }
}
