import React from 'react';
import PropTypes from 'prop-types';

import {Link, withRouter} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';
import ValidationErrors from 'services/ValidationErrors';

import {FormGroup, Button, Label} from 'reactstrap';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import PreOpInstructionsApi from 'clinic/apis/preOpInstructions.api';

class GeneralTab extends React.PureComponent {
  static props = {
    preOpInstruction: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func,
  };

  state = {};

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await PreOpInstructionsApi.updateGeneral(
      this.props.preOpInstruction.id,
      formData,
    ).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    if (!error) {
      NotificationManager.success('La consigne a été modifiée', null);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    const webLink = `${process.env.REACT_APP_APP_WEBSITE_URL}/preopInstructions/${this.props.preOpInstruction.id}`;

    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Titre</Label>
          <div className="col-sm-10">
            <Input
              name="title"
              value={this.props.preOpInstruction.title}
              maxLength={255}
              validations="maxLength:255"
              validationError={ValidationErrors.maxLength(255)}
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Lien web</Label>
          <div className="col-sm-10">
            <a href={webLink} target="_blank" rel="noreferrer">
              {webLink}
            </a>
          </div>
        </FormGroup>

        <FormGroup className="text-end">
          <Link
            to="/clinic/preOpInstructions"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(withRouter(GeneralTab));
