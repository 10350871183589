import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import validationErrors from 'services/ValidationErrors';

import {Link} from 'react-router-dom';
import {Button, FormGroup, Label} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import MapPlacesApi from 'admin/apis/mapPlaces.api';

export default class EditMapPlacePage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    place: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.mapId = +this.props.match.params.mapId;
    this.id = this.props.match.params.id;

    this.props.layoutContextProvider.change({
      title: 'Gestion des marqueurs',
      activeMenuKey: 'maps',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
      },
      async () => {
        let nextState = {isLoading: false};

        let place = await MapPlacesApi.getById(this.id).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (place) {
          nextState.place = place;
        }

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await MapPlacesApi.update(this.id, formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    if (!error) {
      NotificationManager.success('Le marqueur a été modifié', null);
      this.props.history.push(`/admin/maps/${this.mapId}#places`);
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        title={
          <span>
            Modification d'un <strong>marqueur</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des marqueurs">
          {this.state.place && (
            <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
              <FormGroup className="row required">
                <Label className="col-sm-2">Nom</Label>
                <div className="col-sm-10">
                  <Input name="name" value={this.state.place.name} required />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-sm-2">Numéro téléphone</Label>
                <div className="col-sm-10">
                  <Input
                    name="phoneNumber"
                    value={this.state.place.phoneNumber}
                    helpText="N° obligatoirement non surtaxé"
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">
                  Destinataire et/ou service
                </Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="addressLine2"
                    value={this.state.place.addressLine2}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Complément</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="addressLine3"
                    helpText="Entrée, Tour, Immeuble, Bâtiment, Résidence, Zone industrielle, ..."
                    value={this.state.place.addressLine3}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">N° et voie</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="addressLine4"
                    value={this.state.place.addressLine4}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">
                  Mentions spéciales
                </Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="addressLine5"
                    helpText="BP, Tri Service Arrivée, ..."
                    value={this.state.place.addressLine5}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">
                  Code postal et ville
                </Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="addressLine6"
                    value={this.state.place.addressLine6}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Pays</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="addressLine7"
                    value={this.state.place.addressLine7}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Latitude</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="latitude"
                    helpText="Utiliser un point comme séparateur décimal"
                    required
                    validations="isNumeric"
                    validationError={validationErrors.isNumeric}
                    value={this.state.place.latitude}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Longitude</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="longitude"
                    helpText="Utiliser un point comme séparateur décimal"
                    required
                    validations="isNumeric"
                    validationError={validationErrors.isNumeric}
                    value={this.state.place.longitude}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Site web</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="website"
                    value={this.state.place.website}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">
                  Adresse e-mail
                </Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="email"
                    value={this.state.place.email}
                    type="email"
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">
                  Lien prise rendez-vous
                </Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="bookingUrl"
                    value={this.state.place.bookingUrl}
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Description</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="description"
                    value={this.state.place.description}
                  />
                </div>
              </FormGroup>

              <FormGroup className="text-end">
                <Link
                  to={`/admin/maps/${this.mapId}#places`}
                  className="btn btn-outline-primary me-1"
                  type="button">
                  Retour à la liste
                </Link>
                <Button type="submit" color="primary">
                  Valider
                </Button>
              </FormGroup>
            </ValidationForm>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
