import { Button, FormGroup, Label, Alert } from "reactstrap";
import ApiClient from "admin/services/ApiClient";
import ApiErrorsHelper from "helpers/ApiErrorsHelper";
import AuthBlock from "sharedComponents/authBlock/AuthBlock";
import Authentication from "admin/services/Authentication";
import ElsanLogo from "sharedComponents/elsanLogo/ElsanLogo";
import Input from "sharedComponents/input/Input";
import React from "react";
import StringHelper from "helpers/StringHelper";
import validationErrors from "services/ValidationErrors";
import ValidationForm from "sharedComponents/validationForm/ValidationForm";

import "./Login.scss";

export default class Login extends React.PureComponent {
  state = {
    loginError: null,
    validationErrors: null
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({ title: "Connexion" });
  }

  onSubmit = async formData => {
    this.props.layoutContextProvider.showLoader();

    if (this.state.loginError) {
      this.setState({ loginError: null, validationErrors: null });
    }

    let response = await ApiClient.post("login", {
      email: formData.email,
      password: formData.password
    }).catch(e => {
      this.form.current.updateInputsWithError(
        ApiErrorsHelper.convertApiValidationError(e.raw)
      );

      this.setState({
        loginError: e.message
      });

      return null;
    });

    if (response) {
      Authentication.login(
        response.token,
        response.refreshToken,
        response.user
      );

      this.props.history.push("/admin/");
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <AuthBlock
        title="ADEL BO Éditorial"
        subtitle="Espace Administrateur"
        logo={<ElsanLogo />}
      >
        <ValidationForm
          ref={this.form}
          onSubmit={this.onSubmit}
          onChange={this.onFormChange}
        >
          <div className="m-4">
            <FormGroup>
              <Label>Adresse email</Label>
              <Input
                id="emailInput"
                type="email"
                name="email"
                placeholder="Adresse email"
                validations="isEmail"
                validationError={validationErrors.isEMail}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Mot de passe</Label>
              <Input
                id="passwordInput"
                type="password"
                name="password"
                placeholder="Mot de passe"
                required
              />
            </FormGroup>

            {!StringHelper.isNullOrEmpty(this.state.loginError) && (
              <Alert color="danger">{this.state.loginError}</Alert>
            )}
          </div>
          <Button color="secondary" block className="loginButton">
            Valider
          </Button>
        </ValidationForm>
      </AuthBlock>
    );
  }
}
