import {Redirect} from 'react-router-dom';
import React from 'react';

import ApiClient from 'admin/services/ApiClient';
import Authentication from 'admin/services/Authentication';
import PrivateLayout from 'layouts/privateLayout/PrivateLayout';

export default class AdminPrivateLayout extends React.PureComponent {
  editorialMenuItems = [
    {
      menuKey: 'clinics',
      label: 'Établissements',
      icon: 'hospital-alt',
      path: '/clinics',
    },
    {
      menuKey: 'emergencies',
      label: 'Urgences & CSNP',
      icon: 'hospital-alt',
      path: '/emergencies',
    },
    {
      menuKey: 'articles',
      label: 'Articles',
      icon: 'pen',
      path: '/articles',
    },
    {
      menuKey: 'maps',
      label: 'Cartes interactives',
      icon: 'map-marked-alt',
      path: '/maps',
    },
    {
      menuKey: 'news',
      label: 'Actualités',
      icon: 'newspaper',
      path: '/news',
    },
    {
      menuKey: 'faq',
      label: 'FAQ',
      icon: 'question-circle',
      path: '/faq',
    },
    {
      menuKey: 'pages',
      label: 'Pages',
      icon: 'file',
      path: '/pages',
    },
  ];

  adminMenuItems = [
    {
      menuKey: 'admins',
      label: 'Administrateurs',
      icon: 'users',
      path: '/admins',
    },
    {
      menuKey: 'clinicAdmins',
      label: 'Admins cliniques',
      icon: 'user',
      path: '/clinicAdmins',
    },
  ];

  qoDMenuItems = [
    {
      menuKey: 'qod_dashboard',
      label: 'Dashboard',
      icon: 'clipboard',
      path: '/qod/',
    },
    {
      menuKey: 'qod_search',
      label: 'Recherche',
      icon: 'binoculars',
      path: '/qod/search',
    },
  ];

  healthMonitoringMenuItems = [
    {
      menuKey: 'prevention_categories',
      label: 'Catégories de prévention',
      icon: 'boxes',
      path: '/prevention/categories',
    },
    {
      menuKey: 'healthFolders_folders',
      label: 'Dossiers santé',
      icon: 'folder-plus',
      path: '/healthFolders/folders',
    },
    {
      menuKey: 'healthFolders_forms',
      label: 'Questionnaires santé',
      icon: 'question-circle',
      path: '/healthFolders/forms',
    },
    {
      menuKey: 'prevention_checks',
      label: 'Contrôles de prévention',
      icon: 'folder-plus',
      path: '/prevention/checks',
    },
    {
      menuKey: 'self_assessment_tests',
      label: `Tests d'auto-évaluation`,
      icon: 'comment-dots',
      path: '/selfAssessmentTests',
    },
  ];

  bookingItems = [
    {
      menuKey: 'internalBookingSpecialities',
      label: 'Correspondances des spécialités',
      icon: 'link',
      path: '/booking/internalSpecialities',
    },
  ];

  constructor(props) {
    super(props);
    Authentication.loadFromStorage();
  }

  render() {
    if (!Authentication.isLogged()) {
      return <Redirect to={`/admin/login`} />;
    }

    let sideMenuItems = [];

    if (Authentication.isLogged()) {
      // Release notes
      if (Authentication.user.isSuperAdmin) {
        sideMenuItems.push({
          menuKey: 'releaseNotes',
          label: 'Les nouveautés',
          icon: 'file',
          path: '/releaseNotes',
        });
      }

      // EDITORIAL
      sideMenuItems.push({
        menuKey: 'editorial',
        label: 'Éditorial',
        subItems: this.editorialMenuItems,
      });

      // QoD
      let qodMenuItems = [...this.qoDMenuItems];
      if (Authentication.user.isSuperAdmin) {
        qodMenuItems.push({
          menuKey: 'qod_errors',
          label: "Codes d'erreurs",
          icon: 'exclamation-circle',
          path: '/qod/errors',
        });
      }

      sideMenuItems.push({
        menuKey: 'qod',
        label: 'QoD',
        subItems: qodMenuItems,
      });

      // Health Folders
      sideMenuItems.push({
        menuKey: 'healthMonitoring',
        label: 'Suivi Santé',
        subItems: this.healthMonitoringMenuItems,
      });

      // Booking
      sideMenuItems.push({
        menuKey: 'booking',
        label: 'EasyPrat',
        subItems: this.bookingItems,
      });

      // Super Admin
      if (Authentication.user.isSuperAdmin) {
        sideMenuItems.push({
          menuKey: 'administration',
          label: 'Administration',
          subItems: this.adminMenuItems,
        });
      }
    }

    return (
      <PrivateLayout
        {...this.props}
        sideMenuTitle="ADEL Admin"
        sideMenuSubitle="Back Office éditorial"
        routesPrefix="admin"
        unauthorizedRoutePath="login"
        authentication={Authentication}
        apiClient={ApiClient}
        sideMenuItems={sideMenuItems}
      />
    );
  }
}
