import ApiClient from "admin/services/ApiClient";

export default class ArticlesApi {
  static getAll = () => ApiClient.get("articles");

  static get = (id) => ApiClient.get(`articles/${id}`);

  static add = (data) => ApiClient.post("articles", data, true);

  static editGeneral = (id, data) =>
    ApiClient.post(`articles/${id}/general`, data, true);

  static delete = (id) => ApiClient.delete(`articles/${id}`);

  static addBlock = (id, type, data) =>
    ApiClient.post(`articles/${id}/blocks/${type}`, data, true);

  static moveBlock = (articleId, blockId, data) =>
    ApiClient.patch(`articles/${articleId}/blocks/${blockId}/order`, data);

  static editBlock = (articleId, blockId, data) =>
    ApiClient.post(
      `articles/${articleId}/blocks/${blockId}/content`,
      data,
      true
    );

  static deleteBlock = (articleId, blockId) =>
    ApiClient.delete(`articles/${articleId}/blocks/${blockId}`);
}
