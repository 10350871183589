import HttpClient from "services/HttpClient";

import Authentication from "./Authentication";

export default class AuthClient {
  static request = async (method, route, body = null, multipart = false) =>
    HttpClient.request(
      method,
      `${process.env.REACT_APP_API_CLINIC_ENDPOINT}/${route}`,
      Authentication.token,
      body,
      multipart
    );

  static post = async (route, body = null, multipart = false) =>
    AuthClient.request("POST", route, body, multipart);
}
