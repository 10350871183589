import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NotificationManager} from 'react-notifications';
import {Table, Badge, Button} from 'reactstrap';
import {withLayoutContextProvider} from 'services/LayoutContext';
import {withRouter} from 'react-router-dom';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import React from 'react';

import ArticlesApi from 'clinic/apis/articles.api';

import PreviewModal from '../previewModal/PreviewModal';

class GroupArticlesTab extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    articles: null,
    previewModalOpened: false,
    previewArticle: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, articles: null},
      async () => {
        let nextState = {isLoading: false};
        let articles = await ArticlesApi.getAllGroupSummaries().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (articles) {
          nextState.articles = _.orderBy(articles, 'title');
        }

        this.setState(nextState);
      },
    );
  };

  preview = async (id) => {
    this.props.layoutContextProvider.showLoader();
    let article = await ArticlesApi.getGroupArticle(id).catch((e) => {
      NotificationManager.error('Une erreur est survenue', null);
      return null;
    });

    this.props.layoutContextProvider.hideLoader();
    if (article) {
      this.setState({
        previewModalOpened: true,
        previewArticle: article,
      });
    }
  };

  duplicate = async (id) => {
    this.props.layoutContextProvider.showLoader();
    let article = await ArticlesApi.duplicateGroupArticle(id).catch((e) => {
      NotificationManager.error('Une erreur est survenue', null);
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (article) {
      this.props.history.push(`articles/${article.id}`);
    }
  };

  onModalToggle = () => {
    this.setState({
      previewModalOpened: false,
      previewArticle: null,
    });
  };

  render() {
    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}>
        {this.renderContent()}
      </PageDataLoader>
    );
  }

  renderContent = () => {
    if (!this.state.articles) return null;

    return (
      <div>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="width-auto"></th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.articles.map((article) => (
              <tr key={article.id}>
                <td>{article.title}</td>
                <td className="text-center">
                  <Badge color={article.published ? 'success' : 'secondary'}>
                    {article.published ? 'Publié' : 'Non publié'}
                  </Badge>
                </td>
                <td className="width-auto text-center">
                  {article.type === 'video' && (
                    <a
                      href={`https://www.youtube.com/watch?v=${article.videoId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Visualiser"
                      className="btn btn-primary btn-sm me-2">
                      <FontAwesomeIcon icon="play" />
                    </a>
                  )}

                  {article.type === 'text' && (
                    <Button
                      color="primary"
                      size="sm"
                      className="me-2"
                      title="Visualiser"
                      onClick={() => this.preview(article.id)}>
                      <FontAwesomeIcon icon="search" />
                    </Button>
                  )}

                  <Button
                    color="warning"
                    size="sm"
                    className="me-2"
                    title="Dupliquer dans les articles clinique"
                    onClick={() => this.duplicate(article.id)}>
                    <FontAwesomeIcon icon="copy" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PreviewModal
          article={this.state.previewArticle}
          isOpen={this.state.previewModalOpened}
          onToggle={this.onModalToggle}
        />
      </div>
    );
  };
}

export default withLayoutContextProvider(withRouter(GroupArticlesTab));
