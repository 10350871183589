import { NotificationManager } from "react-notifications";
import { Row, Col, Label, FormGroup, Alert, Button } from "reactstrap";
import Block from "sharedComponents/block/Block";
import Input from "sharedComponents/input/Input";
import QoDMessagesList from "sharedPagesComponents/qod/QoDMessagesList/QoDMessagesList";
import React from "react";
import ValidationForm from "sharedComponents/validationForm/ValidationForm";

import QoDApi from 'clinic/apis/qod.api';

export default class QoDSearchPage extends React.PureComponent {
  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Recherche d'un dossier",
      activeMenuKey: "qod_search"
    });
  }
  state = {
    loaded: false,
    messages: [],
    errorsDescriptions: []
  };

  onSubmit = formData => {
    this.setState(
      { loaded: false, messages: [], errorsDescriptions: [] },
      async () => {
        this.props.layoutContextProvider.showLoader();
        let nextState = { loaded: true };

        let error = null;
        let data = await Promise.all([
          QoDApi.getErrorsDescriptions(),
          QoDApi.getLogsFromMedicalStay(formData.medicalStayGapId)
        ]).catch(e => {
          error = e;
          if (e.status !== 404) {
            NotificationManager.error(e.message, "Une erreur est survenue");
          }
        });

        this.props.layoutContextProvider.hideLoader();

        if (!error) {
          nextState.messages = data[1].sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateA < dateB ? 1 : -1;
          });

          nextState.errorsDescriptions = data[0];
        }

        this.setState(nextState);
      }
    );
  };

  render() {
    return (
      <>
        <Block
          irreducible
          title={
            <span>
              Filtres de <strong>recherche</strong>
            </span>
          }
        >
          <ValidationForm onSubmit={this.onSubmit}>
            <Row>
              <Col sm={4}>
                <Label>Numéro de dossier GAP</Label>
                <FormGroup>
                  <Input
                    className="form-control"
                    name="medicalStayGapId"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button type="submit" color="primary">
              Valider
            </Button>
          </ValidationForm>
        </Block>

        {this.state.loaded && this.state.messages.length === 0 && (
          <Alert color="warning">
            Aucun message correspondant à cet identifiant
          </Alert>
        )}

        <QoDMessagesList
          messages={this.state.messages}
          errorsDescriptions={this.state.errorsDescriptions}
        />
      </>
    );
  }
}
