import {FormGroup} from 'reactstrap';
import {Label} from 'reactstrap';
import {PropTypes} from 'prop-types';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import Input from 'sharedComponents/input/Input';
import React from 'react';

export default class UserForm extends React.PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      password: PropTypes.string,
      isEditorialAdmin: PropTypes.bool,
      isQoDAdmin: PropTypes.bool,
      isRoomReservationsAdmin: PropTypes.bool,
    }),
    passwordRequired: PropTypes.bool,
  };

  static defaultProps = {
    user: {
      name: '',
      email: '',
      password: '',
    },
    passwordRequired: false,
  };

  render() {
    return (
      <div className="userForm">
        <FormGroup className="required">
          <Label>Nom</Label>
          <Input
            name="name"
            required
            value={this.props.user ? this.props.user.name : ''}
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            required
            value={this.props.user ? this.props.user.email : ''}
          />
        </FormGroup>

        <FormGroup className={this.props.passwordRequired ? 'required' : ''}>
          <Label>Mot de passe</Label>
          <Input
            type="password"
            name="password"
            required={this.props.passwordRequired}
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Droits</Label>
          <div className="ms-4">
            <Checkbox
              id="isEditorialAdmin"
              name="isEditorialAdmin"
              label="Contenu éditorial"
              checked={this.props.user && this.props.user.isEditorialAdmin}
            />
            <Checkbox
              id="isQoDAdmin"
              name="isQoDAdmin"
              label="QoD"
              checked={this.props.user && this.props.user.isQoDAdmin}
            />
            <Checkbox
              id="isRoomReservationsAdmin"
              name="isRoomReservationsAdmin"
              label="Gestion de l'hôtellerie"
              checked={
                this.props.user && this.props.user.isRoomReservationsAdmin
              }
            />
            <Checkbox
              id="isUsersAdmin"
              name="isUsersAdmin"
              label="Gestion des utilisateurs"
              checked={this.props.user && this.props.user.isUsersAdmin}
            />
          </div>
        </FormGroup>
      </div>
    );
  }
}
