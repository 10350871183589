import React from "react";

import classnames from "classnames";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Block from "sharedComponents/block/Block";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import GeneralTab from "./generalTab/GeneralTab";
import ContentTab from "./contentTab/ContentTab";

import NewsApi from "admin/apis/news.api";

export default class EditNewsPage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : "general",
    isLoading: true,
    loadingError: false,
    news: null,
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.props.layoutContextProvider.change({
      title: "Gestion des actualités",
      activeMenuKey: "news",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, news: null },
      async () => {
        let nextState = { isLoading: false };
        nextState.news = await NewsApi.get(this.id).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.news && nextState.news.type !== "text") {
          nextState.activeTab = "general";
        }

        this.setState(nextState);
      }
    );
  };

  goToGeneralTab = () => {
    this.setState({ activeTab: "general" });
    this.props.history.replace("#general");
  };

  goToContentTab = () => {
    this.setState({ activeTab: "content" });
    this.props.history.replace("#content");
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modifier une <strong>actualité</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de l'actualité"
        >
          {this.state.news && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "general",
                    })}
                    onClick={this.goToGeneralTab}
                  >
                    Général
                  </NavLink>
                </NavItem>
                {this.state.news.type === "text" && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "content",
                      })}
                      onClick={this.goToContentTab}
                    >
                      Contenu
                    </NavLink>
                  </NavItem>
                )}
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="general">
                  <GeneralTab news={this.state.news} />
                </TabPane>
                {this.state.news.type === "text" && (
                  <TabPane tabId="content">
                    <ContentTab news={this.state.news} />
                  </TabPane>
                )}
              </TabContent>
            </>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
