import { PropTypes } from "prop-types";
import React from "react";

import { Label, FormGroup } from "reactstrap";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";
import Input from "sharedComponents/input/Input";

export default class CreatePageButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    modalVisible: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return { modalVisible: !prevState.modalVisible };
    });

  onSubmit = (formData) => {
    return this.props.onSubmit(formData);
  };

  render() {
    return (
      <CreateButtonModal
        text="Nouvelle page"
        icon="plus-circle"
        onSubmit={this.onSubmit}
      >
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input type="text" name="title" required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Code interne</Label>
          <Input type="text" name="internalCode" required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Petite image (aperçu sur la liste des pages)</Label>
          <ImageUploader
            name="smallPictureFile"
            helpText="Taille attendue : 417px par 417px"
            required
          />
        </FormGroup>
        <FormGroup className="required">
          <Label>Grande image (entête de la page)</Label>
          <ImageUploader
            name="largePictureFile"
            helpText="Taille attendue : 1125px par 788px"
            required
          />
        </FormGroup>
      </CreateButtonModal>
    );
  }
}
