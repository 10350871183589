import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormText, Row, Col } from "reactstrap";
import { withFormsy } from "formsy-react";
import PropTypes from "prop-types";
import React from "react";
import RNImageUploader from "react-images-upload";
import StringHelper from "helpers/StringHelper";
import ValidationErrors from "services/ValidationErrors";

import "./ImageUploader.scss";

class ImageUploader extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    maxFileSize: PropTypes.number,
    placeholderImageUrl: PropTypes.string,
    actualUrl: PropTypes.string,
    helpText: PropTypes.string,
    whiteImage: PropTypes.bool,
  };

  static defaultProps = {
    maxFileSize: 5242880, // 5Mb
    whiteImage: false,
  };

  state = {
    base64: null,
  };

  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState({ base64: pictureDataURLs[0] });
    this.props.setValue(pictureFiles[0]);
    //this.props.onChange(pictureFiles[0]);
  };

  onDelete = () => {
    this.setState({ base64: null });
    this.props.setValue(null);
    //this.props.onDelete();
  };

  getErrorSize = () => {
    let max = this.props.maxFileSize / 1048576;
    return `Image trop lourde (${max} Mo max)`;
  };

  render() {
    const imageSrc = StringHelper.isNullOrEmpty(this.state.base64)
      ? StringHelper.isNullOrEmpty(this.props.actualUrl)
        ? null
        : this.props.actualUrl
      : this.state.base64;

    const errorMessage = this.props.errorMessage;

    return (
      <div
        className={`${
          this.props.isPristine ? "" : this.props.isValid ? "valid" : "invalid"
        }`}
      >
        <div className="imageUploader">
          <Row className="align-items-center">
            <Col className="left">
              <RNImageUploader
                withIcon
                singleImage
                withLabel={false}
                name={this.props.name}
                buttonText="Choisir une image"
                fileSizeError={this.getErrorSize()}
                onChange={this.onDrop}
                onDelete={this.onDelete}
                imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
                maxFileSize={this.props.maxFileSize}
                fileContainerStyle={{ boxShadow: "none" }}
              />
            </Col>
            {!StringHelper.isNullOrEmpty(imageSrc) && (
              <Col xs="4">
                <div
                  className={`thumbnailContainer ${
                    this.props.whiteImage && "thumbnail-white"
                  }`}
                >
                  {!StringHelper.isNullOrEmpty(this.state.base64) && (
                    <Button
                      color="danger"
                      size="sm"
                      onClick={this.onDelete}
                      className="deleteImageButton"
                    >
                      <FontAwesomeIcon icon="times" />
                    </Button>
                  )}
                  <img
                    src={imageSrc}
                    className="img-fluid thumbnail"
                    alt="img uploader"
                  />
                </div>
              </Col>
            )}
          </Row>
        </div>

        {this.props.helpText && (
          <FormText color="muted">{this.props.helpText}</FormText>
        )}

        {!this.props.isPristine && this.props.showRequired ? (
          <div className="invalid-feedback">{ValidationErrors.required}</div>
        ) : (
          <div className="invalid-feedback">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default withFormsy(ImageUploader);
