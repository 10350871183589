import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import _ from 'lodash';
import moment from 'moment';

import {Table} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import YoutubeButton from 'sharedComponents/youtubeButton/YoutubeButton';
import PreviewHtmlButton from 'sharedComponents/previewHtmlButton/PreviewHtmlButton';
import CreateReleaseNoteButton from './createReleaseNoteButton/CreateReleaseNoteButton';

import ReleaseNotesApi from 'admin/apis/releaseNotes.api';

export default class ReleaseNotesPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    notes: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des nouveautés',
      activeMenuKey: 'releaseNotes',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, notes: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.notes = await ReleaseNotesApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      },
    );
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    const createdNote = await ReleaseNotesApi.create(formData).catch((e) => {
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('La nouveauté a été créée', null);
    this.props.history.push(`/admin/releaseNotes/${createdNote.id}`);
    return Promise.resolve();
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await ReleaseNotesApi.delete(id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('La nouveauté a été supprimée.', null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>dernières nouveautés</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des dernières nouveautés">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.notes) return null;

    return (
      <div>
        <div className="text-end mb-2">
          <CreateReleaseNoteButton onSubmit={this.onCreate} />
        </div>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th className="width-auto">Date de publication</th>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.orderBy(this.state.notes, 'createdAt', 'desc').map((n) => {
              return (
                <tr key={`${n.id}`}>
                  <td className="text-center">
                    {n.createdAt && moment(n.createdAt).format('DD/MM/YYYY')}
                  </td>

                  <td>{n.title}</td>
                  <td className="width-auto text-center">
                    {n.youtubeId && (
                      <YoutubeButton title="Visualiser" videoId={n.youtubeId} />
                    )}
                    <span className="mx-2">
                      <PreviewHtmlButton html={n.note} />
                    </span>
                    <span className="mx-2">
                      <EditButtonLink to={`releaseNotes/${n.id}`} />
                    </span>
                    <DeleteButton
                      data={n.id}
                      title="Supprimer une nouveauté"
                      className="ms-1"
                      onConfirm={this.onDelete}>
                      Voulez-vous réellement supprimer la nouveauté{' '}
                      <i>{n.title}</i> ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
