import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PropTypes} from 'prop-types';
import moment from 'moment';
import React from 'react';

import Block from 'sharedComponents/block/Block';

export default class QoDMessagesList extends React.PureComponent {
  static propTypes = {
    messages: PropTypes.array,
    errorsDescriptions: PropTypes.array,
  };

  static defaultProps = {
    messages: [],
    errorsDescriptions: [],
  };

  getFormatedDate(date) {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  getErrorDescription(errorCode) {
    let error = this.props.errorsDescriptions.filter(
      (e) => e.code === errorCode,
    );

    if (error.length > 0) {
      return error[0].description;
    } else {
      return errorCode;
    }
  }

  getStatusText(status) {
    let className = '';
    let statusText = '';

    switch (status) {
      case 'error':
        className = 'text-danger';
        statusText = 'Erreur';
        break;

      case 'warning':
        className = 'text-warning';
        statusText = 'Alerte';
        break;

      case 'success':
        className = 'text-success';
        statusText = 'Succès';
        break;

      default:
        className = '';
        statusText = '';
        break;
    }

    return (
      <strong>
        <span className={className}>{statusText}</span>
      </strong>
    );
  }

  render() {
    return (
      <div>
        {this.props.messages.map((m) => (
          <Block
            irreducible
            key={m.id}
            title={
              <span>
                Message du <strong>{this.getFormatedDate(m.date)}</strong>
              </span>
            }>
            <ul>
              <li>Identifiant : {m.messageId}</li>
              <li>Statut : {this.getStatusText(m.status)}</li>
              <li>Type : {m.type}</li>
              <li>
                Erreurs / Warnings :
                {(m.infos != null ||
                  m.warnings != null ||
                  m.errors != null) && (
                  <ul>
                    {m.infos != null &&
                      m.infos.map((info) => (
                        <li key={m.id + '_' + info}>
                          <FontAwesomeIcon
                            className="text-info"
                            icon="info-circle"
                          />{' '}
                          {this.getErrorDescription(info)}
                        </li>
                      ))}

                    {m.warnings != null &&
                      m.warnings.map((warning) => (
                        <li key={m.id + '_' + warning}>
                          <FontAwesomeIcon
                            className="text-warning"
                            icon="exclamation-triangle"
                          />{' '}
                          {this.getErrorDescription(warning)}
                        </li>
                      ))}

                    {m.errors != null &&
                      m.errors.map((error) => (
                        <li key={m.id + '_' + error}>
                          <FontAwesomeIcon
                            className="text-danger"
                            icon="exclamation-circle"
                          />{' '}
                          {this.getErrorDescription(error)}
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            </ul>
          </Block>
        ))}
      </div>
    );
  }
}
