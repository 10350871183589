import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import {Table} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import YoutubeButton from 'sharedComponents/youtubeButton/YoutubeButton';
import PreviewHtmlButton from 'sharedComponents/previewHtmlButton/PreviewHtmlButton';

import ReleaseNotesApi from 'clinic/apis/releaseNotes.api';

export default class ReleaseNotesPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    notes: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des nouveautés',
      activeMenuKey: 'releaseNotes',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, notes: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.notes = await ReleaseNotesApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      },
    );
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>dernières nouveautés</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des dernières nouveautés">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.notes) return null;

    return (
      <div>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th className="width-auto">Date de publication</th>
              <th>Titre</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.orderBy(this.state.notes, 'createdAt', 'desc').map((n) => {
              return (
                <tr key={`${n.id}`}>
                  <td className="text-center">
                    {n.createdAt && moment(n.createdAt).format('DD/MM/YYYY')}
                  </td>
                  <td>{n.title}</td>
                  <td className="width-auto text-center">
                    {n.youtubeId && (
                      <YoutubeButton title="Visualiser" videoId={n.youtubeId} />
                    )}
                    <span className="mx-2">
                      <PreviewHtmlButton html={n.note} />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
