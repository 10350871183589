import React from "react";
import { PropTypes } from "prop-types";

import _ from "lodash";

import { FormGroup, Label } from "reactstrap";
import PageDataLoader from "sharedComponents/pageDataLoader/PageDataLoader";
import Select from "sharedComponents/select/Select";

import HealthFoldersApi from "admin/apis/healthFolders.api";

export default class AddFormsForm extends React.PureComponent {
  static propTypes = {
    healthFolderId: PropTypes.number.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    forms: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, forms: null },
      async () => {
        let nextState = { isLoading: false };
        let forms = await HealthFoldersApi.getAvailableForms(
          this.props.healthFolderId
        ).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (forms) {
          nextState.forms = _.sortBy(forms, "title");
        }

        this.setState(nextState);
      }
    );
  };

  render() {
    return (
      <div>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
        >
          {this.state.forms && (
            <div>
              <FormGroup className="required">
                <Label>Questionnaire</Label>
                <Select
                  name="formId"
                  clearable
                  searchable
                  required
                  options={this.state.forms.map((f) => {
                    return { value: f.id, label: f.title };
                  })}
                />
              </FormGroup>
            </div>
          )}
        </PageDataLoader>
      </div>
    );
  }
}
