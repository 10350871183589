import React from 'react';

import ValidationErrors from 'services/ValidationErrors';

import {FormGroup, Label} from 'reactstrap';
import Input from 'sharedComponents/input/Input';

export default class CreatePostOpInstructionForm extends React.PureComponent {
  render() {
    return (
      <div className="createPostOpInstructionForm">
        <FormGroup className="required">
          <Label>Title</Label>
          <Input
            name="title"
            maxLength={255}
            validations="maxLength:255"
            validationError={ValidationErrors.maxLength(255)}
            required
          />
        </FormGroup>
      </div>
    );
  }
}
