import ApiClient from "admin/services/ApiClient";
import queryString from "query-string";

export default class HealthFoldersArticlesApi {
  static get = (id, withCategory) => {
    let params = {};
    if (withCategory) {
      params.withCategory = null;
    }

    return ApiClient.get(
      `healthFoldersArticles/${id}?${queryString.stringify(params)}`
    );
  };

  static create = (formData) =>
    ApiClient.post("healthFoldersArticles", formData, true);

  static changeOrder = (articleId, newOrder) =>
    ApiClient.put(`healthFoldersArticles/${articleId}/order`, {
      newOrder: newOrder,
    });

  static updateGeneral = (articleId, formData) =>
    ApiClient.post(
      `healthFoldersArticles/${articleId}/general`,
      formData,
      true
    );

  static delete = (articleId) =>
    ApiClient.delete(`healthFoldersArticles/${articleId}`);

  static addBlock = (id, type, data) =>
    ApiClient.post(`healthFoldersArticles/${id}/blocks/${type}`, data, true);

  static moveBlock = (articleId, blockId, data) =>
    ApiClient.patch(
      `healthFoldersArticles/${articleId}/blocks/${blockId}/order`,
      data
    );

  static editBlock = (articleId, blockId, data) =>
    ApiClient.post(
      `healthFoldersArticles/${articleId}/blocks/${blockId}/content`,
      data,
      true
    );

  static deleteBlock = (articleId, blockId) =>
    ApiClient.delete(`healthFoldersArticles/${articleId}/blocks/${blockId}`);
}
