import {PropTypes} from 'prop-types';
import ElsanLogo from 'sharedComponents/elsanLogo/ElsanLogo';
import React from 'react';

import './InlineLoader.scss';

export default class InlineLoader extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    block: PropTypes.bool,
    xl: PropTypes.bool,
  };

  static defaultProps = {
    text: 'Chargement',
    block: false,
    xl: false,
  };

  state = {};

  render() {
    return (
      <div
        className={`inlineLoader ${
          this.props.block ? 'text-center' : 'd-inline-flex flex-row'
        } align-items-center`}>
        <div
          className={`logo ${this.props.block ? '' : 'me-2'} ${
            this.props.xl ? 'xl' : ''
          }`}>
          <ElsanLogo />
        </div>
        <div>{this.props.text}...</div>
      </div>
    );
  }
}
