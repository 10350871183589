import ApiClient from "admin/services/ApiClient";

export default class MapPlacesApi {
  static getByMapId = (mapId) => ApiClient.get(`maps/${mapId}/places`);

  static add = (data) => ApiClient.post(`mapPlaces`, data, true);

  static getById = (id) =>
    ApiClient.get(`mapPlaces/${id}`);

  static update = (id, data) => ApiClient.post(`mapPlaces/${id}`, data, true);

  static delete = (id) => ApiClient.delete(`mapPlaces/${id}`);
}
