import React from 'react';
import {PropTypes} from 'prop-types';

import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import {withLayoutContextProvider} from 'services/LayoutContext';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import OrderingHelper from 'helpers/OrderingHelper';

import {NotificationManager} from 'react-notifications';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {Alert} from 'reactstrap';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import AddArticleButton from './addArticleButton/AddArticleButton';
import CategoryForm from './categoryForm/CategoryForm';
import CategoryItem from './categoryItem/CategoryItem';

import HealthFoldersCategoriesApi from 'admin/apis/healthFoldersCategories.api';
import HealthFoldersArticlesApi from 'admin/apis/healthFoldersArticles.api';

export class InformationsTab extends React.PureComponent {
  static propTypes = {
    healthFolder: PropTypes.object.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    categories: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, articles: null},
      async () => {
        let nextState = {isLoading: false};
        let categories = await HealthFoldersCategoriesApi.getByHealthFolderId(
          this.props.healthFolder.id,
          true,
        ).catch((e) => {
          nextState.loadingError = true;
          return [];
        });

        if (categories) {
          nextState.categories = _.sortBy(categories, 'order').map((c) => ({
            ...c,
            articles: _.sortBy(c.articles, 'order'),
          }));
        }

        this.setState(nextState);
      },
    );
  };

  onDrop = (result) => {
    if (!result.destination || result.source.index === result.destination.index)
      return;

    if (result.type === 'CATEGORY') {
      this.onCategoryDrop(result);
    } else {
      let parts = result.draggableId.split('-');
      this.onArticleDrop(result, +parts[1]);
    }
  };

  onCategoryDrop = (result) => {
    let orderedCategories = OrderingHelper.reorder(
      this.state.categories,
      result.source.index,
      result.destination.index,
    );

    for (var i = 0; i < orderedCategories.length; i++) {
      orderedCategories[i].order = i + 1;
    }

    let lastStateCategories = [...this.state.categories];
    const categoryId = this.state.categories[result.source.index].id;

    this.setState({categories: orderedCategories}, async () => {
      this.props.layoutContextProvider.showLoader();
      await HealthFoldersCategoriesApi.changeOrder(
        categoryId,
        result.destination.index + 1,
      ).catch((error) => {
        this.setState({categories: lastStateCategories});
      });

      this.props.layoutContextProvider.hideLoader();
    });
  };

  onArticleDrop = (result, categoryId) => {
    let category = _.keyBy(this.state.categories, 'id')[categoryId];
    let orderedArticles = OrderingHelper.reorder(
      category.articles,
      result.source.index,
      result.destination.index,
    );

    for (var i = 0; i < orderedArticles.length; i++) {
      orderedArticles[i].order = i + 1;
    }

    const newCategory = {...category, articles: orderedArticles};

    let lastStateCategories = [...this.state.categories];
    const articleId = category.articles[result.source.index].id;

    this.setState(
      {
        categories: lastStateCategories.map((c) =>
          c.id === categoryId ? newCategory : c,
        ),
      },
      async () => {
        this.props.layoutContextProvider.showLoader();
        await HealthFoldersArticlesApi.changeOrder(
          articleId,
          result.destination.index + 1,
        ).catch((error) => {
          this.setState({categories: lastStateCategories});
        });

        this.props.layoutContextProvider.hideLoader();
      },
    );
  };

  onCreateCategorySubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await HealthFoldersCategoriesApi.create({
      ...formData,
      healthFolderId: this.props.healthFolder.id,
    }).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('La catégorie a été créée', null);
    this.loadData();

    return Promise.resolve(true);
  };

  onEditCategorySubmit = async (category, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await HealthFoldersCategoriesApi.update(category.id, formData).catch(
      (e) => {
        error = e;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('La catégorie a été modifiée', null);
    this.loadData();
    return Promise.resolve(true);
  };

  onDeleteCategory = async (categoryId) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await HealthFoldersCategoriesApi.delete(categoryId).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('La catégorie a été supprimée', null);
      this.loadData();
    }
  };

  onCreateArticleSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let createdArticle = await HealthFoldersArticlesApi.create(formData).catch(
      (e) => {
        error = e;
        return null;
      },
    );
    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("L'article' a été créé", null);

    switch (formData.type) {
      case 'text':
        this.props.history.push(
          `/admin/healthFolders/articles/${createdArticle.id}`,
        );
        break;
      default:
        this.loadData();
        break;
    }

    return Promise.resolve();
  };

  onDeleteArticle = async (articleId) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await HealthFoldersArticlesApi.delete(articleId).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('La catégorie a été supprimée', null);
      this.loadData();
    }
  };

  render() {
    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}>
        {this.renderContent()}
      </PageDataLoader>
    );
  }

  renderContent = () => {
    if (!this.state.categories) return null;

    return (
      <div>
        <Alert color="info">
          La première catégorie sera affichée par défaut dans l'app ADEL.
        </Alert>
        <div className="text-end mb-2">
          <CreateButtonModal
            text="Nouvelle catégorie"
            onSubmit={this.onCreateCategorySubmit}>
            <CategoryForm />
          </CreateButtonModal>
          <span className="ms-2">
            <AddArticleButton
              categories={this.state.categories}
              onSubmit={this.onCreateArticleSubmit}
            />
          </span>
        </div>
        <DragDropContext onDragEnd={this.onDrop}>
          <Droppable droppableId="CATEGORIES" type="CATEGORY">
            {(provided, snapshot) => (
              <div
                className={`droppable ${
                  snapshot.isDraggingOver ? 'dragging' : 'notDragging'
                }`}
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {this.state.categories.map((category, index) => (
                  <Draggable
                    key={`category-${category.order}`}
                    draggableId={`category-${category.order}`}
                    index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`item ${
                          snapshot.isDragging ? 'dragging' : 'notDragging'
                        }`}>
                        <CategoryItem
                          category={category}
                          onEditSubmit={this.onEditCategorySubmit}
                          onDelete={this.onDeleteCategory}
                          onDeleteArticle={this.onDeleteArticle}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };
}

export default withRouter(withLayoutContextProvider(InformationsTab));
