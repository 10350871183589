import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Button, FormGroup, Label} from 'reactstrap';
import {Link} from 'react-router-dom';
import Block from 'sharedComponents/block/Block';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';

import HealthFormsApi from 'admin/apis/healthForms.api';

export default class EditHealthFormPage extends React.PureComponent {
  state = {
    isLoading: false,
    loadingError: false,
    form: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des questionnaires santé',
      activeMenuKey: 'healthFolders_forms',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        form: null,
      },
      async () => {
        let nextState = {isLoading: false};

        nextState.form = await HealthFormsApi.get(this.id).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await HealthFormsApi.edit(this.id, formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Questionnaire modifié avec succès', null);
      this.props.history.push(`/admin/healthFolders/forms`);
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modification d'un <strong>questionnaire santé</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des cliniques">
          {this.state.form && (
            <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Titre</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="title"
                    value={this.state.form.title}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-2">Type</Label>
                <div className="col-sm-10">
                  {this.state.form.type === 'internal' ? 'Interne' : 'Externe'}
                </div>
              </FormGroup>

              {this.state.form.type === 'internal' && (
                <>
                  <FormGroup className="row required">
                    <Label className="col-form-label col-sm-2">
                      Code interne
                    </Label>
                    <div className="col-sm-10">
                      <Input
                        className="form-control"
                        name="internalCode"
                        value={this.state.form.internalCode}
                        required
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row required">
                    <Label className="col-form-label col-sm-2">
                      Version minimale de l'app
                    </Label>
                    <div className="col-sm-10">
                      <Input
                        className="form-control"
                        name="minimalAppVersion"
                        value={this.state.form.minimalAppVersion}
                        helpText="Format attendu : X.Y.Z"
                        required
                      />
                    </div>
                  </FormGroup>
                </>
              )}

              {this.state.form.type === 'external' && (
                <FormGroup className="row required">
                  <Label className="col-form-label col-sm-2">URL</Label>
                  <div className="col-sm-10">
                    <Input
                      className="form-control"
                      name="externalUrl"
                      value={this.state.form.externalUrl}
                      required
                    />
                  </div>
                </FormGroup>
              )}

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">Résumé</Label>
                <div className="col-sm-10">
                  <Input
                    type="textarea"
                    className="form-control"
                    name="summary"
                    value={this.state.form.summary}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-form-label col-sm-2">En savoir +</Label>
                <div className="col-sm-10">
                  <Input
                    type="textarea"
                    className="form-control"
                    name="description"
                    value={this.state.form.description}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-form-label col-sm-2">Image</Label>
                <div className="col-sm-10">
                  <ImageUploader
                    name="picture"
                    actualUrl={this.state.form.pictureUrl}
                    helpText="Taille attendue : 100px par 100px"
                  />
                </div>
              </FormGroup>

              <FormGroup className="text-end">
                <Link
                  to="/admin/healthFolders/forms"
                  className="btn btn-outline-primary me-1"
                  type="button">
                  Retour à la liste
                </Link>
                <Button type="submit" color="primary">
                  Valider
                </Button>
              </FormGroup>
            </ValidationForm>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
