import React from 'react';
import PropTypes from 'prop-types';

import {NotificationManager} from 'react-notifications';
import {withLayoutContextProvider} from 'services/LayoutContext';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {FormGroup} from 'reactstrap';
import {Link} from 'react-router-dom';
import StructuredContent from 'sharedComponents/structuredContent/StructuredContent';

import ArticlesApi from 'admin/apis/articles.api';

class ContentTab extends React.PureComponent {
  static props = {
    article: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      structuredContent: PropTypes.shape({
        id: PropTypes.number.isRequired,
        blocks: PropTypes.array.isRequired,
      }),
    }),
  };

  state = {
    structuredContent: this.props.article.structuredContent,
  };

  onBlockAdded = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await ArticlesApi.addBlock(
      this.props.article.id,
      block.type,
      block.data,
    ).catch((e) => {
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    this.setState({structuredContent: modifiedContent});
    NotificationManager.success("L'élément a été ajouté", null);
    return Promise.resolve();
  };

  onBlockMoved = (movement, reorderedBlocks) => {
    this.props.layoutContextProvider.showLoader();

    var previousContent = this.state.structuredContent;
    this.setState(
      {
        structuredContent: {...previousContent, blocks: reorderedBlocks},
      },
      async () => {
        let movedBlock = previousContent.blocks[movement.from];

        let error = null;
        let nextState = {};

        let newContent = await ArticlesApi.moveBlock(
          this.props.article.id,
          movedBlock.id,
          {position: movement.to},
        ).catch((e) => {
          error = e;
          return null;
        });

        this.props.layoutContextProvider.hideLoader();

        if (error) {
          Promise.reject(error);
          nextState.structuredContent = previousContent;
        } else {
          nextState.structuredContent = newContent;
          NotificationManager.success("L'élément a été déplacé", null);
        }

        this.setState(nextState);
        return Promise.resolve();
      },
    );
  };

  onBlockEdited = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await ArticlesApi.editBlock(
      this.props.article.id,
      block.id,
      block.data,
    ).catch((e) => {
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      Promise.reject(error);
    }

    this.setState({structuredContent: modifiedContent});
    NotificationManager.success("L'élément a été modifié", null);
    return Promise.resolve();
  };

  onBlockDeleted = async (block) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let modifiedContent = await ArticlesApi.deleteBlock(
      this.props.article.id,
      block.id,
    ).catch((e) => {
      ApiErrorsHelper.manage(e);
      error = e;
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      this.setState({structuredContent: modifiedContent});
      NotificationManager.success("L'élément a été supprimé", null);
    }

    return Promise.resolve();
  };

  render() {
    return !this.props.article ? null : (
      <div>
        <StructuredContent
          editable
          blocks={this.state.structuredContent.blocks}
          onBlockAdded={this.onBlockAdded}
          onBlockMoved={this.onBlockMoved}
          onBlockEdited={this.onBlockEdited}
          onBlockDeleted={this.onBlockDeleted}
        />
        <FormGroup className="text-end">
          <Link
            to="/admin/articles"
            className="btn btn-outline-primary"
            type="button">
            Retour à la liste
          </Link>
        </FormGroup>
      </div>
    );
  }
}

export default withLayoutContextProvider(ContentTab);
