import ApiClient from "admin/services/ApiClient";

export default class ClinicsApi {
  static getAll() {
    return ApiClient.get("clinics");
  }

  static add(data) {
    return ApiClient.post("clinics", data);
  }

  static edit = (healthFacilityId, data) =>
    ApiClient.patch(`clinics/${healthFacilityId}`, data);

  static get(healthFacilityId) {
    return ApiClient.get(`clinics/${healthFacilityId}`);
  }

  static delete(healthFacilityId) {
    return ApiClient.delete(`clinics/${healthFacilityId}`);
  }

  static getProtocols(healthFacilityId) {
    return ApiClient.get(`clinics/${healthFacilityId}/protocols`);
  }

  static addProtocol(healthFacilityId, data) {
    return ApiClient.post(`clinics/${healthFacilityId}/protocols`, data);
  }

  static deleteProtocol(healthFacilityId, userId) {
    return ApiClient.delete(`clinics/${healthFacilityId}/protocols/${userId}`);
  }

  static getTreatmentModes = (healthFacilityId) =>
    ApiClient.get(`clinics/${healthFacilityId}/treatmentModes`);

  static addTreatmentMode = (healthFacilityId, data) =>
    ApiClient.post(`clinics/${healthFacilityId}/treatmentModes`, data);

  static editTreatmentMode = (healthFacilityId, id, data) =>
    ApiClient.patch(`clinics/${healthFacilityId}/treatmentModes/${id}`, data);

  static deleteTreatmentMode = (healthFacilityId, id) =>
    ApiClient.delete(`clinics/${healthFacilityId}/treatmentModes/${id}`);

  static editNotifications = (healthFacilityId, data) =>
    ApiClient.patch(`clinics/${healthFacilityId}/notifications`, data);
}
