import React from 'react';
import {PropTypes} from 'prop-types';

import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import Input from 'sharedComponents/input/Input';
import ValidationErrors from 'services/ValidationErrors';
import Select from 'sharedComponents/select/Select';

export default class CreateButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    treatmentModes: PropTypes.array.isRequired,
    protocols: PropTypes.array.isRequired,
    managementRules: PropTypes.array.isRequired,
  };

  state = {
    modalVisible: false,
    selectedManagementRule: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleModal = () => {
    if (this._isMounted) {
      this.setState((prevState) => {
        return {modalVisible: !prevState.modalVisible};
      });
    }
  };

  handleSubmit = async (formData) => {
    for (const property in formData.params) {
      const config = this.state.selectedManagementRule.params.find(
        (p) => p.code === property,
      );

      switch (config.type) {
        case 'integer':
          formData.params[property] = parseInt(formData.params[property]);
          break;

        default:
          break;
      }
    }

    if (this.props.onSubmit && typeof this.props.onSubmit === 'function') {
      let success = true;

      await this.props.onSubmit(formData).catch((error) => {
        ApiErrorsHelper.manage(error, this.form);
        success = false;
      });

      if (success) {
        this.toggleModal();
      }
    }
  };

  onSelectRule = (data) => {
    const rule = this.props.managementRules.find((r) => r.code === data);
    this.setState({
      selectedManagementRule: rule,
    });
  };

  getValidations = (param) => {
    switch (param.type) {
      case 'hour':
        return {
          matchRegexp: /([01][0-9]|2[0-3]):[0-5][0-9]/,
        };

      case 'integer':
        return 'isInt';

      default:
        return null;
    }
  };

  render() {
    const treatmentModesOptions = this.props.treatmentModes.map((tm) => ({
      label: tm.name,
      value: tm.mdtCode,
    }));

    const protocolsOptions = this.props.protocols.map((p) => ({
      label: p.name,
      value: p.id,
    }));

    const managementRulesOptions = this.props.managementRules.map((r) => ({
      label: r.title,
      value: r.code,
    }));

    return (
      <span className="createButtonContainer">
        <Button color="primary" onClick={this.toggleModal}>
          <FontAwesomeIcon icon="plus-circle" /> Nouvelle règle de gestion
        </Button>
        <Modal isOpen={this.state.modalVisible} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Nouvelle règle de gestion
          </ModalHeader>
          <ValidationForm ref={this.form} onSubmit={this.handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label>Mode de traitement</Label>
                <Select name="mdtCode" options={treatmentModesOptions} />
              </FormGroup>

              <FormGroup>
                <Label>Protocole Ambulis</Label>
                <Select
                  name="ambulisOperatingMethodId"
                  options={protocolsOptions}
                />
              </FormGroup>

              <FormGroup className="required">
                <Label>Règle de gestion</Label>
                <Select
                  name="ruleCode"
                  options={managementRulesOptions}
                  required
                  onChange={this.onSelectRule}
                />
              </FormGroup>

              {this.state.selectedManagementRule &&
                this.state.selectedManagementRule.params.length > 0 && (
                  <>
                    <hr />
                    {this.state.selectedManagementRule.params.map((param) => (
                      <FormGroup className="required" key={param.code}>
                        <Label>{param.title}</Label>
                        <Input
                          type="text"
                          name={`params[${param.code}]`}
                          validations={this.getValidations(param)}
                          validationErrors={ValidationErrors}
                          required
                        />
                      </FormGroup>
                    ))}
                  </>
                )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.toggleModal}>
                Annuler
              </Button>
              <Button color="primary" type="submit">
                Valider
              </Button>
            </ModalFooter>
          </ValidationForm>
        </Modal>
      </span>
    );
  }
}
