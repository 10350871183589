import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {FormGroup, Table} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import SelfAssessmentTestsApi from 'admin/apis/selfAssessmentTestsApi';

import './SelfAssessmentsPage.scss';

export default class SelfAssessmentsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    tests: null,
    technicalCodes: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des tests d'auto-évaluation",
      activeMenuKey: 'self_assessment_tests',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        tests: null,
      },
      async () => {
        let nextState = {isLoading: false};
        let data = await Promise.all([
          SelfAssessmentTestsApi.getAll(true, true),
          SelfAssessmentTestsApi.getAllTechnicalCodes(),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.tests = _.sortBy(data[0], 'title');
          nextState.technicalCodes = _.keyBy(data[1], 'code');
        }

        this.setState(nextState);
      },
    );
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await SelfAssessmentTestsApi.delete(id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    if (success) {
      NotificationManager.success(
        `Le test d'auto-évaluation a été supprimé.`,
        null,
      );
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>tests d'auto-évaluation</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.tests) return null;

    return (
      <div className="selfAssessmentTestsList">
        <FormGroup className="text-end">
          <CreateButtonLink
            to="/admin/selfAssessmentTests/add"
            text="Nouveau test d'auto-évaludation"
            className="ms-2"
          />
        </FormGroup>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th>Code technique</th>
              <th>Image</th>
              <th>Icone</th>
              <th>Dossier santé</th>
              <th>Spécialités</th>
              <th
                className="text-center width-auto"
                title="Version de l'app requise">
                App min
              </th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.tests.map((test) => {
              return (
                <tr key={test.id}>
                  <td>{test.title}</td>
                  <td>
                    {this.state.technicalCodes[test.technicalCode]?.label}{' '}
                    <i>({test.technicalCode})</i>
                  </td>
                  <td>
                    <img
                      src={test.imageUrl}
                      className="thumbnail"
                      alt="thumbnail"
                    />
                  </td>
                  <td>
                    <img
                      src={test.iconUrl}
                      className="thumbnail"
                      alt="thumbnail"
                    />
                  </td>
                  <td>{test.healthFolder?.title}</td>
                  <td>
                    <ul className="list-unstyled">
                      {test.specialities.map((s) => (
                        <li key={`${s.id}`}>{s.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="text-center width-auto">
                    {test.minimalAppVersion}
                  </td>

                  <td className="width-auto text-center">
                    <EditButtonLink
                      to={`/admin/selfAssessmentTests/${test.id}`}
                    />
                    <DeleteButton
                      title={
                        <span>
                          Supprimer <i>{test.title}</i>
                        </span>
                      }
                      data={test.id}
                      onConfirm={this.onDelete}
                      className="ms-1"
                      tooltip={`Supprimer ${test.title}`}>
                      Voulez-vous réellement supprimer le test{' '}
                      <i>{test.title}</i> ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
