import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PropTypes} from 'prop-types';
import {Table, Badge, Button} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import moment from 'moment';
import React from 'react';

import MedicalStayDetailsModal from './medicalStayDetailsModal/MedicalStayDetailsModal';

export default class DashboardContent extends React.PureComponent {
  static propTypes = {
    onLoadDetails: PropTypes.func.isRequired,
    layoutContext: PropTypes.object,
  };

  state = {
    detailsModalOpened: false,
    detailsMessages: [],
  };

  getErrorDescription(errorCode) {
    let error = this.props.errorsDescriptions.filter(
      (e) => e.code === errorCode,
    );

    if (error.length > 0) {
      return error[0].description;
    } else {
      return errorCode;
    }
  }

  getStatusText(status) {
    let className = '';
    let statusText = '';

    switch (status) {
      case 'error':
        className = 'text-danger';
        statusText = 'Erreur';
        break;

      case 'warning':
        className = 'text-warning';
        statusText = 'Alerte';
        break;

      case 'success':
        className = 'text-success';
        statusText = 'Succès';
        break;

      default:
        className = '';
        statusText = '';
        break;
    }

    return (
      <strong>
        <span className={className}>{statusText}</span>
      </strong>
    );
  }

  showDetails = async (medicalStay) => {
    let error = null;
    let data = await this.props.onLoadDetails(medicalStay).catch((e) => {
      error = e;
      return null;
    });

    if (!error) {
      this.setState({
        detailsModalOpened: true,
        detailsMessages: data.sort((a, b) => {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateA < dateB ? 1 : -1;
        }),
      });
    }
  };

  toggleDetails = () => {
    this.setState((prevState) => ({
      detailsModalOpened: !prevState.detailsModalOpened,
    }));
  };

  render() {
    return (
      <>
        {this.props.days.map((day, index) => (
          <Block
            key={'day_' + day.date}
            reduced={index > 0}
            title={
              <span>
                Dossiers du{' '}
                <strong>
                  {moment(day.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </strong>
              </span>
            }>
            {day.errors.length > 0 && (
              <Block
                title={<span>Dossiers KO ({day.errors.length})</span>}
                color="danger">
                <Table responsive hover striped>
                  <thead>
                    <tr>
                      <th className="width-auto">Numéro dossier</th>
                      <th className="width-auto">Créé dans ADEL</th>
                      <th>Erreurs / Warnings</th>
                      <th className="width-auto">Détails</th>
                    </tr>
                  </thead>
                  <tbody>
                    {day.errors.map((medicalStay) => (
                      <tr key={`medicalStay_${medicalStay.id}`}>
                        <td>{medicalStay.medicalStayGapId}</td>
                        <td className="text-center">
                          {medicalStay.createdInAdel && (
                            <Badge color="success">Oui</Badge>
                          )}
                          {!medicalStay.createdInAdel && (
                            <Badge color="danger">Non</Badge>
                          )}
                        </td>
                        <td>
                          <ul>
                            {medicalStay.lastMessage.infos != null &&
                              medicalStay.lastMessage.infos.map((info) => (
                                <li
                                  key={
                                    medicalStay.medicalStayGapId + '_' + info
                                  }>
                                  <FontAwesomeIcon
                                    className="text-info"
                                    icon="info-circle"
                                  />{' '}
                                  {this.getErrorDescription(info)}
                                </li>
                              ))}

                            {medicalStay.lastMessage.warnings != null &&
                              medicalStay.lastMessage.warnings.map(
                                (warning, i) => (
                                  <li
                                    key={`${medicalStay.medicalStayGapId}_warning _${i}`}>
                                    <FontAwesomeIcon
                                      className="text-warning"
                                      icon="exclamation-triangle"
                                    />{' '}
                                    {this.getErrorDescription(warning)}
                                  </li>
                                ),
                              )}

                            {medicalStay.lastMessage.errors != null &&
                              medicalStay.lastMessage.errors.map((error, i) => (
                                <li
                                  key={`${medicalStay.medicalStayGapId}_error _${i}`}>
                                  <FontAwesomeIcon
                                    className="text-danger"
                                    icon="exclamation-circle"
                                  />{' '}
                                  {this.getErrorDescription(error)}
                                </li>
                              ))}
                          </ul>
                        </td>
                        <td className="text-center" color="info">
                          <Button
                            size="sm"
                            onClick={() => this.showDetails(medicalStay)}>
                            <FontAwesomeIcon icon="info-circle" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Block>
            )}

            {day.warnings.length > 0 && (
              <Block
                title={<span>Dossiers alerte ({day.warnings.length})</span>}
                color="warning">
                <Table responsive hover striped>
                  <thead>
                    <tr>
                      <th className="width-auto">Numéro dossier</th>
                      <th className="width-auto">Créé dans ADEL</th>
                      <th>Erreurs / Warnings</th>
                      <th className="width-auto">Détails</th>
                    </tr>
                  </thead>
                  <tbody>
                    {day.warnings.map((medicalStay) => (
                      <tr key={medicalStay.id}>
                        <td>{medicalStay.medicalStayGapId}</td>
                        <td className="text-center">
                          {medicalStay.createdInAdel && (
                            <Badge color="success">Oui</Badge>
                          )}
                          {!medicalStay.createdInAdel && (
                            <Badge color="danger">Non</Badge>
                          )}
                        </td>
                        <td>
                          <ul>
                            {medicalStay.lastMessage.infos != null &&
                              medicalStay.lastMessage.infos.map((info) => (
                                <li
                                  key={
                                    medicalStay.medicalStayGapId + '_' + info
                                  }>
                                  <FontAwesomeIcon
                                    className="text-info"
                                    icon="info-circle"
                                  />{' '}
                                  {this.getErrorDescription(info)}
                                </li>
                              ))}

                            {medicalStay.lastMessage.warnings != null &&
                              medicalStay.lastMessage.warnings.map(
                                (warning) => (
                                  <li
                                    key={
                                      medicalStay.medicalStayGapId +
                                      '_' +
                                      warning
                                    }>
                                    <FontAwesomeIcon
                                      className="text-warning"
                                      icon="exclamation-triangle"
                                    />{' '}
                                    {this.getErrorDescription(warning)}
                                  </li>
                                ),
                              )}

                            {medicalStay.lastMessage.errors != null &&
                              medicalStay.lastMessage.errors.map((error) => (
                                <li
                                  key={
                                    medicalStay.medicalStayGapId + '_' + error
                                  }>
                                  <FontAwesomeIcon
                                    className="text-danger"
                                    icon="exclamation-circle"
                                  />{' '}
                                  {this.getErrorDescription(error)}
                                </li>
                              ))}
                          </ul>
                        </td>
                        <td className="text-center" color="info">
                          <Button
                            size="sm"
                            onClick={() => this.showDetails(medicalStay)}>
                            <FontAwesomeIcon icon="info-circle" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Block>
            )}

            {day.success.length > 0 && (
              <Block
                title={<span>Dossiers OK ({day.success.length})</span>}
                color="success"
                reduced>
                <Table responsive hover striped>
                  <thead>
                    <tr>
                      <th className="width-auto">Numéro dossier</th>
                      <th className="width-auto">Créé dans ADEL</th>
                      <th>Erreurs / Warnings</th>
                      <th className="width-auto">Détails</th>
                    </tr>
                  </thead>
                  <tbody>
                    {day.success.map((medicalStay) => (
                      <tr key={medicalStay.id}>
                        <td>{medicalStay.medicalStayGapId}</td>
                        <td className="text-center">
                          {medicalStay.createdInAdel && (
                            <Badge color="success">Oui</Badge>
                          )}
                          {!medicalStay.createdInAdel && (
                            <Badge color="danger">Non</Badge>
                          )}
                        </td>
                        <td>
                          <ul>
                            {medicalStay.lastMessage.infos != null &&
                              medicalStay.lastMessage.infos.map((info) => (
                                <li
                                  key={
                                    medicalStay.medicalStayGapId + '_' + info
                                  }>
                                  <FontAwesomeIcon
                                    className="text-info"
                                    icon="info-circle"
                                  />{' '}
                                  {this.getErrorDescription(info)}
                                </li>
                              ))}
                          </ul>
                        </td>
                        <td className="text-center" color="info">
                          <Button
                            size="sm"
                            onClick={() => this.showDetails(medicalStay)}>
                            <FontAwesomeIcon icon="info-circle" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Block>
            )}
          </Block>
        ))}

        <MedicalStayDetailsModal
          isOpen={this.state.detailsModalOpened}
          errorsDescriptions={this.props.errorsDescriptions}
          messages={this.state.detailsMessages}
          toggle={this.toggleDetails}
        />
      </>
    );
  }
}
