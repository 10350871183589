import { FormGroup, Label } from "reactstrap";
import { PropTypes } from "prop-types";
import Input from "sharedComponents/input/Input";
import React from "react";
import Select from "sharedComponents/select/Select";

export default class CreateOperatingMethodForm extends React.PureComponent {
  static propTypes = {
    protocols: PropTypes.arrayOf(PropTypes.shape({})),
    treatmentModes: PropTypes.array.isRequired,
    workflows: PropTypes.array.isRequired,
  };

  render() {
    return (
      <div>
        <FormGroup className="required">
          <Label>Mode de traitement</Label>
          <Select
            name="mdtCode"
            clearable
            searchable
            required
            options={this.props.treatmentModes.map((p) => {
              return { value: p.mdtCode, label: p.name };
            })}
          />
        </FormGroup>
        <FormGroup className="required">
          <Label>Code praticien</Label>
          <Input className="form-control" name="practitionerCode" required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Praticien</Label>
          <Input className="form-control" name="practitionerName" required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Protocole Ambulis</Label>
          <Select
            name="ambulisCareId"
            clearable
            searchable
            required
            options={this.props.protocols.map((p) => {
              return { value: p.id, label: p.name };
            })}
          />
        </FormGroup>
        <FormGroup className="required">
          <Label>Parcours</Label>
          <Select
            name="workflowId"
            clearable
            searchable
            required
            options={this.props.workflows.map((p) => {
              return { value: p.id, label: p.name };
            })}
          />
        </FormGroup>
      </div>
    );
  }
}
