import ApiClient from "admin/services/ApiClient";
import queryString from "query-string";

export default class HealthFormsApi {
  static getAll = (withFolders = false) => {
    let params = {};
    if (withFolders) {
      params.withFolders = null;
    }

    return ApiClient.get(`healthForms?${queryString.stringify(params)}`);
  }

  static get = (id) => ApiClient.get(`healthForms/${id}`);

  static add = (data) => ApiClient.post("healthForms", data, true);

  static edit = (id, data) => ApiClient.post(`healthForms/${id}`, data, true);

  static delete = (id) => ApiClient.delete(`healthForms/${id}`);
}
