import React from 'react';

import {NotificationManager} from 'react-notifications';
import _ from 'lodash';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table} from 'reactstrap';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Block from 'sharedComponents/block/Block';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import ErrorDescriptionForm from './errorDescriptionForm/ErrorDescriptionForm';

import QoDApi from 'admin/apis/qod.api';

export default class ErrorsDescriptionsPage extends React.PureComponent {
  state = {
    isLoading: false,
    loadingError: false,
    errors: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "QoD - Codes d'erreur",
      activeMenuKey: 'qod_errors',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        errors: null,
      },
      async () => {
        let nextState = {isLoading: false};
        let data = await QoDApi.getErrorsDescriptions().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.errors = _.sortBy(data, '');
        }

        this.setState(nextState);
      },
    );
  };

  handleCreateSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await QoDApi.createErrorDescription(
      formData.code,
      formData.description,
    ).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("Le code d'erreur a été créé", null);
    this.loadData();
    return Promise.resolve(true);
  };

  handleEditSubmit = async (originalFormData, editedFormData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await QoDApi.patchErrorDescription(
      originalFormData.code,
      editedFormData.description,
    ).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success("Le code d'erreur a été modifié", null);
    this.loadData();
    return Promise.resolve(true);
  };

  handleDelete = async (errorCode) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await QoDApi.deleteErrorDescription(errorCode).catch((e) => {
      ApiErrorsHelper.manage(e);
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success("Le code d'erreur a été supprimé", null);
      this.loadData();
      return Promise.resolve(true);
    }
  };

  render() {
    return (
      <Block title="Liste des codes d'erreur">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadData={this.loadData}
          loadingText="Chargement des codes d'erreur">
          <div className="float-end clearfix mb-2">
            <CreateButtonModal
              text="Nouveau code d'erreur"
              onSubmit={this.handleCreateSubmit}>
              <ErrorDescriptionForm createMode />
            </CreateButtonModal>
          </div>

          {this.state.errors && (
            <Table striped hover>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Description</th>
                  <th className="width-auto"></th>
                </tr>
              </thead>
              <tbody>
                {this.state.errors.map((e) => (
                  <tr key={e.code}>
                    <td>{e.code}</td>
                    <td>{e.description}</td>
                    <td className="width-auto">
                      <EditButtonModal
                        data={e}
                        title={`Modifier ${e.code}`}
                        onSubmit={this.handleEditSubmit}>
                        <ErrorDescriptionForm {...e} />
                      </EditButtonModal>
                      &nbsp;
                      <DeleteButton data={e.code} onConfirm={this.handleDelete}>
                        Voulez-vous réellement supprimer l'erreur suivante :
                        <div className="my-2 text-center small">
                          <div>
                            <strong>{e.code}</strong>
                          </div>
                          <div>
                            <i>{e.description}</i>
                          </div>
                        </div>
                        <strong>Cette action est irréversible.</strong>
                      </DeleteButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
