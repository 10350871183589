import {PropTypes} from 'prop-types';
import ElsanLogo from 'sharedComponents/elsanLogo/ElsanLogo';
import React from 'react';

import SideMenuItem, {SideMenuItemProps} from './sideMenuItem/SideMenuItem';

import './SideMenu.scss';

export const SideMenuProps = PropTypes.arrayOf(
  PropTypes.shape({
    ...SideMenuItemProps,
    subItems: PropTypes.arrayOf(SideMenuItemProps),
  }),
);

export default class SideMenu extends React.PureComponent {
  static props = {
    items: SideMenuProps,
    routesPrefix: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    topContent: PropTypes.node,
  };

  render() {
    const {title, subtitle, topContent, routesPrefix, items} = this.props;
    return (
      <div className="sideMenuContent">
        <div className="sideMenuTitle px-2 pt-2">
          <ElsanLogo />
          <span className="ms-2 sideMenuText">{title}</span>
        </div>
        <div className="sideMenuSubtitle mb-4 px-2">{subtitle}</div>

        {topContent && <div className="mb-2">{topContent}</div>}

        <ul className="list-unstyled pt-2 sideMenuItemsContainer">
          {items.map((i) => (
            <SideMenuItem {...i} key={i.menuKey} routePrefix={routesPrefix} />
          ))}
        </ul>
      </div>
    );
  }
}
