import { BrowserRouter, Switch } from "react-router-dom";
import AdminPrivateLayout from "admin/AdminPrivateLayout";
import ClinicPrivateLayout from "clinic/ClinicPrivateLayout";
import ErrorsLayout from "layouts/errorsLayout/ErrorsLayout";
import PublicLayout from "layouts/publicLayout/PublicLayout";
import React from "react";
import ScrollToTop from "sharedComponents/scrollToTop/ScrollToTop";

import AdminRoutes from "./AdminRoutes";
import ClinicAdminRoutes from "./ClinicAdminRoutes";
import CommonRoutes from "./CommonRoutes";

export default class Router extends React.PureComponent {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          {AdminRoutes.private.map((route) => (
            <AdminPrivateLayout
              {...{ ...route, path: `/admin${route.path}` }}
            />
          ))}

          {AdminRoutes.public.map((route) => (
            <PublicLayout {...{ ...route, path: `/admin${route.path}` }} />
          ))}

          {ClinicAdminRoutes.private.map((route) => (
            <ClinicPrivateLayout
              {...{ ...route, path: `/clinic${route.path}` }}
            />
          ))}

          {ClinicAdminRoutes.public.map((route) => (
            <PublicLayout {...{ ...route, path: `/clinic${route.path}` }} />
          ))}

          {CommonRoutes.map((route) => (
            <ErrorsLayout {...route} />
          ))}
        </Switch>
      </BrowserRouter>
    );
  }
}
