import React from 'react';

import {NotificationManager} from 'react-notifications';
import _ from 'lodash';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Alert, Table} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import LinkSpecialitiesForm from './linkSpecialitiesForm/LinkSpecialitiesForm';

import BookingApi from 'admin/apis/booking.api';

export default class InternalSpecialitiesPage extends React.PureComponent {
  state = {
    isLoading: false,
    loadingError: false,
    links: null,

    linksWithoutExternalSpeciality: null,
    linksWithoutInternalSpeciality: null,
    correctLinks: null,
    allExternalSpecialities: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'EasyPrat - Correspondances des spécialités',
      activeMenuKey: 'internalBookingSpecialities',
    });

    this.loadData();
  }

  loadData = async () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        linksWithoutExternalSpeciality: null,
        linksWithoutInternalSpeciality: null,
        correctLinks: null,
        allExternalSpecialities: null,
      },
      async () => {
        let nextState = {isLoading: false};
        let data = await Promise.all([
          BookingApi.getBookingSpecialities(),
          BookingApi.getInternalSpecialities(true),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          const bookingSpecialities = data[0];
          const internalBookingSpecialities = data[1];

          let correctLinks = [];
          let linksWithoutExternalSpeciality = [];
          let linkedBookingSpecialitiesIds = [];

          // internal specialities (linked & not linked)
          for (let internalSpeciality of internalBookingSpecialities) {
            const bookingSpe = _.find(
              bookingSpecialities,
              (s) => s.externalId === internalSpeciality.externalId,
            );

            const link = {
              internalSpeciality: internalSpeciality,
              externalSpeciality: bookingSpe,
            };

            if (bookingSpe) {
              correctLinks.push(link);
              linkedBookingSpecialitiesIds.push(bookingSpe.id);
            } else {
              linksWithoutExternalSpeciality.push(link);
            }
          }

          // external specialities ( not linked ) & all external specialities with isLinked property
          let linksWithoutInternalSpeciality = [];
          for (let spe of bookingSpecialities) {
            spe.isLinked = linkedBookingSpecialitiesIds.includes(spe.id);
            if (!spe.isLinked) {
              linksWithoutInternalSpeciality.push({
                internalSpeciality: null,
                externalSpeciality: spe,
              });
            }
          }

          nextState.linksWithoutExternalSpeciality = _.orderBy(
            linksWithoutExternalSpeciality,
            'internalSpeciality.name',
          );
          nextState.linksWithoutInternalSpeciality = _.orderBy(
            linksWithoutInternalSpeciality,
            'externalSpeciality.name',
          );
          nextState.correctLinks = _.orderBy(
            correctLinks,
            'internalSpeciality.name',
          );

          nextState.allExternalSpecialities = _.orderBy(
            bookingSpecialities,
            'name',
          );
        }

        this.setState(nextState);
      },
    );
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await BookingApi.deleteInternalSpeciality(id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Correspondance supprimée avec succès', null);
      this.loadData();
    }
  };

  onEdit = async (link, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await (link.internalSpeciality
      ? BookingApi.editInternalSpeciality(link.internalSpeciality.id, formData)
      : BookingApi.createInternalSpeciality(formData)
    ).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success('Correspondance modifiée avec succès', null);
    this.loadData();
    return Promise.resolve();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>spécialités internes</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.correctLinks) return null;

    const links = [
      ...this.state.linksWithoutInternalSpeciality,
      ...this.state.linksWithoutExternalSpeciality,
      ...this.state.correctLinks,
    ];

    return (
      <div className="internalSpecialitiesPage">
        {this.state.linksWithoutExternalSpeciality.length > 0 && (
          <Alert color="danger">
            Des spécialités internes ne sont liées à aucune spécialité EasyPrat.
          </Alert>
        )}
        {this.state.linksWithoutInternalSpeciality.length > 0 && (
          <Alert color="danger">
            Des spécialités EasyPrat ne sont liées à aucune spécialité interne.
          </Alert>
        )}
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Spécialité EasyPrat</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {links.map((link, index) => {
              const canBeDeleted =
                link.internalSpeciality &&
                (!link.internalSpeciality.preventionChecks ||
                  link.internalSpeciality.preventionChecks.length === 0);

              return (
                <tr
                  key={index}
                  className={
                    !link.internalSpeciality || !link.externalSpeciality
                      ? 'table-warning'
                      : ''
                  }>
                  <td>
                    {link.internalSpeciality ? (
                      link.internalSpeciality.name
                    ) : (
                      <i>Aucune</i>
                    )}
                  </td>
                  <td>
                    {link.externalSpeciality ? (
                      link.externalSpeciality.name
                    ) : (
                      <i>Aucune</i>
                    )}
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonModal
                      data={link}
                      title="Modifier une correspondance"
                      tooltip="Modifier une correspondance"
                      onSubmit={this.onEdit}>
                      <LinkSpecialitiesForm
                        {...link}
                        externalSpecialities={
                          this.state.allExternalSpecialities
                        }
                      />
                    </EditButtonModal>
                    {link.internalSpeciality && (
                      <span className="ms-2">
                        <DeleteButton
                          data={link.internalSpeciality.id}
                          title={`Supprimer ${link.internalSpeciality.name}`}
                          tooltip={
                            canBeDeleted
                              ? `Supprimer ${link.internalSpeciality.name}`
                              : 'Impossible de supprimer cette spécialitée car elle est rattachée à un contrôle de prévention.'
                          }
                          onConfirm={this.onDelete}
                          disabled={!canBeDeleted}>
                          Voulez-vous réellement supprimer la spécialité interne{' '}
                          <i>{link.internalSpeciality.name}</i> ?
                        </DeleteButton>
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
