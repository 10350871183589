import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import React from 'react';

import Block from 'sharedComponents/block/Block';
import GeneralTab from './generalTab/GeneralTab';
import NotificationsTab from './notificationsTab/NotificationsTab';
import ProtocolsTab from './protocolsTab/ProtocolsTab';
import TreatmentModesTab from './treatmentModesTab/TreatmentModesTab';
import ManagementRulesTab from './managementRulesTab/ManagementRulesTab';

export default class EditClinicPage extends React.PureComponent {
  state = {
    activeTab: window.location.hash
      ? window.location.hash.substr(1)
      : 'general',
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des établissements',
      activeMenuKey: 'clinics',
    });
  }

  goToGeneralTab = () => {
    this.setState({
      activeTab: 'general',
    });

    this.props.history.replace('#general');
  };

  goToProtocolsTab = () => {
    this.setState({
      activeTab: 'protocols',
    });

    this.props.history.replace('#protocols');
  };

  goToTreatmentModesTab = () => {
    this.setState({
      activeTab: 'mdt',
    });

    this.props.history.replace('#mdt');
  };

  goToNotificationsTab = () => {
    this.setState({
      activeTab: 'notifications',
    });

    this.props.history.replace('#notifications');
  };

  goToManagementRulesTab = () => {
    this.setState({
      activeTab: 'managementRules',
    });

    this.props.history.replace('#managementRules');
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Modification d'un <strong>établissement</strong>
          </span>
        }>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'general',
              })}
              onClick={this.goToGeneralTab}>
              Général
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'protocols',
              })}
              onClick={this.goToProtocolsTab}>
              Protocoles
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'mdt',
              })}
              onClick={this.goToTreatmentModesTab}>
              Codes MDT
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'notifications',
              })}
              onClick={this.goToNotificationsTab}>
              Notifications
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'managementRules',
              })}
              onClick={this.goToManagementRulesTab}>
              Règles de gestion
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="general">
            <GeneralTab
              clinicId={this.id}
              layoutContextProvider={this.props.layoutContextProvider}
            />
          </TabPane>
          <TabPane tabId="protocols">
            <ProtocolsTab
              clinicId={this.id}
              layoutContextProvider={this.props.layoutContextProvider}
            />
          </TabPane>
          <TabPane tabId="mdt">
            <TreatmentModesTab
              clinicId={this.id}
              layoutContextProvider={this.props.layoutContextProvider}
            />
          </TabPane>
          <TabPane tabId="notifications">
            <NotificationsTab
              clinicId={this.id}
              layoutContextProvider={this.props.layoutContextProvider}
            />
          </TabPane>
          <TabPane tabId="managementRules">
            <ManagementRulesTab
              clinicId={this.id}
              layoutContextProvider={this.props.layoutContextProvider}
            />
          </TabPane>
        </TabContent>
      </Block>
    );
  }
}
