import React from 'react';

import _ from 'lodash';

import {FormGroup, Label} from 'reactstrap';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Select from 'sharedComponents/select/Select';
import Checkbox from 'sharedComponents/checkbox/Checkbox';

import PreOpInstructionsApi from 'clinic/apis/preOpInstructions.api';
import PostOpInstructionsApi from 'clinic/apis/postOpInstructions.api';
import OperatingMethodsApi from 'clinic/apis/operatingMethods.api';

export default class CreateOperatingMethodForm extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    ambulisProtocols: null,
    preopInstructions: null,
    postopInstructions: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: null,
        ambulisProtocols: null,
        preopInstructions: null,
        postopInstructions: null,
      },
      async () => {
        let nextState = {isLoading: false};

        const data = await Promise.all([
          OperatingMethodsApi.getAvailableAmbulisProtocols(),
          PreOpInstructionsApi.getAll(),
          PostOpInstructionsApi.getAll(),
        ]).catch((error) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          let ambuProtocole = data[0].map((p) => {
            return {value: p.id, label: p.name};
          });

          nextState.ambulisProtocols = _.sortBy(ambuProtocole, 'label');

          let preop = data[1].map((p) => {
            return {value: p.id, label: p.title};
          });

          nextState.preopInstructions = _.sortBy(preop, 'label');

          let postop = data[2].map((p) => {
            return {value: p.id, label: p.title};
          });

          nextState.postopInstructions = _.sortBy(postop, 'label');
        }

        this.setState(nextState);
      },
    );
  };

  render() {
    return (
      <div className="createConvocationPlaceForm">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}>
          {this.state.ambulisProtocols && (
            <div>
              <FormGroup className="required">
                <Label>Protocole</Label>
                <Select
                  name="ambulisId"
                  clearable
                  searchable
                  required
                  options={this.state.ambulisProtocols}
                />
              </FormGroup>
              <FormGroup className="required">
                <Label>Consignes préopératoires</Label>
                <Select
                  name="preopInstructionsId"
                  clearable
                  searchable
                  required
                  options={this.state.preopInstructions}
                />
              </FormGroup>
              <FormGroup>
                <Label>Consignes postopératoires</Label>
                <Select
                  name="postopInstructionsId"
                  clearable
                  searchable
                  options={this.state.postopInstructions}
                />
              </FormGroup>

              <FormGroup>
                <Label>Affichage de l'heure de convocation</Label>
                <Checkbox
                  name="displayConvocationDate"
                  label="Oui"
                  id="displayConvocationDate"
                />
              </FormGroup>
            </div>
          )}
        </PageDataLoader>
      </div>
    );
  }
}
