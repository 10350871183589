import {Alert, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PropTypes} from 'prop-types';
import React from 'react';
import StringHelper from 'helpers/StringHelper';

export default class LoadingError extends React.PureComponent {
  static propTypes = {
    onRetry: PropTypes.func,
    error: PropTypes.any.isRequired,
  };

  static defaultProps = {};

  state = {
    minified: true,
  };

  toggleMinified = () =>
    this.setState((prevState) => {
      return {minified: !prevState.minified};
    });

  onClick = () => {
    if (this.props.onRetry) {
      this.props.onRetry();
    }
  };

  render() {
    let message =
      this.props.error &&
      typeof this.props.error === 'object' &&
      'message' in this.props.error
        ? this.props.error.message
        : typeof this.props.error === 'string'
        ? this.props.error
        : '';

    return (
      <div className="loadingError">
        <Alert color="danger" className="clearfix">
          {process.env.NODE_ENV === 'development' && (
            <Button
              color="link"
              onClick={this.toggleMinified}
              className="float-end p-0">
              <i className="small me-2">
                {this.state.minified ? 'Voir' : 'Cacher'}
              </i>
              <FontAwesomeIcon
                icon={this.state.minified ? 'chevron-down' : 'chevron-up'}
              />
            </Button>
          )}
          Une erreur est survenue{' '}
          {!StringHelper.isNullOrEmpty(message) && (
            <span>
              : <i>{message}</i>
            </span>
          )}
          {process.env.NODE_ENV === 'development' && (
            <div className="small clearfix">
              {!this.state.minified && (
                <div>
                  {typeof this.props.error === 'object'
                    ? this.renderObjectContent(this.props.error)
                    : this.props.error}
                </div>
              )}
            </div>
          )}
        </Alert>
        {this.props.onRetry && typeof this.props.onRetry === 'function' && (
          <Button color="primary" onClick={this.onClick} block>
            <FontAwesomeIcon icon="sync-alt" />
            &nbsp;Réessayer
          </Button>
        )}
      </div>
    );
  }

  renderObjectContent(objectBody) {
    let content = [];

    let i = 0;
    for (let propertyName in objectBody) {
      content.push(
        <li key={`${i}-${propertyName}`}>
          <strong>{propertyName} : </strong>
          <i>
            {typeof objectBody[propertyName] === 'object'
              ? this.renderObjectContent(objectBody[propertyName])
              : objectBody[propertyName]}
          </i>
        </li>,
      );
    }

    return <ul className="list-unstyled ms-2">{content}</ul>;
  }
}
