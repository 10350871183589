import ApiClient from "admin/services/ApiClient";

export default class PagesApi {
  static getAll = () => ApiClient.get("pages");

  static create = (formData) => ApiClient.post("pages", formData, true);

  static get = (id) => ApiClient.get(`pages/${id}`);

  static editGeneral = (id, data) =>
    ApiClient.post(`pages/${id}/general`, data, true);

  static delete = (id) => ApiClient.delete(`pages/${id}`);

  static addBlock = (id, type, data) =>
    ApiClient.post(`pages/${id}/blocks/${type}`, data, true);

  static moveBlock = (pageId, blockId, data) =>
    ApiClient.patch(`pages/${pageId}/blocks/${blockId}/order`, data);

  static editBlock = (pageId, blockId, data) =>
    ApiClient.post(`pages/${pageId}/blocks/${blockId}/content`, data, true);

  static deleteBlock = (pageId, blockId) =>
    ApiClient.delete(`pages/${pageId}/blocks/${blockId}`);
}
