import React from 'react';
import {PropTypes} from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Row, Col, Badge} from 'reactstrap';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import ArticlesList from './articlesList/ArticlesList';
import CategoryForm from '../categoryForm/CategoryForm';

export default class CategoryItem extends React.PureComponent {
  static propTypes = {
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      articles: PropTypes.array,
    }),

    onEditSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDeleteArticle: PropTypes.func.isRequired,
  };

  render() {
    const {category, onEditSubmit, onDelete, onDeleteArticle} = this.props;

    const canBeDeleted = !category.articles || category.articles.length === 0;

    return (
      <div>
        <Row className="align-items-center">
          <Col xs="auto">
            <FontAwesomeIcon icon="arrows-alt" />
          </Col>
          <Col>
            <strong>{category.title}</strong>
          </Col>
          {category.order === 1 && (
            <Col xs="auto" style={{width: '100px'}}>
              <Badge color="success">Défaut</Badge>
            </Col>
          )}
          <Col xs="auto" style={{width: '110px', textAlign: 'right'}}>
            <EditButtonModal
              title="Modifier une catégorie"
              data={category}
              onSubmit={onEditSubmit}
              className="me-1"
              tooltip={`Modifier ${category.title}`}>
              <CategoryForm category={category} />
            </EditButtonModal>
            {canBeDeleted && (
              <DeleteButton
                title={
                  <span>
                    Supprimer <i>{category.title}</i>
                  </span>
                }
                data={category.id}
                onConfirm={onDelete}
                className="ms-1"
                tooltip={`Supprimer ${category.title}`}>
                Voulez-vous réellement supprimer la catégorie{' '}
                <i>{category.title}</i> ?
              </DeleteButton>
            )}
          </Col>
        </Row>
        <div className="mx-4 small">
          <hr />
          <strong>Articles</strong>
          <div className="mx-4">
            <ArticlesList
              categoryId={category.id}
              healthFolderId={category.healthFolderId}
              articles={category.articles}
              onDelete={onDeleteArticle}
            />
          </div>
        </div>
      </div>
    );
  }
}
