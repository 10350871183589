import React from "react";
import { PropTypes } from "prop-types";

import ApiErrorsHelper from "helpers/ApiErrorsHelper";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidationForm from "sharedComponents/validationForm/ValidationForm";

export default class EditButtonModal extends React.PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    children: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onSubmit: PropTypes.func,
    largeModal: PropTypes.bool,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    title: "Modifier un élément",
    largeModal: false,
    tooltip: "Modifier",
  };

  state = {
    modalVisible: false,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleModal = () => {
    if (this._isMounted) {
      this.setState((prevState) => {
        return { modalVisible: !prevState.modalVisible };
      });
    }
  };

  handleSubmit = async (formData) => {
    if (this.props.onSubmit && typeof this.props.onSubmit === "function") {
      let success = true;

      await this.props.onSubmit(this.props.data, formData).catch((error) => {
        ApiErrorsHelper.manage(error, this.form);
        success = false;
      });

      if (success) {
        this.toggleModal();
      }
    }
  };

  render() {
    const {
      title,
      children,
      onSubmit,
      data,
      largeModal,
      tooltip,
      ...otherProps
    } = this.props;

    return (
      <span className="editButtonContainer">
        <Button
          color="secondary"
          size="sm"
          onClick={this.toggleModal}
          title={tooltip}
          {...otherProps}
        >
          <FontAwesomeIcon icon="pen" />
        </Button>
        <Modal
          isOpen={this.state.modalVisible}
          toggle={this.toggleModal}
          size={largeModal ? "lg" : "md"}
        >
          <ModalHeader toggle={this.toggleModal}>{title}</ModalHeader>
          <ValidationForm ref={this.form} onSubmit={this.handleSubmit}>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.toggleModal}>
                Annuler
              </Button>
              <Button color="primary" type="submit">
                Valider
              </Button>
            </ModalFooter>
          </ValidationForm>
        </Modal>
      </span>
    );
  }
}
