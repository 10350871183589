import React from 'react';
import {PropTypes} from 'prop-types';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';

import {FormGroup, Label, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import HealthFoldersApi from 'admin/apis/healthFolders.api';

export class GeneralTab extends React.PureComponent {
  static propTypes = {
    healthFolder: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    let error = false;

    await HealthFoldersApi.edit(this.props.healthFolder.id, formData).catch(
      (e) => {
        ApiErrorsHelper.manage(e, this.form);
        error = true;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success(
        'Le dossier santé a été modifié avec succès',
        null,
      );
    }
  };

  render() {
    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Titre</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="title"
              value={this.props.healthFolder.title}
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Texte brique</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="brickText"
              value={this.props.healthFolder.brickText}
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">Image brique</Label>
          <div className="col-sm-10">
            <ImageUploader
              name="brickImage"
              helpText="Taille attendue : 1000px par 567px"
              actualUrl={this.props.healthFolder.brickImageUrl}
            />
          </div>
        </FormGroup>

        <FormGroup className="row required">
          <Label className="col-form-label col-sm-2">Introduction</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="introduction"
              type="textarea"
              value={this.props.healthFolder.introduction}
              required
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-2">URL Chatbot</Label>
          <div className="col-sm-10">
            <Input
              className="form-control"
              name="chatbotUrl"
              value={this.props.healthFolder.chatbotUrl}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label pt-0 col-sm-2" for="onHomePage">
            Page d'accueil
          </Label>
          <div className="col-sm-10">
            <Checkbox
              id="onHomePage"
              name="onHomePage"
              label="Oui"
              checked={this.props.healthFolder.onHomePage}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label pt-0 col-sm-2" for="published">
            Publié
          </Label>
          <div className="col-sm-10">
            <Checkbox
              id="published"
              name="published"
              label="Oui"
              checked={this.props.healthFolder.published}
            />
          </div>
        </FormGroup>

        <FormGroup className="text-end">
          <Link
            to="/admin/healthFolders/folders"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(GeneralTab);
