import { PropTypes } from 'prop-types'
import React from 'react'
import './TextBlockRenderer.scss'

export default class TextBlockRenderer extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    trunk: PropTypes.bool
  }

  static defaultProps = {
    trunk: false
  }

  render () {
    return (
      <div
        className={`textBlockRenderer ${this.props.trunk ? 'trunk' : ''}`}
        dangerouslySetInnerHTML={{ __html: this.props.data.text }}
      />
    )
  }
}
