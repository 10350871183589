import React from 'react';

import {NotificationManager} from 'react-notifications';
import {Table} from 'reactstrap';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import Block from 'sharedComponents/block/Block';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import CreateForm from './createForm/CreateForm';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import EditForm from './editForm/EditForm';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import ConvocationPlacesLinksApi from 'clinic/apis/convocationPlacesLinks.api';

export default class ConvocationPlacesLinksPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,

    convocationPlacesLinks: [],
    protocols: [],
    treatmentModes: [],
    workflows: [],
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des lieux de convocation',
      activeMenuKey: 'convocationPlacesLinks',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState({isLoading: true, loadingError: null}, async () => {
      let nextState = {isLoading: false};

      let convocationPlacesLinks =
        await ConvocationPlacesLinksApi.getAll().catch((error) => {
          nextState.loadingError = true;
          return null;
        });

      if (convocationPlacesLinks) {
        nextState.convocationPlacesLinks = convocationPlacesLinks;
      }

      this.setState(nextState);
    });
  };

  onCreate = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let createdPlace = await ConvocationPlacesLinksApi.create(formData).catch(
      (e) => {
        error = e;
        return null;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (createdPlace) {
      NotificationManager.success('Correspondance créée', null);
      this.loadData();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };

  onEdit = async (originalLink, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await ConvocationPlacesLinksApi.edit(originalLink.id, formData).catch(
      (e) => {
        error = e;
      },
    );

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Correspondance modifiée', null);
      this.loadData();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };

  onDelete = async (operation) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await ConvocationPlacesLinksApi.delete(operation.id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Association supprimée', null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Table de <strong>correspondance</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement de la table de corresondance"
          loadingError={this.state.loadingError}>
          {this.state.convocationPlacesLinks && (
            <div>
              <div className="clearfix">
                <CreateButtonModal
                  text="Nouvelle correspondance"
                  className="float-end mb-2"
                  onSubmit={this.onCreate}>
                  <CreateForm />
                </CreateButtonModal>
              </div>
              <Table responsive hover striped>
                <thead>
                  <tr>
                    <td>Lit / Service</td>
                    <td>Lieu de convocation</td>
                    <td className="width-auto text-center">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.convocationPlacesLinks.map((c) => {
                    return (
                      <tr key={c.searchKey}>
                        <td>{c.searchKey}</td>
                        <td>{c.convocationPlace}</td>
                        <td className="width-auto text-center">
                          <EditButtonModal
                            data={c}
                            onSubmit={this.onEdit}
                            title="Modifier une correspondance"
                            className="me-1">
                            <EditForm convocationPlaceLink={c} />
                          </EditButtonModal>
                          <DeleteButton
                            data={c}
                            title="Supprimer une correspondance"
                            onConfirm={this.onDelete}
                            className="ms-1">
                            Voulez-vous réellement supprimer cette
                            correspondance ?
                          </DeleteButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
