import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import React from "react";

import "./DisconnectButton.scss";

export default class DisconnectButton extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func
  };

  state = {
    modalOpened: false
  };

  toggleModal = () =>
    this.setState(prevState => {
      return { modalOpened: !prevState.modalOpened };
    });

  disconnect = () => {
    this.props.onClick();
    this.toggleModal();
  };

  render() {
    return (
      <div className="disconnectButtonContainer">
        <Button
          onClick={this.toggleModal}
          color="link"
          className="mx-2 disconnectButton"
        >
          <FontAwesomeIcon icon="power-off" />
        </Button>

        <Modal isOpen={this.state.modalOpened} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Déconnexion</ModalHeader>
          <ModalBody>Voulez-vous réellement vous déconnecter ?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal} outline block>
              Annuler
            </Button>
            <Button color="danger" onClick={this.disconnect} block>
              Se déconnecter
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
