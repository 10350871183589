import React from "react";
import { PropTypes } from "prop-types";

import _ from "lodash";

import { Label, FormGroup } from "reactstrap";
import ImageUploader from "sharedComponents/imageUploader/ImageUploader";
import Input from "sharedComponents/input/Input";
import Select from "sharedComponents/select/Select";
import CreateButtonModal from "sharedComponents/buttons/createButtonModal/CreateButtonModal";

export default class AddCategoryButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    maps: PropTypes.array.isRequired,
  };

  state = {
    mapId: null,
  };

  onSubmit = (formData) => {
    return this.props.onSubmit(formData);
  };

  addMapOnChange = (mapId) => {
    this.setState({ mapId: mapId });
  };

  render() {
    const mapsOptions = _.sortBy(this.props.maps, "title").map((m) => ({
      value: m.id,
      label: `${m.title}`,
    }));

    return (
      <CreateButtonModal
        text="Nouvelle catégorie"
        icon="plus-circle"
        onSubmit={this.onSubmit}
      >
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input type="text" name="title" required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Image (bleue)</Label>
          <ImageUploader
            name="picture"
            helpText="Taille attendue :  500px par 500px"
            required
          />
        </FormGroup>

        <FormGroup className="required">
          <Label>Image alt. (blanche)</Label>
          <ImageUploader
            whiteImage
            name="pictureAlt"
            helpText="Taille attendue :  500px par 500px"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label className="col-form-label">Cartes interactives</Label>
          <Select
            id="mapId"
            name="mapId"
            placeholder="Choisir une carte interactive"
            searchable
            onChange={this.addMapOnChange}
            options={mapsOptions}
          />
        </FormGroup>

        {this.state.mapId !== null && (
          <FormGroup className="required">
            <Label className="col-form-label">CTA carte</Label>
            <Input className="form-control" name="mapCTA" required />
          </FormGroup>
        )}
      </CreateButtonModal>
    );
  }
}
