import React from 'react';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import Authentication from 'admin/services/Authentication';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, FormGroup, Row, Col, Label, Button} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Select from 'sharedComponents/select/Select';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ClinicAdminsApi from 'admin/apis/clinicAdmins.api';
import ClinicApi from 'admin/apis/clinics.api';

export default class ClinicAdminsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    clinicAdmins: null,
    clinics: null,
    clinicId: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des admins cliniques',
      activeMenuKey: 'clinicAdmins',
    });

    this.loadData(this.state.clinicId);
  }

  loadData = (clinicId) => {
    this.setState(
      {isLoading: true, loadingError: false, clinicAdmins: null},
      async () => {
        let nextState = {isLoading: false};
        let data = await Promise.all([
          ClinicAdminsApi.getAll(clinicId),
          ClinicApi.getAll(),
        ]).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.clinicAdmins = _.orderBy(data[0], 'name');
          nextState.clinics = _.orderBy(data[1], 'name');
        }

        this.setState(nextState);
      },
    );
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await ClinicAdminsApi.delete(id).catch((e) => {
      ApiErrorsHelper.manage(e);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Administrateur supprimé avec succès', null);
      this.loadData(this.state.clinicId);
    }
  };

  onSubmit = (formData) => {
    this.setState({clinicId: formData.clinic});

    this.loadData(formData.clinic);
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>admin cliniques</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des cliniques">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.clinicAdmins) return null;

    const clinicsOptions = this.state.clinics
      ? this.state.clinics.map((c) => ({
          label: `${c.shortName} - ${c.name}`,
          value: c.id,
        }))
      : [];

    return (
      <div>
        <ValidationForm onSubmit={this.onSubmit}>
          <Row>
            <Col sm={4}>
              <Label>Établissement</Label>
              <FormGroup>
                <Select
                  id="clinic"
                  name="clinic"
                  searchable
                  placeholder="Tous les établissements"
                  options={clinicsOptions}
                />
              </FormGroup>
            </Col>
          </Row>

          <Button type="submit" color="primary">
            Valider
          </Button>
        </ValidationForm>

        <FormGroup className="text-end">
          <CreateButtonLink
            to="clinicAdmins/add"
            text="Nouvel admin clinique"
          />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className="width-auto">Email</th>
              <th>Cliniques</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.clinicAdmins.map((admin) => {
              return (
                <tr key={admin.id}>
                  <td>{admin.name}</td>
                  <td className="width-auto">{admin.email}</td>
                  <td>
                    <ul className="list-unstyled p-0">
                      {admin.healthFacilities.map((c) => (
                        <li key={`${admin.id}_${c.id}`}>{c.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonLink to={`clinicAdmins/${admin.id}`} />

                    {Authentication.user.id !== admin.id && (
                      <DeleteButton
                        title={
                          <span>
                            Supprimer <i>{admin.name}</i>
                          </span>
                        }
                        data={admin.id}
                        onConfirm={this.onDelete}
                        className="ms-1">
                        Voulez-vous réellement supprimer <i>{admin.name}</i> ?
                      </DeleteButton>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
