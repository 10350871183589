import { FormText } from "reactstrap";
import { PropTypes } from "prop-types";
import { withFormsy } from "formsy-react";
import fr from "date-fns/locale/fr";
import moment from "moment";
import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import ValidationErrors from "services/ValidationErrors";

import "react-datepicker/dist/react-datepicker.css";

import "./DatePicker.scss";

registerLocale("fr", fr);
const FnsDateFormat = "dd/MM/yyyy";

class DatePicker extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    min: PropTypes.object,
    max: PropTypes.object,
    className: PropTypes.string,
    fullWidth: PropTypes.bool
  };

  static defaultProps = {
    fullWidth: false
  };

  changeValue = date => {
    this.props.setValue(date);

    if (this.props.onChange) {
      this.props.onChange(date);
    }
  };

  componentDidUpdate() {
    var date = this.props.value;
    let newDate = date;
    // check correct value
    if (this.props.max || this.props.min) {
      let asMoment = moment(date);
      if (this.props.max) {
        let max = moment(this.props.max);
        if (asMoment.isAfter(max)) {
          newDate = max.toDate();
        }
      }
      if (this.props.min) {
        let min = moment(this.props.min);
        if (asMoment.isBefore(min)) {
          newDate = min.toDate();
        }
      }
    }

    if (newDate !== date) {
      this.changeValue(newDate);
    }
  }

  render() {
    let max = this.props.max;
    if (max) {
      if (moment.isMoment(max)) {
        max = max.toDate();
      } else if (typeof max === "string") {
        max = new Date(max);
      }
    }

    let min = this.props.min;
    if (min) {
      if (moment.isMoment(min)) {
        min = min.toDate();
      } else if (typeof min === "string") {
        min = new Date(min);
      }
    }

    let value = this.props.value;
    if (value) {
      if (moment.isMoment(value)) {
        value = value.toDate();
      } else if (typeof value === "string") {
        value = new Date(value);
      }
    }

    return (
      <div
        className={`datePicker ${
          this.props.isPristine
            ? ""
            : this.props.isValid
            ? "valid"
            : "invalid"
        } ${this.props.fullWidth ? "fullWidth" : ""}`}
      >
        <ReactDatePicker
          id={this.id}
          name={this.name}
          selected={value}
          locale="fr"
          dateFormat={FnsDateFormat}
          onChange={this.changeValue}
          minDate={min}
          maxDate={max}
          valid={!this.props.isPristine && this.props.isValid}
          invalid={!this.props.isPristine && !this.props.isValid}
          className={this.props.className}
        />
        {this.props.helpText && (
          <FormText color="muted">{this.props.helpText}</FormText>
        )}
        {!this.props.isPristine && this.props.showRequired ? (
          <div className="invalid-feedback">{ValidationErrors.required}</div>
        ) : (
          <div className="invalid-feedback">{this.props.errorMessage}</div>
        )}
      </div>
    );
  }
}

export default withFormsy(DatePicker);
