import React from 'react';

import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import StringHelper from 'helpers/StringHelper';

import {FormGroup, Label, Button} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import Input from 'sharedComponents/input/Input';
import validationErrors from 'services/ValidationErrors';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import WorkflowsApi from 'clinic/apis/workflows.api';

export default class AddService extends React.PureComponent {
  state = {};

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.workflowId = +this.props.match.params.workflowId;
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des parcours / Nouveau service',
      activeMenuKey: 'workflows',
    });
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await WorkflowsApi.addService(this.workflowId, formData).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Le service a été créé', '');
      this.props.history.push(`/clinic/workflows/${this.workflowId}#services`);
    }
  };

  render() {
    return (
      <Block
        className="addServiceToWorkflow"
        title={
          <span>
            Ajout d'un <strong>service</strong>
          </span>
        }>
        <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Nom</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="name" required />
            </div>
          </FormGroup>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              Heures d'ouverture
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="workingHours"
                required
                type="textarea"
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Email de contact</Label>
            <div className="col-sm-10">
              <Input
                type="email"
                className="form-control"
                name="contactEmail"
                validations="isEmail"
                validationError={validationErrors.isEMail}
              />
            </div>
          </FormGroup>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Téléphone</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="contactPhoneNumber"
                required
                format={StringHelper.formatPhoneNumber}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Tarif</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="contactPhonePrice"
                helpText="Format attendu : Service 0,20 € / min + prix appel"
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Téléphone Bis</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="contactPhoneNumberBis"
                format={StringHelper.formatPhoneNumber}
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2" htmlFor="contactWebsite">
              Website
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="contactWebsite"
                id="contactWebsite"
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Contact principal</Label>
            <div className="col-sm-10">
              <Checkbox name="mainContact" id="mainContact" label="" />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Affiché en fin de questionnaire suivi
            </Label>
            <div className="col-sm-10">
              <Checkbox
                name="displayedAfterAmbulisForm"
                id="displayedAfterAmbulisForm"
                label=""
              />
            </div>
          </FormGroup>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Miniature</Label>
            <div className="col-sm-10">
              <ImageUploader
                name="smallPictureFile"
                id="smallPictureFile"
                helpText="Taille attendue : 464px par 234px"
                required
              />
            </div>
          </FormGroup>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Photo</Label>
            <div className="col-sm-10">
              <ImageUploader
                name="largePictureFile"
                id="largePictureFile"
                helpText="Taille attendue : 1125px par 788px"
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="text-end">
            <Link
              to={`/clinic/workflows/${this.workflowId}#services`}
              className="btn btn-outline-primary me-1"
              type="button">
              Retour à la liste
            </Link>
            <Button type="submit" color="primary">
              Valider
            </Button>
          </FormGroup>
        </ValidationForm>
      </Block>
    );
  }
}
