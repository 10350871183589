import React from 'react';
import _ from 'lodash';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Table, FormGroup, Badge} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import CreateButtonLink from 'sharedComponents/buttons/createButtonLink/CreateButtonLink';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import ClinicsApi from 'admin/apis/clinics.api';

export default class ClinicsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    clinics: null,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des établissements',
      activeMenuKey: 'clinics',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, clinics: null},
      async () => {
        let nextState = {isLoading: false};
        nextState.clinics = await ClinicsApi.getAll().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (nextState.clinics) {
          nextState.clinics = _.orderBy(nextState.clinics, 'shortName');
        }

        this.setState(nextState);
      },
    );
  };

  deleteClinic = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await ClinicsApi.delete(id).catch((error) => {
      ApiErrorsHelper.manage(error);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('Etablissement supprimé avec succès', null);
      this.loadData();
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>établissements</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des cliniques">
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.clinics) return null;

    return (
      <div className="ClinicsPage">
        <FormGroup className="text-end">
          <CreateButtonLink
            to="/admin/clinics/add"
            text="Nouvel établissement"
          />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th className="width-auto">Trigramme</th>
              <th>Nom</th>
              <th className="text-center width-auto">Déployé</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.clinics.map((clinic) => {
              return (
                <tr key={clinic.shortName}>
                  <td className="text-center">{clinic.shortName}</td>
                  <td>{clinic.name}</td>
                  <td className="width-auto text-center">
                    <Badge color={clinic.deployed ? 'success' : 'secondary'}>
                      {clinic.deployed ? 'Oui' : 'Non'}
                    </Badge>
                  </td>
                  <td className="width-auto text-center">
                    <EditButtonLink to={`/admin/clinics/${clinic.id}`} />
                    {/*Authentication.user.isSuperAdmin && (
                      <DeleteButton
                        title={
                          <span>
                            Supprimer <i>{clinic.name}</i>
                          </span>
                        }
                        data={clinic.healthFacilityId}
                        onConfirm={this.deleteClinic}
                        className="ms-1"
                        disabled={clinic.deployed}
                        tooltip={
                          clinic.deployed
                            ? "Impossible de supprimer un établissement déployé"
                            : "Supprimer"
                        }
                      >
                        Voulez-vous réellement supprimer{" "}
                        <i>{clinic.name}</i> ?
                      </DeleteButton>
                      )*/}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
