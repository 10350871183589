import React from 'react';

import {withRouter} from 'react-router-dom';
import Authentication from 'clinic/services/Authentication';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from 'reactstrap';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import Select from 'sharedComponents/select/Select';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import ClinicApi from 'clinic/apis/clinic.api';

import './ClinicChoice.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class ClinicChoice extends React.PureComponent {
  state = {
    modalOpened: false,
    isLoading: true,
    loadingError: false,
    clinics: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  toggleModal = () => {
    this.setState(
      (prevState) => ({modalOpened: !prevState.modalOpened}),
      () => {
        if (!this.state.clinics) {
          this.loadData();
        }
      },
    );
  };

  loadData = () => {
    this.setState({isLoading: true, loadingError: null}, async () => {
      let nextState = {
        isLoading: false,
      };
      let clinics = await ClinicApi.getAllAvailable().catch(() => {
        nextState.loadingError = false;
        return null;
      });

      nextState.clinics = clinics;
      this.setState(nextState);
    });
  };

  onSubmit = (formData) => {
    Authentication.changeClinic(formData.clinic);
    this.props.history.push('/clinic');
  };

  render() {
    const {modalOpened, isLoading, loadingError, clinics} = this.state;

    let options = clinics
      ? clinics.map((c) => ({
          label: `${c.shortName} - ${c.name}`,
          value: c,
        }))
      : [];

    let selectedClinic = null;
    if (Authentication.clinic && this.state.clinics) {
      for (let clinic of this.state.clinics) {
        if (clinic.id === Authentication.clinic.id) {
          selectedClinic = clinic;
          break;
        }
      }
    }

    return (
      <div>
        <Button
          color="link"
          block
          onClick={this.toggleModal}
          title="Changer de clinique"
          className="clinicChoice">
          <strong>
            <i>{Authentication.clinic?.name}</i>
          </strong>
          <FontAwesomeIcon icon="pen" className="ms-2" />
        </Button>
        <Modal isOpen={modalOpened} toggle={this.toggleModal} size="md">
          <ModalHeader toggle={this.toggleModal}>
            Changer d'établissement
          </ModalHeader>
          <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
            <ModalBody>
              <PageDataLoader
                loadingText="Chargement des établissements..."
                isLoading={isLoading}
                loadingError={loadingError}>
                <FormGroup className="required">
                  <Label>Clinique</Label>
                  <Select
                    id="clinic"
                    name="clinic"
                    placeholder="Choisir une clinique"
                    searchable
                    options={options}
                    value={selectedClinic}
                    required
                  />
                </FormGroup>
              </PageDataLoader>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.toggleModal}>
                Annuler
              </Button>
              <Button color="primary" type="submit">
                Valider
              </Button>
            </ModalFooter>
          </ValidationForm>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ClinicChoice);
