import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import validationErrors from 'services/ValidationErrors';

import {Link} from 'react-router-dom';
import {Button, FormGroup, Label} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import Input from 'sharedComponents/input/Input';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import EmergenciesApi from 'admin/apis/emergencies.api';

export default class AddEmergencyPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    step: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des urgences et CSNP',
      activeMenuKey: 'emergencies',
    });
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await EmergenciesApi.add(formData).catch((error) => {
      ApiErrorsHelper.manage(error, this.form);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("L'établissement a été créé", null);
      this.props.history.push('/admin/emergencies');
    }
  };

  render() {
    return (
      <Block
        title={
          <span>
            Création d'un <strong>centre d'urgence ou CSNP</strong>
          </span>
        }>
        <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
          <FormGroup className="row required">
            <Label className="col-sm-2">Nom</Label>
            <div className="col-sm-10">
              <Input name="name" required />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-sm-2">Numéro téléphone</Label>
            <div className="col-sm-10">
              <Input
                name="phoneNumber"
                helpText="N° obligatoirement non surtaxé"
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Destinataire et/ou service
            </Label>
            <div className="col-sm-10">
              <Input className="form-control" name="addressLine2" />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">Complément</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine3"
                helpText="Entrée, Tour, Immeuble, Bâtiment, Résidence, Zone industrielle, ..."
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">N° et voie</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="addressLine4" required />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Mentions spéciales
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="addressLine5"
                helpText="BP, Tri Service Arrivée, ..."
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">
              Code postal et ville
            </Label>
            <div className="col-sm-10">
              <Input className="form-control" name="addressLine6" required />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Pays</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="addressLine7" required />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Latitude</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="latitude"
                helpText="Utiliser un point comme séparateur décimal"
                required
                validations="isNumeric"
                validationError={validationErrors.isNumeric}
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Longitude</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="longitude"
                helpText="Utiliser un point comme séparateur décimal"
                required
                validations="isNumeric"
                validationError={validationErrors.isNumeric}
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">
              Temps d'attente moyen
            </Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="waitingTime"
                helpText="Saisir la durée ET l'unité correspondante"
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-sm-2">Autorisation Urgences</Label>
            <div className="col-sm-10">
              <Checkbox
                name="emergencyAuthorization"
                label="Oui"
                id="emergencyAuthorization"
                checked={false}
              />
            </div>
          </FormGroup>

          <FormGroup className="text-end">
            <Link
              to="/admin/emergencies"
              className="btn btn-outline-primary me-1"
              type="button">
              Retour à la liste
            </Link>
            <Button type="submit" color="primary">
              Valider
            </Button>
          </FormGroup>
        </ValidationForm>
      </Block>
    );
  }
}
