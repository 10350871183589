import {FormGroup, Label} from 'reactstrap';
import Input from 'sharedComponents/input/Input';
import React from 'react';

export default class CreateForm extends React.PureComponent {
  render() {
    return (
      <div>
        <FormGroup className="required">
          <Label>Lit / Service</Label>
          <Input
            className="form-control"
            name="searchKey"
            maxLength={25}
            required
          />
        </FormGroup>
        <FormGroup className="required">
          <Label>Lieu de convocation</Label>
          <Input
            className="form-control"
            name="convocationPlace"
            maxLength={255}
            helpText="Le lieu de convocation apparaîtra sur le SMS Patient, aussi penser à ajouter les pronoms correspondant : à l'accueil, au service, etc.."
            required
          />
        </FormGroup>
      </div>
    );
  }
}
