import React from 'react';
import {PropTypes} from 'prop-types';

import _ from 'lodash';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import {withLayoutContextProvider} from 'services/LayoutContext';

import {FormGroup, Label, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import Input from 'sharedComponents/input/Input';
import Select from 'sharedComponents/select/Select';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import PreventionCategoriesApi from 'admin/apis/preventionCategories.api';

export class GeneralTab extends React.PureComponent {
  static propTypes = {
    category: PropTypes.object.isRequired,
    maps: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  state = {
    mapId: this.props.category.mapId,
  };

  addMapOnChange = (mapId) => {
    this.setState({mapId: mapId});
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await PreventionCategoriesApi.editGeneral(
      this.props.category.id,
      formData,
    ).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success(
        'La catégorie de prévention a été modifiée',
        null,
      );
    }
  };

  render() {
    const mapsOptions = _.sortBy(this.props.maps, 'title').map((m) => ({
      value: m.id,
      label: `${m.title}`,
    }));

    return (
      <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
        <FormGroup className="required">
          <Label>Titre</Label>
          <Input
            type="text"
            name="title"
            value={this.props.category.title}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Image (bleue)</Label>
          <ImageUploader
            name="picture"
            helpText="Taille attendue :  500px par 500px"
            actualUrl={this.props.category.pictureUrl}
          />
        </FormGroup>

        <FormGroup>
          <Label>Image alt. (blanche)</Label>
          <ImageUploader
            whiteImage
            name="pictureAlt"
            helpText="Taille attendue :  500px par 500px"
            actualUrl={this.props.category.pictureAltUrl}
          />
        </FormGroup>

        <FormGroup>
          <Label>Cartes interactives</Label>
          <Select
            id="mapId"
            name="mapId"
            placeholder="Choisir une carte interactive"
            searchable
            onChange={this.addMapOnChange}
            value={this.state.mapId}
            options={mapsOptions}
          />
        </FormGroup>

        {this.state.mapId !== null && (
          <FormGroup className="required">
            <Label>CTA carte</Label>
            <Input
              className="form-control"
              name="mapCTA"
              value={this.props.category.mapCTA}
              required
            />
          </FormGroup>
        )}

        <FormGroup className="text-end">
          <Link
            to="/admin/prevention/categories"
            className="btn btn-outline-primary me-1"
            type="button">
            Retour à la liste
          </Link>
          <Button type="submit" color="primary">
            Valider
          </Button>
        </FormGroup>
      </ValidationForm>
    );
  }
}

export default withLayoutContextProvider(GeneralTab);
