import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Link} from 'react-router-dom';
import {Button, FormGroup, Label} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import YoutubeInput from 'sharedComponents/youtubeInput/YoutubeInput';
import HtmlEditor from 'sharedComponents/htmlEditor/HtmlEditor';

import ReleaseNotesApi from 'admin/apis/releaseNotes.api';

export default class EditReleaseNotePage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    note: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.id = this.props.match.params.id;

    this.props.layoutContextProvider.change({
      title: 'Gestion des nouveautés',
      activeMenuKey: 'releaseNotes',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
      },
      async () => {
        let nextState = {isLoading: false};

        let note = await ReleaseNotesApi.get(this.id).catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (note) {
          nextState.note = note;
        }

        this.setState(nextState);
      },
    );
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;
    await ReleaseNotesApi.edit(this.id, formData).catch((error) => {
      ApiErrorsHelper.manage(error, this.form);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success('La nouveauté a été modifié', null);
      this.props.history.push('/admin/releaseNotes');
    }
  };

  render() {
    return (
      <Block
        title={
          <span>
            Modification d'une <strong>nouveauté</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement de la nouveauté">
          {this.state.note && (
            <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
              <FormGroup className="row required">
                <Label className="col-sm-2">Titre</Label>
                <div className="col-sm-10">
                  <Input
                    className="form-control"
                    name="title"
                    value={this.state.note.title}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row required">
                <Label className="col-sm-2">Note</Label>
                <div className="col-sm-10">
                  <HtmlEditor
                    className="form-control"
                    name="note"
                    value={this.state.note.note}
                    required
                  />
                </div>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-2">ID YouTube de la vidéo</Label>
                <div className="col-sm-10">
                  <YoutubeInput
                    name="videoId"
                    value={this.state.note.youtubeId}
                  />
                </div>
              </FormGroup>

              <FormGroup className="text-end">
                <Link
                  to="/admin/releaseNotes"
                  className="btn btn-outline-primary me-1"
                  type="button">
                  Retour à la liste
                </Link>
                <Button type="submit" color="primary">
                  Valider
                </Button>
              </FormGroup>
            </ValidationForm>
          )}
        </PageDataLoader>
      </Block>
    );
  }
}
