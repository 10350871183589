import React from 'react';

import {Link} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {Button, FormGroup, Label} from 'reactstrap';
import Block from 'sharedComponents/block/Block';
import HtmlEditor from 'sharedComponents/htmlEditor/HtmlEditor';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';

import WorkflowsApi from 'clinic/apis/workflows.api';

export default class AddHospitalizationStepPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.workflowId = this.props.match.params.workflowId;

    this.props.layoutContextProvider.change({
      title: 'Gestion des parcours',
      activeMenuKey: 'workflows',
    });

    this.loadData();
  }

  loadData = () => {
    this.setState({isLoading: true, loadingError: false}, async () => {
      let nextState = {isLoading: false};

      let workflow = await WorkflowsApi.get(this.workflowId).catch((error) => {
        nextState.loadingError = error;
        return null;
      });

      if (workflow) {
        this.props.layoutContextProvider.change({
          title: `Gestion des parcours / ${workflow.name} `,
          activeMenuKey: 'workflows',
        });
      }

      this.setState(nextState);
    });
  };

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await WorkflowsApi.createHospitalizationSteps(
      this.workflowId,
      formData,
    ).catch((e) => {
      ApiErrorsHelper.manage(e, this.form);
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();
    if (success) {
      NotificationManager.success('Étape créée', '');
      this.props.history.push(
        `/clinic/workflows/${this.workflowId}#hospitalizationSteps`,
      );
    }
  };

  render() {
    return (
      <Block
        title={
          <span>
            Création d'une <strong>étape Jour J</strong>
          </span>
        }>
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement de l'étape jour J"
          loadingError={this.state.loadingError}>
          <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
            <FormGroup className="row required">
              <Label className="col-sm-2">Titre</Label>
              <div className="col-sm-10">
                <Input name="title" required />
              </div>
            </FormGroup>

            <FormGroup className="row required">
              <Label className="col-sm-2">Équipe</Label>
              <div className="col-sm-10">
                <Input name="team" required />
              </div>
            </FormGroup>

            <FormGroup className="row required">
              <Label className="col-sm-2">Lieu</Label>
              <div className="col-sm-10">
                <Input name="location" required />
              </div>
            </FormGroup>

            <FormGroup className="row required">
              <Label className="col-sm-2">Description</Label>
              <div className="col-sm-10">
                <HtmlEditor name="description" required />
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-2">Durée</Label>
              <div className="col-sm-10">
                <HtmlEditor name="duration" />
              </div>
            </FormGroup>

            <FormGroup className="text-end">
              <Link
                to={`/clinic/workflows/${this.workflowId}#hospitalizationSteps`}
                className="btn btn-outline-primary me-1"
                type="button">
                Retour à la liste
              </Link>
              <Button type="submit" color="primary">
                Valider
              </Button>
            </FormGroup>
          </ValidationForm>
        </PageDataLoader>
      </Block>
    );
  }
}
