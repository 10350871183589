import ApiClient from "admin/services/ApiClient";
import queryString from "query-string";

export default class SelfAssessmentTestsApi {
  static getAllTechnicalCodes = () =>
    ApiClient.get("selfAssessmentTests/technicalCodes");

  static getAvailableTechnicalCodes = () =>
    ApiClient.get(`selfAssessmentTests/technicalCodes/available`);

  static getAll(withHealthFolders = false, withSpecialities = false) {
    let params = {};
    if (withHealthFolders) {
      params.withHealthFolders = null;
    }
    if (withSpecialities) {
      params.withSpecialities = null;
    }

    return ApiClient.get(
      `selfAssessmentTests?${queryString.stringify(params)}`
    );
  }

  static getById = (
    id,
    withHealthFolders = false,
    withSpecialities = false
  ) => {
    let params = {};
    if (withHealthFolders) {
      params.withHealthFolders = null;
    }
    if (withSpecialities) {
      params.withSpecialities = null;
    }

    return ApiClient.get(
      `selfAssessmentTests/${id}?${queryString.stringify(params)}`
    );
  };

  static create = (formData) =>
    ApiClient.post(`selfAssessmentTests`, formData, true);

  static edit = (id, formData) =>
    ApiClient.post(`selfAssessmentTests/${id}`, formData, true);

  static delete = (id) => ApiClient.delete(`selfAssessmentTests/${id}`);
}
