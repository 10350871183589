import ApiClient from "clinic/services/ApiClient";

export default class ConvocationPlacesLinksApi {
  static getAll = () => ApiClient.get("convocationPlacesLinks");

  static create = (data) => ApiClient.post("convocationPlacesLinks", data);

  static delete = (id) =>
    ApiClient.delete(`convocationPlacesLinks/${id}`);

  static edit = (id, data) =>
    ApiClient.patch(
      `convocationPlacesLinks/${id}`,
      data
    );
}
