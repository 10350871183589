import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Route} from 'react-router-dom';
import {withLayoutContextProvider} from 'services/LayoutContext';
import {withRouter, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollPositionManager from 'sharedComponents/ScrollPositionManager/ScrollPositionManager';

import DisconnectButton from './disconnectButton/DisconnectButton';
import SideMenu, {SideMenuProps} from './sideMenu/SideMenu';

import './PrivateLayout.scss';

function viewport() {
  var e = window,
    a = 'inner';
  if (!('innerWidth' in window)) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  return {width: e[a + 'Width'], height: e[a + 'Height']};
}

class PrivateLayout extends React.PureComponent {
  static props = {
    sideMenuItems: SideMenuProps,
    sideMenuTitle: PropTypes.string,
    sideMenuSubitle: PropTypes.string,
    routesPrefix: PropTypes.string.isRequired,
    sideMenuTopContent: PropTypes.node,
    unauthorizedRoutePath: PropTypes.string,
    authentication: PropTypes.shape({
      logout: PropTypes.func.isRequired,
      isLogged: PropTypes.func.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
      }),
    }).isRequired,
    apiClient: PropTypes.shape({
      addAuthExpiredListener: PropTypes.func.isRequired,
      removeAuthExpiredListener: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    sideMenuTopContent: null,
  };

  state = {
    sidemenuOpened: viewport().width >= 768,
  };

  constructor(props) {
    super(props);

    this.authExpired = this.props.apiClient.addAuthExpiredListener(() => {
      this.logout();
    });
  }

  componentWillUnmount() {
    this.props.apiClient.removeAuthExpiredListener(this.authExpired);
  }

  logout = () => {
    this.props.authentication.logout();
    this.props.history.push(
      `/${this.props.routesPrefix}/${this.props.unauthorizedRoutePath}`,
    );
  };

  toggleSideMenu = () => {
    this.setState((prevState) => {
      return {sidemenuOpened: !prevState.sidemenuOpened};
    });
  };

  render() {
    if (
      this.props.authorizedIf &&
      typeof this.props.authorizedIf === 'function' &&
      !this.props.authorizedIf(this.props.authentication.user)
    ) {
      return <Redirect to={`/404`} />;
    }

    const {
      sideMenuItems,
      apiClient,
      authentication,
      routesPrefix,
      unauthorizedRoutePath,
      component,
      sideMenuTopContent,
      ...routeProps
    } = this.props;

    if (!this.props.authentication.isLogged()) {
      return null;
    }

    const RouteComponent = component;

    return (
      <div
        className={`privateLayoutContent ${
          this.state.sidemenuOpened ? 'sidemenuopened' : 'sidemenuclosed'
        }`}>
        <div className="header px-2">
          <Button onClick={this.toggleSideMenu}>
            <FontAwesomeIcon icon="bars" />
          </Button>
          <div className="d-flex align-items-center text-end">
            <div>
              {authentication.user.name}
              <div className="small d-none d-sm-block">
                <i>{authentication.user.email}</i>
              </div>
            </div>
            <DisconnectButton onClick={this.logout} />
          </div>
        </div>
        <ScrollPositionManager scrollKey="sideMenu">
          {({connectScrollTarget}) => (
            <div ref={connectScrollTarget} className="sidemenu">
              <SideMenu
                items={sideMenuItems}
                routesPrefix={routesPrefix}
                title={this.props.sideMenuTitle}
                subtitle={this.props.sideMenuSubitle}
                topContent={sideMenuTopContent}
              />
            </div>
          )}
        </ScrollPositionManager>
        <div className="pageContent">
          <h1>{this.props.layoutContextProvider.title}</h1>
          <div className="px-0 px-md-2">
            <Route
              {...routeProps}
              render={(props) =>
                RouteComponent ? (
                  <RouteComponent
                    {...props}
                    layoutContextProvider={this.props.layoutContextProvider}
                  />
                ) : null
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withLayoutContextProvider(PrivateLayout));
