import React from 'react';

import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {FormGroup, Label, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import Block from 'sharedComponents/block/Block';
import Checkbox from 'sharedComponents/checkbox/Checkbox';
import ImageUploader from 'sharedComponents/imageUploader/ImageUploader';
import Input from 'sharedComponents/input/Input';
import ValidationForm from 'sharedComponents/validationForm/ValidationForm';

import HealthFoldersApi from 'admin/apis/healthFolders.api';

export default class AddHealthFoldersPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: 'Gestion des dossiers santé',
      activeMenuKey: 'healthFolders_folders',
    });
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let folderId = await HealthFoldersApi.create(formData).catch((error) => {
      ApiErrorsHelper.manage(error, this.form);
      return null;
    });

    this.props.layoutContextProvider.hideLoader();

    if (folderId) {
      NotificationManager.success('Le dossier santé a été créé', null);
      this.props.history.push(`/admin/healthFolders/folders/${folderId}`);
    }
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Création d'un <strong>dossier santé</strong>
          </span>
        }>
        <ValidationForm ref={this.form} onSubmit={this.onSubmit}>
          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Titre</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="title" required />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Texte brique</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="brickText" required />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Image brique</Label>
            <div className="col-sm-10">
              <ImageUploader
                name="brickImage"
                helpText="Taille attendue : 1000px par 567px"
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row required">
            <Label className="col-form-label col-sm-2">Introduction</Label>
            <div className="col-sm-10">
              <Input
                className="form-control"
                name="introduction"
                type="textarea"
                required
              />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label col-sm-2">URL Chatbot</Label>
            <div className="col-sm-10">
              <Input className="form-control" name="chatbotUrl" />
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-form-label pt-0 col-sm-2" for="onHomePage">
              Page d'accueil
            </Label>
            <div className="col-sm-10">
              <Checkbox id="onHomePage" name="onHomePage" label="Oui" />
            </div>
          </FormGroup>

          <FormGroup className="text-end">
            <Link
              to="/admin/healthFolders/folders"
              className="btn btn-outline-primary me-1"
              type="button">
              Retour à la liste
            </Link>
            <Button type="submit" color="primary">
              Valider
            </Button>
          </FormGroup>
        </ValidationForm>
      </Block>
    );
  }
}
