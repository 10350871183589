import ApiClient from "admin/services/ApiClient";

export default class FaqQuestionsApi {
  static getAll = () => ApiClient.get("faqQuestions");

  static create = (formData) => ApiClient.post("faqQuestions", formData, true);

  static delete = (id) => ApiClient.delete(`faqQuestions/${id}`);

  static get = (id) => ApiClient.get(`faqQuestions/${id}`);

  static edit = (id, formData) =>
    ApiClient.patch(`faqQuestions/${id}`, formData);

  static changeOrder = (questionId, newOrder) =>
    ApiClient.patch(`faqQuestions/${questionId}/order`, {
      newOrder: newOrder,
    });
}
