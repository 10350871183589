import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { PropTypes } from "prop-types";
import React from "react";

import QoDMessagesList from "../../QoDMessagesList/QoDMessagesList";

export default class MedicalStayDetailsModal extends React.PureComponent {
  static propTypes = {
    messages: PropTypes.array,
    errorsDescriptions: PropTypes.array,
    isOpen: PropTypes.bool
  };

  static defaultProps = {
    messages: [],
    errorsDescriptions: [],
    isOpen: false
  };

  render() {
    return (
      <div>
        <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <ModalHeader toggle={this.props.toggle}>
            Détails des messages HL7
          </ModalHeader>
          <ModalBody>
            <QoDMessagesList
              errorsDescriptions={this.props.errorsDescriptions}
              messages={this.props.messages}
            />
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary" onClick={this.props.toggle}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
